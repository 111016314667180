import { BASE_URL, PAID_PLAN_HOOK } from "../../config";
import { postData } from "../api-call";

const getPaidPlan = ({ countryCode }: { countryCode: string }) => {
  const data = { countryCode };
  const requestUrl = PAID_PLAN_HOOK;

  return postData(requestUrl, data);
};

export const GetPaidPlanAPI = {
  getPaidPlan,
};
