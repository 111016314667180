import * as React from "react";
import { SafeAreaView, StyleSheet } from "react-native";
import { SegmentedButtons } from "react-native-paper";
import { globalStyles } from "../styles";
import { useSelector } from "react-redux";
import { buttonSelect } from "../analytics/src/open-report";

const DateButtons = ({ date, setDate, setLabel, label, contactPhone }) => {
  const months = [
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre",
  ];

  const dateOptions = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  };

  const today = new Date(new Date()).toLocaleDateString("es-ES", dateOptions);
  const period = useSelector((state) => state.periodTime);
  return (
    <SafeAreaView style={styles.container}>
      <SegmentedButtons
        value={date}
        onValueChange={setDate}
        style={{ width: "100%" }}
        buttons={[
          {
            value: today,
            label: "Día",
            style: {
              backgroundColor:
                label === "Día" ? globalStyles.colors.primary : "white",
            },
            onPress: async () => {
              setLabel("Día");
              await buttonSelect(contactPhone, {
                type: "Barra de Movimientos",
                button: "Día",
              });
            },
          },
          {
            value: "semana",
            label: "Semana",
            style: {
              backgroundColor:
                label === "Semana" ? globalStyles.colors.primary : "white",
            },
            onPress: async () => {
              setLabel("Semana");
              await buttonSelect(contactPhone, {
                type: "Barra de Movimientos",
                button: "Semana",
              });
            },
          },
          {
            value: months[new Date().getMonth()],
            label: "Mes",
            style: {
              backgroundColor:
                label === "Mes" ? globalStyles.colors.primary : "white",
            },
            onPress: async () => {
              setLabel("Mes");
              await buttonSelect(contactPhone, {
                type: "Barra de Movimientos",
                button: "Mes",
              });
            },
          },
        ]}
      />
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    width: "100%",
    alignItems: "center",
    marginTop: globalStyles.shape.sectionSpace,
  },
});

export default DateButtons;
