import { StyleSheet, Text, View } from "react-native";
import React from "react";
import { Snackbar } from "react-native-paper";
import { globalClasses, globalStyles } from "../styles";
import Ionicons from "react-native-vector-icons/Ionicons";
const TxModifySnackbar = ({ showSnackbar, dismissSnackbar }) => {
  return (
    <Snackbar
      duration={5000}
      style={{ backgroundColor: "rgba(255,255,255,1)" }}
      visible={showSnackbar}
      onDismiss={dismissSnackbar}
    >
      <View style={{ flexDirection: "row" }}>
        <View style={{ padding: 5 }}>
          <Ionicons
            name="checkmark-circle"
            size={30}
            color={globalStyles.colors.primary}
          />
        </View>
        <Text>
          ¡Listo!{" "}
          <Text style={globalClasses.smallTitle}>
            Se modificó tu transacción
          </Text>
        </Text>
      </View>
    </Snackbar>
  );
};

export default TxModifySnackbar;

const styles = StyleSheet.create({});
