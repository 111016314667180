import React, { useState, useEffect, useMemo } from "react";
import { GetPaidPlanAPI } from "../../api/src/paid-plan";
import { getHashId } from "../../helpers/src";
import { UserInfoAPI } from "../../api/src/user-info";

import _ from "lodash";

export const usePaidPlan = (props) => {
  const paramsId =
    window.location.pathname !== "/"
      ? window.location.pathname
      : `/u/${localStorage.getItem("hashId")}`;

  const hashId = getHashId(paramsId);

  const [paidPlan, setPaidPlan] = useState({});
  const [contactCountry, setContactCountry] = useState("");
  const [contactPhone, setContactPhone] = useState("");
  const [contactFullname, setContactFullname] = useState("");
  const [contactMemberShip, setContactMemberShip] = useState("");
  const [loader, setLoader] = useState(false);
  const [contactTrial, setContactTrial] = useState(false);

  useEffect(() => {
    UserInfoAPI.getInfo({ id: hashId }).then((x) => {
      setContactCountry(x?.contact?.attributes?.countryCode);
      setContactPhone(x?.contact?.attributes?.phone);
      setContactMemberShip(x?.contact?.attributes?.userMembership);
      setContactFullname(
        `${x?.contact?.attributes?.name} ${x?.contact?.attributes?.lastName}`
      );
      setContactTrial(x?.contact?.attributes?.trial);

      GetPaidPlanAPI.getPaidPlan({
        countryCode: x?.contact?.attributes?.countryCode,
      }).then((x) => {
        setPaidPlan(x);
      });
    });

    setTimeout(() => {
      setLoader(true);
    }, 2000);
  }, []);

  return {
    paidPlan,
    contactCountry,
    contactPhone,
    contactMemberShip,
    contactFullname,
    loader,
    contactTrial,
    setContactTrial,
  };
};
