export const CHANGE_TRANSACTION = {
  title: "Change of Transaction",
  body: "¡Cambio registrado! 😃 Algunos detalles de tu TYPE DESCRIPTION han sido modificados.",
};

export const DELETE_TRANSACTION = {
  title: "Delete Transaction",
  body: "¡Listo! ⚠️ Hemos eliminado tu TYPE DESCRIPTION.",
};

export const COMPLETE_DEBT = {
  title: "Complete Debt",
  body: "¡Bien hecho! 🚀 completaste tu deuda TYPE con HOLDER.",
};

export const PAY_DEBT = {
  title: "Pay Debt",
  body: "¡Excelente! 🤝 quedó registrado el abono a tu deuda TYPE con HOLDER.",
};

export const RESCHEDULE_DEBT = {
  title: "Reschedule Debt",
  body: "La fecha límite 🗓️ de tu deuda TYPE con HOLDER, ha sido modificada al NEW_DATE.",
};

export const DELETE_DEBT = {
  title: "Delete Debt",
  body: "¡Deuda borrada! ⚠️ Tu deuda TYPE con HOLDER ha sido eliminada.",
};
