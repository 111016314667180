import {
  configureFonts,
  DefaultTheme,
  Provider as PaperProvider,
} from "react-native-paper";
import store from "./store/index";
import { Provider } from "react-redux";
import { PortalProvider } from "@gorhom/portal";
import { SafeAreaProvider } from "react-native-safe-area-context";
import BottomNav from "./src/Components/BottomNav";
export default function App() {
  const fontConfig = {
    web: {
      regular: {
        fontFamily: "mulish",
        fontWeight: "normal",
      },
    },
  };

  const theme = {
    ...DefaultTheme,
    fonts: configureFonts(fontConfig),
  };

  return (
    <PaperProvider theme={theme}>
      <PortalProvider>
        <Provider store={store}>
          <SafeAreaProvider
            style={{
              width: "100%",
              minWidth: 320,
              maxWidth: 500,
              alignSelf: "center",
            }}
          >
            <BottomNav />
          </SafeAreaProvider>
        </Provider>
      </PortalProvider>
    </PaperProvider>
  );
}
