import { View, Text } from "react-native";
import React from "react";
import Screen from "../../Templates/Screen";
import { TouchableOpacity } from "react-native-gesture-handler";
import Icon from "react-native-vector-icons/Ionicons";
import { globalClasses, globalStyles } from "../../styles";

const Learning = ({ navigation, route }) => {
  return (
    <Screen loader={true} route={route}>
      <TouchableOpacity onPress={() => navigation.push("Menu")}>
        <Icon name="arrow-back-outline" size={30} color="#000000" />
      </TouchableOpacity>
      <Text style={globalClasses.breakTitle}>Manual...</Text>
      <Text style={globalClasses.sectionTitle}>Pantalla Movimientos</Text>
      <View
        style={{
          width: "100%",
          borderRadius: 10,
          backgroundColor: globalStyles.colors.white,
        }}
      >
        <View
          style={{
            width: "100%",
            padding: 75,
            borderRadius: 10,
            backgroundColor: "#000",
            flex: 1,
            justifyContent: "center",
            textAlign: "center",
          }}
        >
          <Text style={{ color: "#fff" }}>Imagen de la tarjeta</Text>
        </View>
        <View
          style={[
            {
              borderRadius: 10,
              paddingHorizontal: 15,
              paddingVertical: 10,
              marginTop: 20,
              backgroundColor: "#fff",
              borderWidth: 1,
              borderColor: globalStyles.colors.lightGrey,
              margin: 8,
            },
          ]}
        >
          <Text style={globalClasses.smallTitle}>Titulo de la tarjeta</Text>
          <Text>Descripción y ejemplo de la funcionalidad</Text>
        </View>
      </View>

      <Text style={globalClasses.sectionTitle}>Pantalla Deudas</Text>
    </Screen>
  );
};

export default Learning;
