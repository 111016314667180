import {
  VictoryAxis,
  VictoryBar,
  VictoryChart,
  VictoryContainer,
  VictoryTheme,
} from "victory";
import { Image, StyleSheet, Text, View } from "react-native";
import React from "react";

import { globalClasses, globalStyles } from "../styles";

const currencyFormat = Intl.NumberFormat("es-CO");
const monthWeeks = ["Sem 1", "Sem 2", "Sem 3", "Sem 4"];
const weeksDays = [
  "Lunes",
  "Martes",
  "Miércoles",
  "Jueves",
  "Viernes",
  "Sabado",
];

const MySalesCard = ({
  mostDaysIncomes,
  mostProfitDay,
  mostLossDay,
  countryCurrency,
  countDayIncome,
  purposeUse,
  content,
}) => {
  const incomeDays = mostDaysIncomes
    ?.filter((x) => x.day !== "Domingo")
    .filter((x) => x.amount)?.length;

  const countDays = countDayIncome?.reduce(
    (a, v) => ({ ...a, [`${v.days}`]: v.count }),
    {}
  );

  let repeatDays = [];

  const formatData = mostDaysIncomes
    ?.filter((x) => x.day !== "Domingo")
    ?.sort((a, b) => b.amount - a.amount)
    ?.reverse()
    ?.map((x, i) => {
      repeatDays.push(x?.day);
      return {
        x: x?.day,
        y: x?.amount / countDays[x?.day],
        fill: globalStyles.colors.darkGrey,
      };
    })
    ?.sort((a, b) => b.y - a.y)
    ?.reverse();

  if (formatData?.length) {
    if (formatData?.length === 1) {
      formatData[0].fill = globalStyles.colors.primary;
    }
    if (formatData?.length > 1) {
      formatData[0].fill = globalStyles.colors.red;

      formatData[formatData.length - 1].fill = globalStyles.colors.primary;
    }
  }

  const uniqueDays = weeksDays
    .filter((x) => !repeatDays.includes(x))
    .map((x) => ({
      x: x,
      y: 0,
      fill: globalStyles.colors.darkGrey,
    }));

  const formatDatas = [...formatData, ...uniqueDays]
    ?.sort((a, b) => weeksDays.indexOf(b.x) - weeksDays.indexOf(a.x))
    .reverse();

  return (
    <>
      <Text style={globalClasses.sectionTitle}>
        Tus
        {purposeUse === "META_GANANCIA" ? " ventas " : " gastos "}
        {content === "semanal" ? "de la semana" : "del mes"}
      </Text>
      <View style={globalClasses.card}>
        <VictoryChart
          theme={VictoryTheme.material}
          containerComponent={
            <VictoryContainer
              style={{
                pointerEvents: "auto",
                userSelect: "auto",
                touchAction: "auto",
              }}
            />
          }
        >
          <VictoryBar
            width={"100%"}
            labels={({ datum }) =>
              `$${currencyFormat.format(datum.y?.toFixed(0))}`
            }
            cornerRadius={4}
            barWidth={40}
            data={formatDatas}
            y="y"
            style={{
              labels: {
                fill: "black",
              },
              data: {
                fill: ({ datum }) => datum.fill,
              },
            }}
            animate={{
              duration: 2000,
              onLoad: { duration: 1000 },
            }}
          />
          <VictoryAxis
            crossAxis={false}
            style={{
              grid: {
                stroke: "none",
              },
            }}
          />
        </VictoryChart>
        <View style={styles.performanceContainer}>
          <View style={styles.imageContainer}>
            <Image
              resizeMode="contain"
              source={require("../../assets/money-bag.png")}
              style={styles.imageContainer__image}
            />
          </View>
          <View style={styles.performanceContainer__performance}>
            <Text style={globalClasses.smallTitle}>
              El día {content === "semanal" ? "de la semana " : "del mes "} que
              más
              {purposeUse === "META_GANANCIA" ? " vendes" : " gastas"}
            </Text>
            <Text style={globalClasses.caption}>
              <Text
                style={[
                  globalClasses.smallTitle,
                  { color: globalStyles.colors.primary },
                ]}
              >
                {mostProfitDay?.day}
              </Text>{" "}
              👉 En promedio tus{" "}
              {purposeUse === "META_GANANCIA" ? "ventas " : "gastos "}son de{" "}
              {countryCurrency}
              {countDays &&
                currencyFormat.format(
                  (
                    mostProfitDay?.amount / countDays[mostProfitDay?.day]
                  )?.toFixed(0)
                )}
            </Text>
          </View>
        </View>
        <View style={styles.performanceContainer}>
          {formatDatas?.length > 1 ? (
            <>
              {" "}
              <View style={styles.imageContainer}>
                <Image
                  resizeMode="contain"
                  source={require("../../assets/thumb-down.png")}
                  style={styles.imageContainer__image}
                />
              </View>
              <View style={styles.performanceContainer__performance}>
                <Text style={globalClasses.smallTitle}>
                  El día {content === "semanal" ? "de la semana " : "del mes "}
                  que menos
                  {purposeUse === "META_GANANCIA" ? " vendes" : " gastas"}{" "}
                </Text>
                <Text style={globalClasses.caption}>
                  <Text
                    style={[
                      globalClasses.smallTitle,
                      { color: globalStyles.colors.red },
                    ]}
                  >
                    {mostLossDay?.day}
                  </Text>{" "}
                  👉 En promedio{" "}
                  {purposeUse === "META_GANANCIA" ? "ventas " : "gastos "}son de{" "}
                  {countryCurrency}
                  {countDays &&
                    currencyFormat.format(
                      (
                        mostLossDay?.amount / countDays[mostLossDay?.day]
                      )?.toFixed(0)
                    )}
                </Text>
              </View>
            </>
          ) : (
            ""
          )}
        </View>
      </View>
    </>
  );
};

export default MySalesCard;

const styles = StyleSheet.create({
  performanceContainer: {
    flexDirection: "row",
    marginTop: globalStyles.shape.betweenSpace,
  },
  imageContainer: { width: "10%", padding: 5 },
  imageContainer__image: { width: "100%", height: 30 },
  performanceContainer__performance: { width: "90%", padding: 5 },
});
