import { BASE_URL, STRIPE_PAYMENT } from "../../config";
import { post } from "../base";

const getStripeLink = async ({ planType, price, phone, codePrice }) => {
  const data = {
    planType,
    price,
    phone,
    codePrice,
  };

  const requestUrl = `${BASE_URL}${STRIPE_PAYMENT}`;

  return post(requestUrl, data);
};
export const StripeLinkAPI = {
  getStripeLink,
};
