import { useDispatch, useSelector } from "react-redux";
import React, { useState, useEffect, useRef } from "react";

import {
  getDate,
  changeDateFilter,
  checkProperty,
  getHashId,
} from "../../helpers/src";
import { addDate } from "../../../store/slices/date";
import { clearReportUrl } from "../../../store/slices/reportUrl";
import { DashboardAPI } from "../../api/src/dashboard";
import { ReportWebHookAPI } from "../../api/src/report-data";

/*Función para crear y guardar los hooks necesarios para el modal de
descargar el reporte 
Trabaja solo la fecha y el loader del reporte*/

export const useDownload = () => {
  const paramsId =
    window.location.pathname !== "/"
      ? window.location.pathname
      : `/u/${localStorage.getItem("hashId")}`;

  const paramsDate = window.location.search;
  const dispatch = useDispatch();
  const hashId = getHashId(paramsId);

  const [reportUrl, setReportUrl] = useState("");

  const [date, setDate] = useState();
  const [reportLoader, setReportLoader] = useState(false);
  const [financeData, setFinanceData] = useState({});
  const currencyFormat = Intl.NumberFormat("es-CO");
  const { userData } = useSelector((state) => state.userData);
  const [transactions,setTransaction]=useState(false)


  useEffect(() => {
    if (date) {
      DashboardAPI.getUserFinancesData({
        id: hashId,
        from: date,
      }).then((x) => {
        if (x?.transactions?.length) {
          const parseTransactions =
            date?.text !== "year"
              ? x?.transactions?.map(({ id, attributes }) => ({
                  id,
                  attributes: {
                    ...attributes,
                    type: attributes?.type === "income" ? "Ingreso" : "Gasto",
                    amount: currencyFormat.format(attributes?.amount),
                  },
                }))
              : x?.transactions?.map((t) => ({
                  id: t.id,
                  attributes: {
                    description: t.description,
                    date: t?.date,
                    type: t?.type === "income" ? "Ingreso" : "Gasto",
                    amount: currencyFormat.format(t?.amount),
                  },
                }));

          ReportWebHookAPI.postReportWebHook({
            phone: userData.phone,
            period: date === "year" ? "Año" : date?.text,
            txs: parseTransactions,
          })
            .then((x) => {
              setTransaction(true)
              setReportUrl(checkProperty(x?.url));
            })
           // .finally(() => setIsSendReportWebHook(false));
        }
      });

      setReportLoader(false);

      dispatch(addDate(date));

      setTimeout(() => setReportLoader(true), 10000);
    }
  }, [date]);
  return { date, setDate, reportLoader, financeData, reportUrl, transactions };
};

