import React from "react";
import { loadingMessage } from "../utils/Arrays";
import { StyleSheet, Text, View } from "react-native";
import { ActivityIndicator } from "react-native-paper";
import { globalClasses, globalStyles } from "../styles";

const randomText =
  loadingMessage[Math.floor(Math.random() * loadingMessage.length)];

const LoadingComponent = () => {
  return (
    <View style={styles.activityContainer}>
      <ActivityIndicator
        animating={true}
        color={globalStyles.colors.primary}
        size={50}
      />
      <Text style={globalClasses.sectionTitle}>{randomText}</Text>
    </View>
  );
};

export default LoadingComponent;

const styles = StyleSheet.create({
  activityContainer: {
    marginTop: 100,
    height: 200,
    width: "80%",
    textAlign: "center",
    justifyContent: "center",
    marginLeft: "auto",
    marginRight: "auto",
  },
});
