import * as React from "react";
import { Text, StyleSheet, View } from "react-native";

import { globalClasses, globalStyles } from "../styles";
import MaterialCommunityIcons from "react-native-vector-icons/MaterialCommunityIcons";

const InterpretationCard = ({ title, description }) => {
  return (
    <View style={styles.messageCard}>
      <View style={{ marginRight: 7 }}>
        <MaterialCommunityIcons
          name="creation"
          color={globalStyles.colors.primary}
          size={25}
        />
      </View>
      <View style={{ width: "91%" }}>
        <Text style={globalClasses.smallTitle}>{title}</Text>

        <Text>{description}</Text>
      </View>
    </View>
    // <List.Accordion
    //   titleNumberOfLines={3}
    //   titleStyle={globalClasses.smallTitle}
    //   style={styles.accordionContainer}
    //   title={
    //     <View>
    //       <Text style={globalClasses.smallTitle}>
    //         <MaterialCommunityIcons
    //           name="creation"
    //           color={globalStyles.colors.primary}
    //           size={20}
    //         />{" "}
    //         {title}
    //       </Text>
    //     </View>
    //   }
    //   // left={(props) => <List.Icon {...props} icon="creation" />}
    // >
    //   <List.Item
    //     descriptionStyle={{ backgroundColor: globalStyles.colors.primary }}
    //     description={() => (
    //       <View
    //         style={{
    //           backgroundColor: globalStyles.colors.lightPositive,
    //           width: "100%",
    //         }}
    //       >
    //         <Divider />
    //         <View style={{ flexDirection: "row" }}>
    //           <Text style={styles.proText}>
    //            {description}
    //           </Text>
    //         </View>
    //       </View>
    //     )}
    //   />
    // </List.Accordion>
  );
};

export default InterpretationCard;

const styles = StyleSheet.create({
  accordionContainer: {
    backgroundColor: globalStyles.colors.lightPositive,
    borderRadius: 1,
  },
  proText: {
    color: globalStyles.colors.darkGrey,
    marginTop: globalStyles.shape.betweenSpace,
    marginRight: 10,
  },
  messageCard: {
    padding: 7,
    backgroundColor: globalStyles.colors.lightPositive,
    flexDirection: "row ",
    marginTop: globalStyles.shape.sectionSpace,
    borderRadius: 10,
  },
});
