import { useEffect, useState } from "react";
import { Divider } from "react-native-paper";
import Ionicon from "react-native-vector-icons/Ionicons";
import { Image, StyleSheet, Text, View } from "react-native";
import { TouchableOpacity } from "react-native-gesture-handler";

import { useOptions } from "./useOptions";
import logo from "../../../assets/Qentaz.png";
import { menuOptions } from "../../utils/Arrays";
import MenuButton from "../../Components/MenuButton";
import Download from "../../Components/Modal/Download";
import { globalClasses, globalStyles } from "../../styles";
import { buttonSelect, visitPageMenu } from "../../analytics/src/open-report";

import Screen from "../../Templates/Screen";
import MultiUserForm from "../../Components/Modal/MultiUserForm/MultiUserForm";
/*Contenedor Pantalla menú donde estarán los botones para
 navegar a distintas pantallas u otras funcionalidades 
 Recibe datos como
 - Plan del usuario
 - Nombre del usuario*/
const Options = ({ navigation, route }) => {
  const {
    reportExcel,
    setModalShow,
    clearModalShowV,
    modal,
    setShowDonwload,
    showDownload,
    userData: { userMembership, userType, name, phone, beta },
  } = useOptions();

  const setAnalytic = async () => visitPageMenu(phone, { type: "navigation" });
  //multiUserForm
  const [multiUser, setMultiUser] = useState(false);

  useEffect(() => {
    if (phone && !route?.params?.nav) {
      setAnalytic();
    }
  }, [phone]);

  return (
    <>
      <View>
        <View style={styles.greenCard}>
          <View style={globalClasses.spacedRow}>
            <View>
              <Text
                style={[
                  globalClasses.cardTitle,
                  { color: globalStyles.colors.white },
                ]}
              >
                Hola, {name}
              </Text>
              <Text
                style={[
                  globalClasses.smallTitle,
                  { color: globalStyles.colors.white },
                ]}
              >
                Tu plan:{" "}
                {userMembership === "basic" ? "básico" : userMembership}
              </Text>
            </View>
            <View style={{ width: 110, marginTop: 8 }}>
              <Image
                source={logo}
                style={{ height: 40, width: "100%" }}
                resizeMode="contain"
              />
            </View>
          </View>
        </View>
        <Screen loader={true} route={route} phone={phone}>
          {menuOptions.map((item) => (
            <MenuButton
              key={item.title}
              icon={item.icon}
              title={item.title}
              screen={item.screen}
              link={item.link}
              navigation={navigation}
              contactPhone={phone}
              type={item?.type}
            />
          ))}
          <Divider style={{ marginTop: 10 }} bold />
          <Text style={[{ textAlign: "center" }, globalClasses.sectionTitle]}>
            ¡Funcionalidades diseñadas para ti!
          </Text>

          <TouchableOpacity
            disabled={userMembership !== "pro" && true}
            onPress={async () => {
              setShowDonwload(true);
              await buttonSelect(phone, {
                page: "REPORTE",
                type: "button",
                previous_screen: "Menu",
              });
            }}
          >
            <View
              style={[
                globalClasses.card,
                userMembership !== "pro"
                  ? { opacity: 0.6 }
                  : globalClasses.shadow,
              ]}
            >
              <View style={{ flexDirection: "row" }}>
                <View style={{ padding: 5 }}>
                  <Ionicon
                    name="download-outline"
                    size={30}
                    color={
                      userMembership !== "pro"
                        ? globalStyles.colors.darkGrey
                        : globalStyles.colors.primary
                    }
                  />
                </View>
                <Text
                  style={[
                    globalClasses.cardTitle,
                    { marginTop: 5, marginLeft: 8 },
                  ]}
                >
                  Descargar mi reporte
                </Text>
              </View>
            </View>
          </TouchableOpacity>
          <TouchableOpacity
            disabled={userMembership !== "pro" && !beta}
            onPress={() => setMultiUser(true)}
          >
            <View
              style={[
                globalClasses.card,
                userMembership !== "pro"
                  ? { opacity: 0.6 }
                  : globalClasses.shadow,
              ]}
            >
              <View style={{ flexDirection: "row" }}>
                <View style={{ padding: 5 }}>
                  <Ionicon
                    name="person-add-outline"
                    size={30}
                    color={
                      userMembership !== "pro"
                        ? globalStyles.colors.darkGrey
                        : globalStyles.colors.primary
                    }
                  />
                </View>
                <Text
                  style={[
                    globalClasses.cardTitle,
                    { marginTop: 5, marginLeft: 8 },
                  ]}
                >
                  Agregar otro usuario
                </Text>
              </View>
            </View>
          </TouchableOpacity>
          {userMembership !== "pro" && (
            <Text style={[globalClasses.cardTitle, styles.greenCaption]}>
              Solo disponible para Plan Pro
            </Text>
          )}
        </Screen>
      </View>
      {userMembership === "pro" && showDownload && (
        <Download
          url={reportExcel}
          show={showDownload}
          dismiss={clearModalShowV}
          loadUrl={modal}
          setLoadUrl={setModalShow}
        />
      )}
      <MultiUserForm show={multiUser} dismiss={() => setMultiUser(false)} />
    </>
  );
};

export default Options;

const styles = StyleSheet.create({
  greenCard: {
    backgroundColor: globalStyles.colors.primary,
    borderBottomLeftRadius: 20,
    borderBottomRightRadius: 20,
    paddingHorizontal: 16,
    paddingVertical: 30,
  },
  planButton: {
    borderRadius: 20,
    marginTop: 8,
    padding: 5,
    backgroundColor: globalStyles.colors.lightPositive,
  },
  greenCaption: {
    color: globalStyles.colors.primary,
    textAlign: "center",
    marginTop: 8,
  },
});
