import { Button, StyleSheet, Text, TouchableOpacity, View } from "react-native";
import React from "react";
import { globalClasses, globalStyles } from "../styles";
import Ionicons from "react-native-vector-icons/Ionicons";
import { visitPageChangePlan, visitPagePlans } from "../analytics/src/open-report";
const NotPremiumContainer = ({
  navigation,
  registerTime,
  amountTransactions,
  userMembership,
  phone,
}) => {
  return (
    <View
      style={[
        globalClasses.screenContainer,
        { flex: 1, justifyContent: "center" },
      ]}
    >
      <Text style={[globalClasses.cardTitle, { fontWeight: "700" }]}>
        ¡El análisis de tu reporte!
      </Text>
      <Text style={{ marginTop: globalStyles.shape.sectionSpace }}>
        Para poder acceder a tu análisis es necesario cumplir con estos 3
        requisitos:
      </Text>
      <View style={styles.iconRow}>
        <View style={{ padding: 3 }}>
          <Ionicons
            name="checkmark-circle"
            size={27}
            color={
              registerTime > 10
                ? globalStyles.colors.primary
                : globalStyles.colors.darkGrey
            }
          />
        </View>
        <View>
          <Text style={globalClasses.smallTitle}>
            ¡Lleva 10 días usando Qentaz!
          </Text>
          <Text
            style={[
              globalClasses.smallTitle,
              {
                color:
                  registerTime > 10
                    ? globalStyles.colors.primary
                    : globalStyles.colors.red,
              },
            ]}
          >
            {registerTime > 10
              ? "¡Completado!"
              : "¡Te faltan" + Math.abs(registerTime - 10) + "días !"}
          </Text>
        </View>
      </View>
      <View style={styles.iconRow}>
        <View style={{ padding: 3 }}>
          <Ionicons
            name="checkmark-circle"
            size={27}
            color={
              amountTransactions > 10
                ? globalStyles.colors.primary
                : globalStyles.colors.darkGrey
            }
          />
        </View>
        <View>
          <Text style={globalClasses.smallTitle}>
            Registra al menos 10 movimientos.
          </Text>
          <Text
            style={[
              globalClasses.smallTitle,
              {
                color:
                  amountTransactions > 10
                    ? globalStyles.colors.primary
                    : globalStyles.colors.red,
              },
            ]}
          >
            {amountTransactions > 10
              ? "Completado!"
              : "¡Te faltan " +
                Math.abs(amountTransactions - 10) +
                " por registrar !"}
          </Text>
        </View>
      </View>
      <View style={styles.iconRow}>
        <View style={{ padding: 3 }}>
          <Ionicons
            name="checkmark-circle"
            size={27}
            color={
              userMembership !== "basic"
                ? globalStyles.colors.primary
                : globalStyles.colors.darkGrey
            }
          />
        </View>
        <View>
          <Text style={globalClasses.smallTitle}>Adquiere un plan pago</Text>
          <TouchableOpacity
            onPress={async () => {
              navigation.navigate("Más", {
                screen: "Planes",
                nav: true,
              });

              await visitPageChangePlan(phone, {
                previous_screen: "Analisis",
                type: "button",
              });
            }}
          >
            <Text
              style={[
                globalClasses.smallTitle,
                {
                  color:
                    userMembership === "basic"
                      ? globalStyles.colors.red
                      : globalStyles.colors.primary,
                  textDecorationLine:
                    userMembership === "basic" ? "underline" : "none",
                },
              ]}
            >
              {userMembership === "basic" ? "¡Conocer más!" : "¡Completado!"}
            </Text>
          </TouchableOpacity>
        </View>
      </View>
      <View style={{ marginTop: globalStyles.shape.sectionSpaceBottom }}>
        <Button
          title="Ver mis movimientos"
          color={globalStyles.colors.primary}
          onPress={() => navigation.navigate("Movimientos")}
        />
      </View>
    </View>
  );
};

export default NotPremiumContainer;

const styles = StyleSheet.create({
  title: { fontSize: 18, fontWeight: "700" },
  iconRow: { flexDirection: "row", marginTop: globalStyles.shape.sectionSpace },
});
