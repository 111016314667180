import { Image, StyleSheet, Text, View } from "react-native";
import React from "react";
import { globalClasses, globalStyles } from "../styles";
import { TouchableOpacity } from "react-native-gesture-handler";
import Ionicons from "react-native-vector-icons/Ionicons";

const DropdownCard = ({ visible, setVisible, title, bank, children, icon }) => {
  return (
    <View style={[styles.cardContainer, globalClasses.shadow]}>
      <TouchableOpacity onPress={setVisible}>
        <View
          style={[
            styles.cardTitle,
            globalClasses.shadow,
            globalClasses.spacedRow,
          ]}
        >
          <Text style={globalClasses.smallTitle}>{title}</Text>
          <View>
            {icon ? (
              <Ionicons
                name={icon}
                size={30}
                color={globalStyles.colors.primary}
              />
            ) : (
              <Image
                source={bank}
                style={{ width: 80, height: 25 }}
                resizeMode="contain"
              />
            )}
          </View>
        </View>
      </TouchableOpacity>
      {visible === true && (
        <View style={{ paddingHorizontal: 15, paddingVertical: 10 }}>
          {children}
        </View>
      )}
    </View>
  );
};

export default DropdownCard;

const styles = StyleSheet.create({
  cardContainer: {
    width: "100%",
    borderRadius: 10,
    marginTop: 20,
    backgroundColor: "#f6f8f7",
  },
  cardTitle: {
    width: "100%",
    borderRadius: 10,
    paddingHorizontal: 15,
    paddingVertical: 10,
    backgroundColor: "#fff",
  },
  button: {
    borderRadius: 7,
    textAlign: "center",
    width: 120,
    padding: 5,
  },
});
