import React, { useState } from "react";
import { Linking, Text, View, StyleSheet } from "react-native";
import { ActivityIndicator, Button, IconButton } from "react-native-paper";

import { periodTime } from "../../Screens/config/_.model";
import { globalClasses, globalStyles } from "../../styles";
import ModalTemplate from "../ModalTemplate";

const { useDownload } = require("./useDownload");
/*Componente modal para descargar el reporte.
Recibe los parametros:
- url: Link para descargar el reporte
- show: variable que define si se abre el modal o no (true/false)
 - dismiss: Acción para cambiar el valor de show
 Tiene un desplegable para definir la fecha de la información 
 del reporte*/

const Download = ({ url, show, dismiss, loadUrl, setLoadUrl }) => {
  const {
    date,
    setDate,
    reportLoader,
    reportUrl,
   transactions,
  } = useDownload();

  const [otro, setOtro] = useState(false);

  return (
    <ModalTemplate visible={show}>
      <>
        <IconButton
          icon="close"
          onPress={dismiss}
          iconColor={globalStyles.colors.darkGrey}
          size={25}
        />
        {!otro ? (
          <>
            <Text
              style={[
                globalClasses.cardTitle,
                { fontWeight: "700", textAlign: "center" },
              ]}
            >
              Selecciona el periodo para revisar tu reporte
            </Text>
            <Button
              buttonColor={globalStyles.colors.primary}
              mode="contained"
              labelStyle={[
                globalClasses.cardTitlePeriod,
                { color: globalStyles.colors.white },
              ]}
              onPress={() => {
                setDate("Mes");
                setOtro(true);
                setLoadUrl(true);
              }}
              style={{ borderRadius: 10, marginTop: 20 }}
              textColor={globalStyles.colors.white}
            >
              Este mes
            </Button>
            <Button
              buttonColor={globalStyles.colors.primary}
              mode="contained"
              labelStyle={[
                globalClasses.cardTitlePeriod,
                { color: globalStyles.colors.white },
              ]}
              onPress={() => {
                setDate("Mes-pasado");
                setOtro(true);
                setLoadUrl(true);
              }}
              style={{ borderRadius: 10, marginTop: 20 }}
              textColor={globalStyles.colors.white}
            >
              Mes pasado
            </Button>
            <Button
              buttonColor={globalStyles.colors.primary}
              mode="contained"
              labelStyle={[
                globalClasses.cardTitlePeriod,
                { color: globalStyles.colors.white },
              ]}
              onPress={() => {
                setDate("year");
                setOtro(true);
                setLoadUrl(true);
              }}
              style={{ borderRadius: 10, marginTop: 20 }}
              textColor={globalStyles.colors.white}
            >
              Año en curso
            </Button>
          </>
        ) : (
          <>
            {!reportLoader ? (
              <View style={{ marginTop: 8 }}>
                <Text
                  style={[
                    globalClasses.cardTitle,
                    { textAlign: "center", marginBotton: 8 },
                  ]}
                >
                  Estamos procesando tu reporte, por favor espera unos segundos
                  😊
                </Text>
                <ActivityIndicator animating={true} color="green" size={40} />
              </View>
            ) : transactions ? (
              <View>
                <Text
                  style={[
                    globalClasses.cardTitle,
                    { textAlign: "center", marginBottom: 12 },
                  ]}
                >
                  ¡Listo! Puedes descargar tu reporte de{" "}
                  {date === "year" ? "Año" : date}
                </Text>
                <Button
                  buttonColor={globalStyles.colors.primary}
                  contentStyle={{ flexDirection: "row-reverse" }}
                  icon="file-excel"
                  mode="contained"
                  labelStyle={{ fontWeight: "bold" }}
                  onPress={() => Linking.openURL(reportUrl)}
                  style={{ borderRadius: 10 }}
                  textColor={globalStyles.colors.white}
                >
                  Obtener mi reporte
                </Button>
              </View>
            ) : (
              <Text style={styles.modalCaption}>
                🙁 No tienes movimientos en el periodo seleccionado
              </Text>
            )}
          </>
        )}
      </>
    </ModalTemplate>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: "#ffffff",
    justifyContent: "center",
    // flex: 1,
    alignSelf: "center",
    alignItems: "center",
    borderRadius: 20,
    width: "80%",
    padding: 15,
  },
  button: { width: "100%", margin: 4, borderRadius: 10 },
  modalCaption: {
    color: "#ababab",
    paddingVertical: 4,
    fontWeight: "bold",
    textAlign: "center",
    fontSize: 15,
  },
});

export default Download;

