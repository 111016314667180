import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { checkProperty, getHashId } from "../../helpers/src";
import { addModalShow, clearModalShow } from "../../../store/slices/modalShow";
import { visitPageMenu } from "../../analytics/src/open-report";
import _ from "lodash";
import { UserInfoAPI } from "../../api/src/user-info";

export const useOptions = () => {
  const reportUrls = useSelector((state) => state.reportUrl);
  const paramsId =
    window.location.pathname !== "/"
      ? window.location.pathname
      : `/u/${localStorage.getItem("hashId")}`;

  const [userData, setUserData] = useState({});
  const [modal] = useSelector((state) => state.modalShow);
  const [isFetchingUserData, setIsFetchingUserData] = useState(false);
  const [showDownload, setShowDonwload] = useState(false);

  const dispatch = useDispatch();
  const hashId = getHashId(paramsId);

  const [excelReport] = reportUrls.slice(-1);
  const setModalShow = (show) => {
    dispatch(addModalShow(show));
  };

  const clearModalShowV = () => {
    dispatch(clearModalShow());
    setShowDonwload(false);
  };

  useEffect(() => {
    UserInfoAPI.getInfo({ id: hashId })
      .then((x) => {
        setUserData({
          name: checkProperty(x?.contact?.attributes?.name),
          userMembership: checkProperty(x?.contact?.attributes?.userMembership),
          purposeUse: checkProperty(x?.contact?.attributes?.purposeUse),
          phone: checkProperty(x?.contact?.attributes?.phone),
          id: checkProperty(x?.contact?.id),
          userType: checkProperty(x?.contact?.attributes?.userType),
          beta: checkProperty(x?.contact?.attributes?.beta),
        });
      })
      .finally(() => setIsFetchingUserData(false));
  }, []);

  return {
    reportExcel: checkProperty(excelReport?.text),
    setModalShow,
    clearModalShowV,
    modal,
    setShowDonwload,
    showDownload,
    userData,
  };
};
