import React, { useState } from "react";
import { Divider, List } from "react-native-paper";
import Icon from "react-native-vector-icons/Ionicons";
import { Button, StyleSheet, Text, View } from "react-native";
import { ScrollView, TouchableOpacity } from "react-native-gesture-handler";

import { usePaidPlan } from "./usePaidPlans";
import FAQsCard from "../../Components/FAQsCard";
import { getCurrencySymbol } from "../../helpers/src";
import { globalClasses, globalStyles } from "../../styles";
import { faqs, plusInfo, proInfo } from "../../utils/Arrays";
import LoadingComponent from "../../Components/LoadingComponent";
import { slackNotificationPaidPlan } from "../../analytics/src/open-report";

const PlanAccordion = ({ color, title, description, background }) => {
  return (
    <View style={{ marginTop: 10 }}>
      <List.Accordion
        title={title}
        descriptionStyle={{ backgroundColor: background }}
        style={{ backgroundColor: globalStyles.colors.white }}
        left={() => <List.Icon icon="toggle-switch" color={color} />}
      >
        <Text>{description}</Text>
      </List.Accordion>
    </View>
  );
};

const PricesCard = ({
  periodTitle,
  periodCaption,
  value,
  valueDescription,
  color,
  navigationAction,
  contactPhone,
  codePrice,
}) => {
  return (
    <View style={{ marginBottom: 5 }}>
      <View style={globalClasses.spacedRow}>
        <View>
          <Text style={globalClasses.cardTitle}>{periodTitle}</Text>
          <Text style={globalClasses.caption}>{periodCaption}</Text>
        </View>
        <View style={{ flexDirection: "row" }}>
          <View style={{ textAlign: "right", marginRight: 6 }}>
            <Text style={globalClasses.cardTitle}>{value}</Text>
            <Text style={globalClasses.caption}>{periodTitle}</Text>
          </View>
          <View style={{ padding: 5, height: 30 }}>
            <Button
              title="Elegir"
              color={color}
              onPress={async () => {
                navigationAction(value, periodTitle, codePrice);
                await slackNotificationPaidPlan(contactPhone);
              }}
            />
          </View>
        </View>
      </View>
    </View>
  );
};

const PaidPlans = ({ navigation, route, type, close }) => {
  const {
    paidPlan,
    contactCountry,
    contactPhone,
    stipePayment,
    stripeLink,
    contactMemberShip,
    contactFullname,
    loader,
  } = usePaidPlan();

  const [planType, setPlanType] = useState("PLUS");

  const currencyFormat = Intl.NumberFormat("es-CO");
  const countryCurrency = contactCountry
    ? getCurrencySymbol(contactCountry)
    : "$";

  const plusPrices = [
    {
      periodTitle: "Mensual",
      periodCaption: "1 mes",
      value: `$${currencyFormat.format(paidPlan?.plusmesmes) || 0}`,
      valueDescription: "Por mes",
      codePrice: "plusmesmes",
    },
    {
      periodTitle: "Trimestral",
      periodCaption: "3 meses",
      value: `$${currencyFormat.format(paidPlan?.plustrimestretotal) || 0}`,
      valueDescription: `${countryCurrency}${
        currencyFormat.format(paidPlan?.plustrimestresmes) || 0
      }/mes`,
      codePrice: "plustrimestretotal",
    },
    {
      periodTitle: "Semestral",
      periodCaption: "6 meses",
      value: `$${currencyFormat.format(paidPlan?.plussemestretotal) || 0}`,
      valueDescription: `${countryCurrency}${
        currencyFormat.format(paidPlan?.plussemestremes) || 0
      }/mes`,
      codePrice: "plussemestretotal",
    },
  ];

  const proPrices = [
    {
      periodTitle: "Mensual",
      periodCaption: "1 mes",
      value: `${countryCurrency}${
        currencyFormat.format(paidPlan?.promesmes) || 0
      }`,
      valueDescription: "Por mes",
      codePrice: "promesmes",
    },
    {
      periodTitle: "Trimestral",
      periodCaption: "3 meses",
      value: `$${currencyFormat.format(paidPlan?.protrimestretotal) || 0}`,
      valueDescription: `$${
        currencyFormat.format(paidPlan?.protrimestresmes) || 0
      }/mes`,
      codePrice: "protrimestretotal",
    },
    {
      periodTitle: "Semestral",
      periodCaption: "6 meses",
      value: `$${currencyFormat.format(paidPlan?.prosemestretotal) || 0}`,
      valueDescription: `${countryCurrency}${
        currencyFormat.format(paidPlan?.prosemestremes) || 0
      }/mes`,
      codePrice: "prosemestretotal",
    },
  ];

  return (
    <ScrollView style={globalClasses.screenContainer}>
      <TouchableOpacity onPress={() => navigation.push("Menu")}>
        <Icon name="arrow-back-outline" size={30} color="#000000" />
      </TouchableOpacity>
      {!loader ? (
        <LoadingComponent />
      ) : (
        <View style={globalClasses.screenContainer}>
          <Text style={[globalClasses.cardTitle, { textAlign: "center" }]}>
            ¡Potencia tus finanzas!
          </Text>
          <Text
            style={{
              textAlign: "center",
              marginTop: globalStyles.shape.betweenSpace,
            }}
          >
            Lleva tus cuentas al siguiente nivel con los planes Qentaz 🚀
          </Text>
          <View
            style={{
              marginTop: globalStyles.shape.sectionSpace,

              width: "100%",
            }}
          >
            <View style={{ flexDirection: "row" }}>
              <View
                style={[
                  styles.planButton,
                  {
                    backgroundColor:
                      planType === "PLUS" && globalStyles.colors.lightSecondary,
                    borderTopLeftRadius: 10,
                    borderBottomLeftRadius: 10,
                  },
                ]}
              >
                <TouchableOpacity onPress={() => setPlanType("PLUS")}>
                  <Text
                    style={
                      planType === "PLUS" && [
                        styles.badge,
                        { backgroundColor: globalStyles.colors.secondary },
                      ]
                    }
                  >
                    PLUS
                  </Text>
                  <Text>
                    Desde{" "}
                    <Text style={globalClasses.cardTitle}>
                      ${currencyFormat.format(paidPlan.plusmesmes || 0)}
                    </Text>
                    {"/mes"}
                  </Text>
                </TouchableOpacity>
              </View>
              <View
                style={[
                  styles.planButton,
                  {
                    backgroundColor:
                      planType === "PRO" && globalStyles.colors.lightPositive,

                    borderTopRightRadius: 10,
                    borderBottomRightRadius: 10,
                  },
                ]}
              >
                <TouchableOpacity onPress={() => setPlanType("PRO")}>
                  <Text
                    style={
                      planType === "PRO" && [
                        styles.badge,
                        { backgroundColor: globalStyles.colors.primary },
                      ]
                    }
                  >
                    PRO
                  </Text>
                  <Text style={{ color: globalStyles.colors.darkGrey }}>
                    Desde{" "}
                    <Text
                      style={[
                        globalClasses.cardTitle,
                        { color: globalStyles.colors.darkGrey },
                      ]}
                    >
                      ${currencyFormat.format(paidPlan?.promesmes || 0)}
                    </Text>
                    {"/mes"}
                  </Text>
                </TouchableOpacity>
              </View>
            </View>
          </View>
          <Text style={globalClasses.sectionTitle}>
            ¿Qué incluye el plan plus?
          </Text>
          {planType === "PLUS"
            ? plusInfo.map((item) => (
                <PlanAccordion
                  color={globalStyles.colors.secondary}
                  title={item.title}
                  description={item.description}
                  background={globalStyles.colors.lightSecondary}
                />
              ))
            : proInfo.map((item) => (
                <PlanAccordion
                  color={globalStyles.colors.primary}
                  title={item.title}
                  description={item.description}
                  background={globalStyles.colors.lightPositive}
                />
              ))}
          <View style={{ marginTop: globalStyles.shape.sectionSpace }}>
            {contactMemberShip === "basic" && (
              <Button
                title="¡Probar 15 días gratis!"
                color={
                  planType === "PLUS"
                    ? globalStyles.colors.secondary
                    : globalStyles.colors.primary
                }
                onPress={() => navigation.push("Checkout")}
              />
            )}
          </View>
          <Text style={globalClasses.sectionTitle}>¡Adquiere tu plan!</Text>
          <View style={[globalClasses.card, globalClasses.shadow]}>
            {planType === "PLUS"
              ? plusPrices.map((item) => (
                  <PricesCard
                    navigationAction={(price, valueDescription, codePrice) =>
                      navigation.push("Checkout", {
                        price,
                        planType: "PLUS",
                        contactCountry,
                        contactPhone,
                        valueDescription,
                        contactFullname,
                        codePrice,
                      })
                    }
                    periodTitle={item.periodTitle}
                    periodCaption={item.periodCaption}
                    value={item.value}
                    valueDescription={item.valueDescription}
                    color={globalStyles.colors.secondary}
                    contactPhone={contactPhone}
                    codePrice={item.codePrice}
                  />
                ))
              : proPrices.map((item) => (
                  <PricesCard
                    navigationAction={(price, valueDescription, codePrice) =>
                      navigation.push("Checkout", {
                        price,
                        planType: "PRO",
                        contactCountry,
                        contactPhone,
                        valueDescription,
                        contactFullname,
                        codePrice,
                      })
                    }
                    periodTitle={item.periodTitle}
                    periodCaption={item.periodCaption}
                    value={item.value}
                    valueDescription={item.valueDescription}
                    color={globalStyles.colors.primary}
                    contactPhone={contactPhone}
                    codePrice={item.codePrice}
                  />
                ))}
          </View>
          <Divider style={{ marginTop: globalStyles.shape.sectionSpace }} />
          <Text style={globalClasses.sectionTitle}>PreguntasFrecuentes</Text>
          {faqs.map((item) => (
            <FAQsCard question={item.qestion} answer={item.answer} />
          ))}
        </View>
      )}
    </ScrollView>
  );
};

export default PaidPlans;

const styles = StyleSheet.create({
  planButton: {
    borderWidth: 1,
    borderColor: globalStyles.colors.darkGrey,
    alignSelf: "center",
    textAlign: "center",
    padding: 10,
    width: "50%",
  },
  badge: {
    alignSelf: "center",
    width: 60,
    padding: 1,
    borderRadius: 20,
    fontWeight: "700",
  },
});
