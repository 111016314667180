import _ from "lodash";
import React, { useEffect, useState } from "react";
import { IconButton, TextInput } from "react-native-paper";
import Ionicon from "react-native-vector-icons/Ionicons";
import { Calendar, LocaleConfig } from "react-native-calendars";
import { Button, Modal, StyleSheet, Text, View } from "react-native";
import { ScrollView, TouchableOpacity } from "react-native-gesture-handler";

import { globalClasses, globalStyles } from "../../styles";
import ModalTemplate from "../ModalTemplate";

LocaleConfig.locales["es"] = {
  monthNames: [
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre",
  ],
  monthNames: [
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre",
  ],
  monthNamesShort: [
    "Ene",
    "Feb",
    "Mar",
    "Abr",
    "May",
    "Jun",
    "Jul",
    "Agos",
    "Sept",
    "Oct",
    "Nov",
    "Déc",
  ],
  dayNames: [
    "Lunes",
    "Martes",
    "Miercoles",
    "Jueves",
    "Viernes",
    "Sábado",
    "Domingo",
  ],
  dayNamesShort: ["Lun", "Mar", "Mier", "Jue", "Vie", "Sab", "Dom"],
  today: "Hoy",
};
LocaleConfig.defaultLocale = "es";
const EditTx = ({
  id,
  show,
  dismiss,
  date,
  value,
  money,
  categoryType,
  description,
  tx,
  userType,
  saveAction,
  newTxData,
  updateNewTxInfo,
  deleteAction,
  userMembership,
  clearTxInfo,
  activateTx,
}) => {
  const [selected, setSelected] = useState("");
  const [calenderVisible, setCalenderVisible] = useState(false);

  const [disclaimer, setDisclaimer] = useState(false);
  const [infoChange, setInfoChange] = useState(false);
  const formatDate = new Intl.DateTimeFormat("es");

  const newDate = new Date(date);

  newDate.setDate(newDate.getDate() + 1);

  const saveAndClose = () => {
    const type1 = (newTxData?.type || tx) === "income" ? "Ingreso" : "Gasto";
    const description1 = newTxData?.description || description;
    saveAction(id, type1, description1);
    dismiss();
    setTimeout(() => {
      activateTx();
    }, 1000);
  };

  return (
    <>
      <ModalTemplate visible={show}>
        <>
          <IconButton
            icon="close"
            onPress={() => (infoChange ? setDisclaimer(true) : dismiss())}
            iconColor={globalStyles.colors.darkGrey}
            size={25}
          />
          <Text
            style={[
              globalClasses.cardTitle,
              { textAlign: "center", fontWeight: "700", marginBottom: 15 },
            ]}
          >
            Editar Transacción
          </Text>
          <Text style={globalClasses.cardTitlePeriod}>
            Tipo de la transacción
          </Text>
          <View style={[globalClasses.spacedRow, { marginTop: 10 }]}>
            <View style={{ width: "45%" }}>
              <TouchableOpacity
                onPress={() => {
                  updateNewTxInfo("type", "income");
                  setInfoChange(true);
                }}
              >
                <View
                  style={[
                    styles.txTypeButton,
                    (newTxData?.type || tx) === "income"
                      ? styles.positive
                      : globalClasses.shadow,
                  ]}
                >
                  <Ionicon
                    name="arrow-down-circle"
                    size={20}
                    color={
                      (newTxData?.type || tx) === "income"
                        ? globalStyles.colors.primary
                        : globalStyles.colors.darkGrey
                    }
                  />
                  <Text
                    style={[
                      globalClasses.smallTitle,
                      {
                        color:
                          (newTxData?.type || tx) === "income"
                            ? globalStyles.colors.primary
                            : globalStyles.colors.darkGrey,
                      },
                    ]}
                  >
                    Ingreso
                  </Text>
                </View>
              </TouchableOpacity>
            </View>
            <View style={{ width: "45%" }}>
              <TouchableOpacity
                onPress={() => {
                  updateNewTxInfo("type", "outcome");
                  setInfoChange(true);
                }}
              >
                <View
                  style={[
                    styles.txTypeButton,
                    (newTxData?.type || tx) !== "income"
                      ? styles.negative
                      : globalClasses.shadow,
                  ]}
                >
                  <Ionicon
                    name="arrow-up-circle"
                    size={20}
                    color={
                      (newTxData?.type || tx) !== "income"
                        ? globalStyles.colors.red
                        : globalStyles.colors.darkGrey
                    }
                  />
                  <Text
                    style={[
                      globalClasses.smallTitle,
                      {
                        color:
                          (newTxData?.type || tx) !== "income"
                            ? globalStyles.colors.red
                            : globalStyles.colors.darkGrey,
                      },
                    ]}
                  >
                    Gasto
                  </Text>
                </View>
              </TouchableOpacity>
            </View>
          </View>
          <Text style={[globalClasses.cardTitlePeriod, { marginTop: 15 }]}>
            Descripción
          </Text>
          <TextInput
            mode="outlined"
            value={
              typeof newTxData?.description === "string"
                ? newTxData?.description
                : description
            }
            onChangeText={(val) => {
              updateNewTxInfo("description", val);
              setInfoChange(true);
            }}
            activeOutlineColor={globalStyles.colors.primary}
            theme={{
              roundness: 10,
            }}
          />
          <View style={[globalClasses.spacedRow, { marginTop: 15 }]}>
            <View style={{ width: "45%" }}>
              <Text style={globalClasses.cardTitlePeriod}> Valor</Text>
              <TextInput
                mode="outlined"
                value={
                  typeof newTxData?.amount === "string"
                    ? newTxData?.amount
                    : value
                }
                onChangeText={(val) => {
                  updateNewTxInfo("amount", val);
                  setInfoChange(true);
                }}
                activeOutlineColor={globalStyles.colors.primary}
                left={<TextInput.Affix text={money} />}
                theme={{
                  roundness: 10,
                }}
                keyboardType="numeric"
              />
            </View>
            <View style={{ width: "45%" }}>
              <Text style={globalClasses.cardTitlePeriod}> Fecha</Text>
              <TouchableOpacity
                onPress={() => setCalenderVisible(!calenderVisible)}
              >
                <View style={styles.dateButton}>
                  <Text style={{ textAlign: "center" }}>
                    {newTxData?.date
                      ? formatDate.format(new Date(`${newTxData?.date} `))
                      : formatDate.format(newDate)}
                  </Text>
                </View>
              </TouchableOpacity>
            </View>
          </View>

          {calenderVisible && (
            <View>
              <Calendar
                onDayPress={(day) => {
                  setSelected(day.dateString);
                  updateNewTxInfo("date", day.dateString);
                  setCalenderVisible(false);
                  setInfoChange(true);
                }}
                markedDates={{
                  [selected]: {
                    selected: true,
                    disableTouchEvent: true,
                    selectedColor: globalStyles.colors.primary,
                  },
                }}
                theme={{
                  arrowColor: "#000",
                  backgroundColor: "#000",
                }}
              />
            </View>
          )}

          {userMembership.toLowerCase() !== "basic" && (
            <>
              <Text style={[globalClasses.cardTitlePeriod, { marginTop: 15 }]}>
                {" "}
                Categoría
              </Text>
              <View style={[globalClasses.spacedRow, { marginTop: 10 }]}>
                <View style={{ width: "45%" }}>
                  <TouchableOpacity
                    onPress={() => {
                      updateNewTxInfo("categoryType", "NEGOCIO");
                      setInfoChange(true);
                    }}
                  >
                    <View
                      style={[
                        styles.txTypeButton,
                        (newTxData?.categoryType || categoryType) === "NEGOCIO"
                          ? styles.positive
                          : globalClasses.shadow,
                        ,
                      ]}
                    >
                      <Ionicon
                        name="briefcase"
                        size={20}
                        color={
                          (newTxData?.categoryType || categoryType) ===
                          "NEGOCIO"
                            ? globalStyles.colors.primary
                            : globalStyles.colors.darkGrey
                        }
                      />
                      <Text
                        style={[
                          globalClasses.smallTitle,
                          {
                            color:
                              (newTxData?.categoryType || categoryType) ===
                              "NEGOCIO"
                                ? globalStyles.colors.primary
                                : globalStyles.colors.darkGrey,
                          },
                        ]}
                      >
                        Negocio
                      </Text>
                    </View>
                  </TouchableOpacity>
                </View>
                <View style={{ width: "45%" }}>
                  <TouchableOpacity
                    onPress={() => {
                      updateNewTxInfo("categoryType", "PERSONAL");
                      setInfoChange(true);
                    }}
                  >
                    <View
                      style={[
                        styles.txTypeButton,
                        (newTxData?.categoryType || categoryType) === "PERSONAL"
                          ? styles.positive
                          : globalClasses.shadow,
                        ,
                      ]}
                    >
                      <Ionicon
                        name="person"
                        size={20}
                        color={
                          (newTxData?.categoryType || categoryType) ===
                          "PERSONAL"
                            ? globalStyles.colors.primary
                            : globalStyles.colors.darkGrey
                        }
                      />
                      <Text
                        style={[
                          globalClasses.smallTitle,
                          {
                            color:
                              (newTxData?.categoryType || categoryType) ===
                              "PERSONAL"
                                ? globalStyles.colors.primary
                                : globalStyles.colors.darkGrey,
                          },
                        ]}
                      >
                        Personal
                      </Text>
                    </View>
                  </TouchableOpacity>
                </View>
              </View>
            </>
          )}
          <View style={{ marginTop: 15 }}>
            <Button
              onPress={saveAndClose}
              disabled={_.isEmpty(newTxData)}
              title="guardar cambios"
              color={globalStyles.colors.primary}
            />
          </View>

          <TouchableOpacity
            style={{ marginBottom: 8 }}
            onPress={() => {
              deleteAction();
              dismiss();
            }}
          >
            <View
              style={{
                flexDirection: "row",
                alignSelf: "center",
                marginTop: 15,
              }}
            >
              <Ionicon name="trash" size={17} color={globalStyles.colors.red} />
              <Text
                style={[
                  globalClasses.cardTitlePeriod,
                  {
                    color: globalStyles.colors.red,
                    marginLeft: 10,
                  },
                ]}
              >
                Eliminar transacción
              </Text>
            </View>
          </TouchableOpacity>
        </>
      </ModalTemplate>

      {infoChange && (
        <ModalTemplate visible={disclaimer}>
          <>
            <View style={{ textAlign: "center" }}>
              <Text
                style={[
                  globalClasses.cardTitle,
                  { fontWeight: "700", marginBottom: 15 },
                ]}
              >
                ¿Estás seguro de cancelar?
              </Text>
              <Text style={globalClasses.cardTitlePeriod}>
                Toda la información que modificaste se borrará
              </Text>

              <View style={[globalClasses.spacedRow, { marginTop: 10 }]}>
                <View style={{ width: "45%" }}>
                  <TouchableOpacity
                    onPress={() => {
                      dismiss();
                      clearTxInfo();
                      setInfoChange(false);
                      setDisclaimer(false);
                    }}
                  >
                    <View
                      style={[
                        styles.confirmationButton,
                        {
                          borderColor: globalStyles.colors.red,
                        },
                      ]}
                    >
                      <Text style={{ color: globalStyles.colors.red }}>
                        ¡Si! Cerrar
                      </Text>
                    </View>
                  </TouchableOpacity>
                </View>
                <View style={{ width: "45%" }}>
                  <TouchableOpacity onPress={() => setDisclaimer(false)}>
                    <View
                      style={[
                        styles.confirmationButton,
                        {
                          borderColor: globalStyles.colors.primary,
                          backgroundColor: globalStyles.colors.primary,
                        },
                      ]}
                    >
                      <Text style={{ color: globalStyles.colors.white }}>
                        Seguir editando
                      </Text>
                    </View>
                  </TouchableOpacity>
                </View>
              </View>
            </View>
          </>
        </ModalTemplate>
      )}
    </>
  );
};

export default EditTx;

const styles = StyleSheet.create({
  dateButton: {
    marginTop: 8,
    padding: 10,
    borderRadius: 10,
    borderWidth: 2,
    paddingHorizontal: 10,
    borderColor: globalStyles.colors.darkGrey,
  },
  txTypeButton: {
    padding: 5,
    borderRadius: 10,
    borderWidth: 2,
    paddingHorizontal: 20,
    borderColor: globalStyles.colors.darkGrey,

    flexDirection: "row",
    justifyContent: "space-around",
  },
  positive: {
    backgroundColor: globalStyles.colors.lightPositive,
    borderColor: globalStyles.colors.primary,
  },
  negative: {
    backgroundColor: globalStyles.colors.lightNegative,
    borderColor: globalStyles.colors.red,
  },
  confirmationButton: { padding: 8, borderWidth: 2, borderRadius: 10 },
});
