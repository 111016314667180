import { StyleSheet, Text, View } from "react-native";
import React from "react";
import { globalClasses, globalStyles } from "../styles";

const BalanceCard = ({
  countryCurrency,
  totalBalance,
  incomeTotal,
  outcomeTotal,
}) => {
  const currencyFormat = Intl.NumberFormat("es-CO");

  const progress =
    !Number(incomeTotal?.replace(/\D/g, "")) &&
    !Number(outcomeTotal?.replace(/\D/g, ""))
      ? 50
      : (
          (Number(incomeTotal?.replace(/\D/g, "")) /
            (Number(incomeTotal?.replace(/\D/g, "")) +
              Number(outcomeTotal?.replace(/\D/g, "")))) *
          100
        ).toFixed(0);

  return (
    <View style={[globalClasses.card, globalClasses.shadow]}>
      <Text style={globalClasses.cardTitle}> Balance 👇</Text>
      <Text style={globalClasses.breakTitle}>
        {countryCurrency}
        {totalBalance}
      </Text>
      <View style={styles.bar}>
        <View
          style={{
            width: `${progress}%`,
            height: 10,
            borderRadius: 10,
            backgroundColor: globalStyles.colors.primary,
          }}
        ></View>
      </View>
      <View style={styles.captionContainer}>
        <View style={{ flexDirection: "row" }}>
          <Text
            style={[
              globalClasses.smallTitle,
              { color: globalStyles.colors.primary },
            ]}
          >
            Ingresos:{" "}
          </Text>
          <Text>
            {countryCurrency}
            {incomeTotal}
          </Text>
        </View>
        <View style={{ flexDirection: "row" }}>
          <Text
            style={[
              globalClasses.smallTitle,
              { color: globalStyles.colors.red },
            ]}
          >
            Gastos:{" "}
          </Text>
          <Text>
            {countryCurrency}
            {outcomeTotal}
          </Text>
        </View>
      </View>
    </View>
  );
};

export default BalanceCard;

const styles = StyleSheet.create({
  cardContainer: {
    width: "100%",
    borderRadius: 10,
    padding: 15,
    borderWidth: 1,
    borderColor: "#dedede",
  },
  bar: {
    width: "100%",
    height: 10,
    borderRadius: 10,
    backgroundColor: globalStyles.colors.red,
    marginTop: globalStyles.shape.betweenSpace,
  },
  percentage: {
    width: "60%",
    height: 10,
    borderRadius: 10,
    backgroundColor: globalStyles.colors.primary,
  },
  captionContainer: {
    marginTop: 5,
    flexDirection: "row",
    justifyContent: "space-between",
  },
});
