import _ from "lodash";
import React, { useEffect, useState } from "react";
import { Chip, Searchbar } from "react-native-paper";
import Ionicon from "react-native-vector-icons/Ionicons";
import {
  Text,
  View,
  Image,
  TouchableOpacity,
  StyleSheet,
  Button,
} from "react-native";

import {
  visitPageMovements,
  visitPageSummary,
} from "../../analytics/src/open-report";
import Screen from "../../Templates/Screen";
import { useTransaction } from "./useTransaction";
import DateButtons from "../../Components/DateButtons";
import BalanceCard from "../../Components/BalanceCard";
import PeriodSlider from "../../Components/PeriodSlider";
import { globalClasses, globalStyles } from "../../styles";
import DeleteSnackbar from "../../Components/DeleteSnackbar";
import TopTransactions from "../../Components/TopTransactions";
import TransactionsList from "../../Components/TransactionsList";
import TxModifySnackbar from "../../Components/TxModifySnackbar";
import InterpretationCard from "../../Components/InterpretationCard";

const Transactions = ({ navigation, route }) => {
  const {
    financeData: {
      transactions,
      total_balance,
      income_transactions,
      outcome_transactions,
      total_income,
      total_outcome,
      weeks_days,
    },
    userData: {
      countryCurrency,
      userType,
      phone,
      purposeUse,
      userMembership,
      userRole,
      beta,
      name
    },
    onChangeSearch,
    searchQuery,
    newTxData,
    updateNewTxInfo,
    deleteTransaction,
    updateTransaction,
    date,
    clearTxInfo,
    setDate,
    namesFilter,
    setNamesFilter,
    paramsDate,
    msg,
    loader,
    label,
    setLabel,
    index,
    setIndex,
  } = useTransaction({ type: undefined });

  //Delete tx
  const [showSnackbar, setShowSnackbar] = useState(false);
  const activateSnackbar = () => setShowSnackbar(!showSnackbar);
  const dismissSnackbar = () => setShowSnackbar(false);
  //Modify tx
  const [showTx, setShowTx] = React.useState(false);
  const activateTx = () => setShowTx(!showTx);
  const dismissTx = () => setShowTx(false);

  const setAnalytic = async () =>
    visitPageMovements(phone, { type: "navigation" });

  useEffect(() => {
    if (phone && !route?.params?.nav) {
      setAnalytic();
    }
  }, [phone]);
  //filter
  const [open, setOpen] = useState(false);

  const toggleFilter = () => setOpen(!open);
  const [defineOptions, setDetineOptions] = useState(false);

  return (
    <>
      <Screen loader={loader} route={route} phone={phone}>
        <DateButtons
          date={date}
          setDate={setDate}
          setLabel={setLabel}
          label={label}
          contactPhone={phone}
        />
        <PeriodSlider
          from={date}
          contactPhone={phone}
          setCurrentDate={setDate}
          label={label}
          currentDate={date}
          paramsDate={paramsDate}
          weeks_days={weeks_days}
        />
        <BalanceCard
          countryCurrency={countryCurrency}
          totalBalance={total_balance}
          incomeTotal={total_income}
          outcomeTotal={total_outcome}
        />
        <InterpretationCard
          description={
            Number(total_balance) === 0
              ? "¡Lo siento! No tengo información suficiente para analizar 😔"
              : msg
                  ?.replace(
                    "gastosDiarios",
                    `${countryCurrency}${total_outcome}`
                  )
                  ?.replace("gastosMes", `${countryCurrency}${total_outcome}`)
                  ?.replace(
                    "gastosSemanal",
                    `${countryCurrency}${total_outcome}`
                  )
          }
        />
        {purposeUse !== "META_GANANCIA" ? (
          <>
            <Text style={globalClasses.sectionTitle}>¿En qué gasté más?</Text>
            {purposeUse === "META_DEUDA" && (
              <Text style={globalClasses.caption}>
                Ten tus gastos bajo control, ahorra y ya no te preocupes por tus
                deudas
              </Text>
            )}
            {outcome_transactions?.map((x, i) => (
              <TopTransactions
                tx={x.type === "income" ? "ingreso" : ""}
                number={i + 1}
                description={x.description}
                amount={x.amount}
                date={x.date}
                countryCurrency={countryCurrency}
              />
            ))}{" "}
          </>
        ) : (
          <>
            <Text style={globalClasses.sectionTitle}>¿En qué ganas más?</Text>
            {income_transactions?.map((x, i) => (
              <TopTransactions
                tx={x.type === "income" ? "ingreso" : ""}
                number={i + 1}
                description={x.description}
                amount={x.amount}
                date={x.date}
                countryCurrency={countryCurrency}
              />
            ))}{" "}
          </>
        )}
        <TouchableOpacity
          onPress={async () => {
            navigation.navigate("Análisis", {
              nav: true,
            });

            await visitPageSummary(phone, {
              previous_screen: "Movimientos",
              type: "banner",
            });
          }}
        >
          <View
            style={{
              marginTop: globalStyles.shape.sectionSpace,
              borderRadius: 10,

              overflow: "hidden",
            }}
          >
            <Image
              source={{
                uri: `https://qentaz.s3.amazonaws.com/public/Reporte/BANNER_Analisis.jpg`,
              }}
              resizeMode="contain"
              style={{ width: "100%", height: 120 }}
            />
          </View>
        </TouchableOpacity>
        <Text style={globalClasses.sectionTitle}>
          Tus movimientos a detalle 🔍
        </Text>
        <View>
          {transactions?.length ? (
            <>
              <View style={styles.filterContainer}>
                <View
                  style={{
                    width: userMembership === "pro" && beta && "65%",
                  }}
                >
                  <Searchbar
                    placeholder="Busqueda"
                    onChangeText={onChangeSearch}
                    value={searchQuery}
                    inputStyle={globalClasses.smallTitle}
                  />
                </View>
                {userMembership === "pro" && beta && userRole !== "member" && (
                  <View style={{ width: "30%" }}>
                    <TouchableOpacity onPress={toggleFilter}>
                      <View
                        style={[
                          styles.filterButton,
                          globalClasses.spacedRow,
                          globalClasses.shadow,
                        ]}
                      >
                        <Ionicon
                          name="filter"
                          size={20}
                          color={globalStyles.colors.primary}
                        />
                        <Text
                          style={[
                            globalClasses.smallTitle,
                            { color: globalStyles.colors.primary },
                          ]}
                        >
                          Filtrar
                        </Text>
                      </View>
                    </TouchableOpacity>
                  </View>
                )}
              </View>
              {open && (
                <View style={globalClasses.card}>
                  <View style={styles.chipsContainer}>
                    {namesFilter.map(({ name: memberName, phone: i }) => (
                      <Chip
                        onPress={
                          !index.includes(i?.replace("+", ""))
                            ? () => setIndex([...index, i.replace("+", "")])
                            : null
                        }
                        // disabled={index.includes(i)}
                        disabled={index.length}
                        onClose={
                          index.includes(i?.replace("+", "")) &&
                          (() =>
                            setIndex([...index.splice(i?.replace("+", ""), 1)]))
                        }
                        selected={index.includes(i?.replace("+", ""))}
                        selectedColor={
                          index.includes(i?.replace("+", ""))
                            ? globalStyles.colors.primary
                            : globalStyles.colors.darkGrey
                        }
                        style={[
                          styles.filterChip,
                          // isSelected &&
                          index.includes(i?.replace("+", "")) && {
                            backgroundColor: globalStyles.colors.lightPositive,
                          },
                        ]}
                        mode="outlined"
                      >
                        {memberName}
                      </Chip>
                    ))}
                  </View>
                  {/* <View style={styles.aceptOptionsButton}>
                    <Button
                      disabled={_.isEmpty(index)}
                      onPress={() => {
                        setDetineOptions(true);
                        setOpen(false);
                      }}
                      title="aplicar"
                      color={globalStyles.colors.primary}
                    />
                  </View> */}
                </View>
              )}
              {defineOptions && !_.isEmpty(index) && (
                <View style={[styles.chipsContainer, { marginTop: 15 }]}>
                  <Text
                    style={[
                      globalClasses.cardTitlePeriod,
                      { marginTop: 6, marginRight: 8 },
                    ]}
                  >
                    Filtrado por:
                  </Text>
                  {namesFilter
                    .filter((val, i) => index.includes(i))
                    .map((n, i) => (
                      <Chip
                        selectedColor={globalStyles.colors.primary}
                        onClose={() => setIndex([...index.splice(i, 1)])}
                        style={[
                          styles.filterChip,
                          {
                            backgroundColor: globalStyles.colors.lightPositive,
                          },
                        ]}
                        mode="outlined"
                      >
                        {n}
                      </Chip>
                    ))}
                </View>
              )}
              {transactions
                .filter((x) =>
                  x.attributes.description.toLowerCase().match(searchQuery)
                )
                .filter((x) =>
                  index.length ? index.includes(x.attributes.memberPhone) : x
                )
                .map(({ id, attributes: x }) => (
                  <TransactionsList
                    id={id}
                    description={x.description}
                    date={x.date}
                    amount={x.amount}
                    countryCurrency={countryCurrency}
                    tx={x.type}
                    updateTransaction={updateTransaction}
                    categoryType={x.categoryType}
                    deleteTransaction={deleteTransaction}
                    activateSnackbar={activateSnackbar}
                    phone={phone}
                    userType={userType}
                    userMembership={userMembership}
                    newTxData={newTxData}
                    updateNewTxInfo={updateNewTxInfo}
                    clearTxInfo={clearTxInfo}
                    activateTx={activateTx}
                    beta={beta}
                    name={
                      namesFilter.filter(
                        (v) => v.phone?.replace("+", "") == x?.memberPhone
                      )[0]?.name
                    }
                    userName={name}
                  />
                ))}
            </>
          ) : (
            <View>
              <Text>No tienes movimientos recientes</Text>
            </View>
          )}
        </View>
      </Screen>
      <TxModifySnackbar showSnackbar={showTx} dismissSnackbar={dismissTx} />
      <DeleteSnackbar
        showSnackbar={showSnackbar}
        dismissSnackbar={dismissSnackbar}
      />
    </>
  );
};

export default Transactions;
const styles = StyleSheet.create({
  filterButton: {
    alignItems: "center",
    borderRadius: 10,
    paddingHorizontal: 16,
    paddingVertical: 14,
    backgroundColor: "#f6f8f7",
  },
  filterContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: 20,
  },
  filterChip: {
    backgroundColor: globalStyles.colors.lightGrey,
    marginRight: 8,
    marginBottom: 8,
    borderRadius: 12,
  },
  chipsContainer: { flex: 1, flexDirection: "row", flexWrap: "wrap" },
  aceptOptionsButton: { alignSelf: "flex-end", marginTop: 7, width: 140 },
});
