import { BASE_URL, DELETE_DEBT, UPDATE_DEBT } from "../../config";
import { post } from "../base";

const deleteDebt = async ({ debtId }) => {
  const data = {
    debtId,
  };

  const requestUrl = `${BASE_URL}${DELETE_DEBT}`;

  return post(requestUrl, data);
};

const updateDebt = async ({
  debtId,
  active,
  currentAmount,
  newDate,
  newPaymentDate,
  type,
}) => {
  const data = {
    debtId,
    active,
    currentAmount,
    newDate,
    newPaymentDate,
    type,
  };

  const requestUrl = `${BASE_URL}${UPDATE_DEBT}`;

  return post(requestUrl, data);
};

export const DebtAPI = {
  deleteDebt,
  updateDebt,
};
