import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import Ionicons from "react-native-vector-icons/Ionicons";
import React, { useEffect, useRef, useState } from "react";
import { View, Text, Button, StyleSheet } from "react-native";
import { TouchableOpacity } from "react-native-gesture-handler";
import { IconButton, Snackbar, TextInput } from "react-native-paper";
import { useMultiUserForm } from "./userMultiUserForm";
import ModalTemplate from "../../ModalTemplate";
import { globalClasses, globalStyles } from "../../../styles";

const MultiUserForm = ({ show, dismiss, userInfo, setRefreshUserData }) => {
  const phoneInput = useRef(null);
  const [showSnackbar, setShowSnackbar] = useState(false);

  const {
    newUser,
    setNewUser,
    phoneNumber,
    setPhoneNumber,
    sendConfirmationMultiAccount,
    updateMultiAccountUser,
  } = useMultiUserForm({
    userInfo: {
      name: userInfo?.name,
      phone: userInfo?.phone,
      userRol: userInfo?.userRole,
    },
    setRefreshUserData,
  });

  return (
    <>
      <ModalTemplate visible={show}>
        <IconButton
          icon="close"
          onPress={dismiss}
          iconColor={globalStyles.colors.darkGrey}
          size={25}
        />
        <Text
          style={[
            globalClasses.cardTitle,
            { fontWeight: "700", textAlign: "center" },
          ]}
        >
          {userInfo ? "Editar usuario" : "Agregar otros usuarios a tu negocio"}
        </Text>
        <View style={{ marginTop: 15 }}>
          <Text style={globalClasses.cardTitlePeriod}>Nombre del usuario</Text>
          <TextInput
            value={newUser.name}
            mode="outlined"
            activeOutlineColor={globalStyles.colors.primary}
            onChangeText={(val) => setNewUser({ ...newUser, name: val })}
          />
        </View>
        <View style={{ marginTop: 15 }}>
          <Text style={[globalClasses.cardTitlePeriod, { marginBottom: 8 }]}>
            Celular
          </Text>
          <PhoneInput
            defaultCountry="CO"
            placeholder="Número de celular"
            value={phoneNumber}
            disabled={userInfo?.phone ? true : false}
            onChange={setPhoneNumber}
          />
        </View>
        <View style={{ marginTop: 15 }}>
          <Text style={globalClasses.cardTitlePeriod}>Tipo de usuario</Text>
          <View style={[globalClasses.spacedRow, { marginVertical: 10 }]}>
            <View style={{ width: "47%" }}>
              <TouchableOpacity
                onPress={() => setNewUser({ ...newUser, userRol: 1 })}
              >
                <View
                  style={[
                    styles.txTypeButton,
                    newUser.userRol === 1
                      ? styles.positive
                      : globalClasses.shadow,
                  ]}
                >
                  <Text
                    style={[
                      globalClasses.smallTitle,
                      {
                        color:
                          newUser.userRol === 1
                            ? globalStyles.colors.primary
                            : globalStyles.colors.darkGrey,
                      },
                    ]}
                  >
                    Administrador
                  </Text>
                </View>
              </TouchableOpacity>
            </View>
            <View style={{ width: "47%" }}>
              <TouchableOpacity
                onPress={() => setNewUser({ ...newUser, userRol: 2 })}
              >
                <View
                  style={[
                    styles.txTypeButton,
                    newUser.userRol === 2
                      ? styles.positive
                      : globalClasses.shadow,
                  ]}
                >
                  <Text
                    style={[
                      globalClasses.smallTitle,
                      {
                        color:
                          newUser.userRol === 2
                            ? globalStyles.colors.primary
                            : globalStyles.colors.darkGrey,
                      },
                    ]}
                  >
                    Miembro
                  </Text>
                </View>
              </TouchableOpacity>
            </View>
          </View>
        </View>
        <Text style={[globalClasses.caption, { textAlign: "center" }]}>
          El usuario "Miembro" unicamente puede crear, editar y revisar su
          información. El usuario "Administrador" puede ver la información de
          todos los miembros.
        </Text>
        <View style={{ marginVertical: 15 }}>
          <Button
            title={userInfo ? "Aplicar cambios" : "Agregar nuevo usuario"}
            color={globalStyles.colors.primary}
            onPress={() => {
              userInfo
                ? (dismiss(), updateMultiAccountUser(), setShowSnackbar(true))
                : (dismiss(),
                  sendConfirmationMultiAccount(),
                  setShowSnackbar(true));
            }}
          />
        </View>
      </ModalTemplate>
      <Snackbar
        duration={5000}
        style={{ backgroundColor: "rgba(255,255,255,1)" }}
        visible={showSnackbar}
        onDismiss={() => setShowSnackbar(false)}
      >
        <View style={{ flexDirection: "row" }}>
          <View style={{ padding: 5 }}>
            <Ionicons
              name="checkmark-circle"
              size={30}
              color={globalStyles.colors.primary}
            />
          </View>
          <View>
            <Text>¡Usuario agregado exitosamente!</Text>
            <Text style={globalClasses.smallTitle}>
              En breve recibirá un mensaje de confirmación
            </Text>
          </View>
        </View>
      </Snackbar>
    </>
  );
};

export default MultiUserForm;
const styles = StyleSheet.create({
  txTypeButton: {
    padding: 7,
    borderRadius: 10,
    borderWidth: 2,
    paddingHorizontal: 18,
    borderColor: globalStyles.colors.darkGrey,
    textAlign: "center",
  },
  positive: {
    backgroundColor: globalStyles.colors.lightPositive,
    borderColor: globalStyles.colors.primary,
  },
});
