import React, { useState, useEffect } from "react";
import { DashboardAPI } from "../../api/src/dashboard";
import { UserInfoAPI } from "../../api/src/user-info";
import {
  checkProperty,
  getHashId,
  getDate,
  getCurrencySymbol,
  changeDateFilter,
} from "../../helpers/src";
import { DebtAPI } from "../../api/src/debts";
import { visitPageDebts } from "../../analytics/src/open-report";
import axios from "axios";
import { sendEventChatwood } from "../../events";
import {
  COMPLETE_DEBT,
  DELETE_DEBT,
  PAY_DEBT,
  RESCHEDULE_DEBT,
} from "../../events/model";

export const useDebts = () => {
  const paramsId = window.location.pathname || localStorage.getItem("hashId");
  const paramsDate = window.location.search || "?from=hoy";
  const [isFetchingData, setIsFetchingData] = useState(false);
  const [isFetchingUserData, setIsFetchingUserData] = useState(false);
  const dateOptions = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  };

  const [financeData, setFinanceData] = useState({});
  const [userData, setUserData] = useState({});
  const [date, setDate] = useState("");
  const [newDate, setNewDate] = useState("");

  const [urlRoutes, setUrlRoute] = useState("");
  const [recharge, setRecharge] = useState(false);
  const [payDebt, setPayDebt] = useState(null);
  const [msg, setMsg] = useState("");
  const [loader, setLoader] = useState(false);

  const deleteDebt = (id, type, holder) => {
    DebtAPI.deleteDebt({ debtId: id }).finally(() => {
      setRecharge(true);
      const convertType = type.includes("pagar") ? "por pagar" : "por cobrar";
      const body = DELETE_DEBT.body
        .replace("TYPE", convertType)
        .replace("HOLDER", holder);

      sendEventChatwood(userData.phone, DELETE_DEBT.title, body);
    });
  };
  const updateDebt = ({
    id,
    active,
    currentAmount,
    newDate,
    newPaymentDate,
    type,
    holder,
    typeAction,
  }) => {
    DebtAPI.updateDebt({
      debtId: id,
      active,
      currentAmount,
      newDate,
      newPaymentDate,
      type,
    })
      .then(() => setRecharge(true))
      .finally(() => {
        setRecharge(false);
        setPayDebt(null);
        setNewDate(null);
        const convertType = type.includes("pagar") ? "por pagar" : "por cobrar";

        const body =
          typeAction === "Complete"
            ? COMPLETE_DEBT.body
            : typeAction === "Pay"
            ? PAY_DEBT.body
            : RESCHEDULE_DEBT.body;
        const convertDate = new Date(newPaymentDate);

        convertDate.setDate(convertDate.getDate() + 1);

        const convertBody = body
          .replace("TYPE", convertType)
          .replace("HOLDER", holder)
          .replace(
            "NEW_DATE",
            convertDate.toLocaleDateString("es-ES", dateOptions)
          );

        const title =
          typeAction === "Complete"
            ? COMPLETE_DEBT.title
            : typeAction === "Pay"
            ? PAY_DEBT.title
            : RESCHEDULE_DEBT.title;

        sendEventChatwood(userData.phone, title, convertBody);
      });
  };

  useEffect(() => {
    setIsFetchingData(true);

    setUrlRoute(paramsId + paramsDate);
    const hashId = getHashId(paramsId);
    const transactionDate = getDate(paramsDate);
    setDate(transactionDate?.toUpperCase());
    axios
      .post(
        "https://qentaz-notification-server.herokuapp.com/report-message-notification",
        {
          id: hashId,
          from: "Hoy",
        }
      )
      .then((x) => setMsg(x?.data?.data?.debtsMsg))
      .finally(() => setIsFetchingData(false));

    DashboardAPI.getUserFinancesData({ id: hashId, from: transactionDate })
      .then((x) =>
        setFinanceData({
          total_amount_debt: checkProperty(x?.total_amount_debt),
          total_amount_owe: checkProperty(x?.total_amount_owe),
          debts: checkProperty(x?.debts),
          week_period_debt: checkProperty(x?.week_period_debt),
        })
      )
      .finally(() => setIsFetchingData(false));

    UserInfoAPI.getInfo({ id: hashId })
      .then((x) =>
        setUserData({
          name: checkProperty(x?.contact?.attributes?.name),
          countryCurrency: getCurrencySymbol(
            checkProperty(x?.contact?.attributes?.countryCode)
          ),
          phone: checkProperty(x?.contact?.attributes?.phone),
        })
      )
      .finally(() => setIsFetchingUserData(false));
    setTimeout(() => {
      setLoader(true);
    }, 2000);
  }, [recharge]);

  return {
    financeData,
    userData,
    isFetchingUserData,
    isFetchingData,
    urlRoutes,
    date,
    setPayDebt,
    payDebt,
    changeDateFilter,
    deleteDebt,
    updateDebt,
    newDate,
    setNewDate,
    date,
    msg,
    loader,
  };
};
