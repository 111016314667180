import { StyleSheet, Text, Touchable, View } from "react-native";
import React, { useEffect, useState } from "react";
import Ionicons from "react-native-vector-icons/Ionicons";
import { globalClasses, globalStyles } from "../styles";
import { TouchableOpacity } from "react-native-gesture-handler";
import moment from "moment";
import momentTimeZone from "moment-timezone";
import { getCountry } from "countries-and-timezones";
import { CountryCode } from "../utils/countryCode";
import { buttonSelect } from "../analytics/src/open-report";
import phone from "phone";
import { GetConvertDateAPI } from "../api/src/get-convert-date";

const months = [
  "Enero",
  "Febrero",
  "Marzo",
  "Abril",
  "Mayo",
  "Junio",
  "Julio",
  "Agosto",
  "Septiembre",
  "Octubre",
  "Noviembre",
  "Diciembre",
];
const dateOptions = {
  year: "numeric",
  month: "long",
  day: "numeric",
};

const PeriodSlider = ({
  from,
  contactPhone,
  setCurrentDate,
  currentDate,
  label,
  paramsDate,
  weeks_days,
}) => {
  const [current, setCurrent] = useState(0);
  const [convertDate, setConvertDate] = useState("");
  const today = new Date(new Date()).toLocaleDateString("es-ES", dateOptions);

  useEffect(() => {
    if (
      from?.includes(today) ||
      (months[new Date().getMonth()] === from && from === "Mayo") ||
      from?.includes("semana")
    ) {
      setCurrent(0);
    }
  }, [from]);

  useEffect(() => {
    GetConvertDateAPI.getConvertDate({
      text: currentDate,
      phone: contactPhone,
    }).then((x) => {
      const { countryIso2 } = phone(contactPhone);
      const { timezones } = getCountry(countryIso2);
      const [timeZone] = timezones;

      const formatDate = new Date(momentTimeZone(x?.data).tz(timeZone));
      const today = new Date();
      const diffTime = today - formatDate;
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

      if (months.includes(currentDate)) {
        const currentMonth = new Date().getMonth();
        const incomeMonth = months.indexOf(currentDate);
        const diffMonth = incomeMonth - currentMonth;
        setCurrent(diffMonth);
      } else if (diffDays > 0) {
        const days = diffDays - 1;
        setCurrent(-days);
      }
    });
  }, []);

  useEffect(() => {
    if (contactPhone) {
      const phoneUser = phone(contactPhone || "");

      let time = filterByDate(
        from,
        phoneUser?.countryIso2 ||
          CountryCode[`${contactPhone?.substring(0, 4)}`],
        current,
        currentDate
        // formatDate
      );
      setCurrentDate(time?.firstDay);
    }
  }, [current, contactPhone]);

  const handleReduce = async () => {
    setCurrent(current - 1);
    await buttonSelect(contactPhone, {
      type: "Cambio de fecha",
      button: label,
    });
  };
  const handleAdd = async () => {
    setCurrent(current + 1);
    await buttonSelect(contactPhone, {
      type: "Cambio de fecha",
      button: label,
    });
  };

  const firstDay = new Date(weeks_days?.firstDay);
  firstDay.setDate(firstDay.getDate() + 1);

  const lastDay = new Date(weeks_days?.lastDay);

  lastDay.setDate(lastDay.getDate() + 1);

  return (
    <View style={styles.container}>
      <TouchableOpacity onPress={handleReduce}>
        <Ionicons
          name="arrow-back-circle"
          size={35}
          color={globalStyles.colors.primary}
        />
      </TouchableOpacity>
      <Text style={[globalClasses.cardTitlePeriod, { marginTop: 7 }]}>
        {from?.includes("semana") || from?.includes("week")
          ? `${firstDay?.toLocaleDateString(
              "es-ES",
              dateOptions
            )} - ${lastDay?.toLocaleDateString("es-ES", dateOptions)}`
          : currentDate}
      </Text>
      <TouchableOpacity onPress={handleAdd} disabled={current === 0}>
        <Ionicons
          name="arrow-forward-circle"
          size={35}
          color={
            current === 0
              ? globalStyles.colors.lightGrey
              : globalStyles.colors.primary
          }
        />
      </TouchableOpacity>
    </View>
  );
};

export default PeriodSlider;

const filterByDate = (
  filterDate,
  country,
  counter,
  currentDate,
  convertDate
) => {
  const { timezones } = getCountry(country);
  const [timeZone] = timezones;

  const months = [
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre",
  ];

  const dateOptions = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  };

  const date = new Date();

  const dateMonth = new Date();
  let finalDate = "";
  const weekKey = "week";

  dateMonth.setMonth(dateMonth.getMonth() + counter);

  date.setDate(date.getDate() + counter);

  const formatDate = momentTimeZone(date).tz(timeZone);
  finalDate =
    months.indexOf(filterDate) !== -1 ||
    filterDate?.toLowerCase()?.includes("mes")
      ? months[dateMonth.getMonth()]
      : filterDate?.toLowerCase()?.includes("semana") ||
        filterDate.includes("week")
      ? weekKey + Math.abs(counter)
      : new Date(formatDate).toLocaleDateString("es-ES", dateOptions);

  return { firstDay: finalDate };
};

const styles = StyleSheet.create({
  container: {
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: globalStyles.shape.sectionSpace,
  },
});
