import React, { useEffect, useState } from "react";
import { UserInfoAPI } from "../../api/src/user-info";
import { getHashId } from "../../helpers/src";
import { checkProperty } from "../../helpers/src";
import axios from "axios";
import _ from "lodash";
import { MultiAccountAPI } from "../../api/src/get-multiple-accounts";

export const useProfileData = () => {
  const paramsId =
    window.location.pathname !== "/"
      ? window.location.pathname
      : `/u/${localStorage.getItem("hashId")}`;

  const hashId = getHashId(paramsId);

  const [userData, setUserData] = useState({});
  const [location, setLocation] = useState([]);
  const [selectCountryState, setSelectCountryState] = useState("");
  const [selectCity, setSelectCity] = useState("");
  const [namesFilter, setNamesFilter] = useState([]);

  const [refreshUserData, setRefreshUserData] = useState(false);

  const updateNewUserInfo = (name, value) => {
    setUserData({ ...userData, [name]: value });
  };

  const saveNewInfo = () => {
    UserInfoAPI.putInfo({ id: hashId, info: userData });
  };
  useEffect(async () => {
    UserInfoAPI.getInfo({ id: hashId }).then((x) => {
      setUserData({
        name: checkProperty(x?.contact?.attributes?.name),
        lastName: checkProperty(x?.contact?.attributes?.lastName),
        nickname: checkProperty(x?.contact?.attributes?.nickname),
        userMembership: checkProperty(x?.contact?.attributes?.userMembership),
        purposeUse: checkProperty(x?.contact?.attributes?.purposeUse),
        countryCode: checkProperty(x?.contact?.attributes?.countryCode),
        city: checkProperty(x?.contact?.attributes?.city),
        countryState: checkProperty(x?.contact?.attributes?.countryState),
        paidEndPlanDate: checkProperty(x?.contact?.attributes?.paidEndPlanDate),
        phone: checkProperty(x?.contact?.attributes?.phone),
        userType: checkProperty(x?.contact?.attributes?.userType),
        beta: checkProperty(x?.contact?.attributes?.beta),
      });
    });

    MultiAccountAPI.getMultiAccountsUser({ id: hashId }).then((x) => {
      const {
        data: { users },
      } = x;

      setNamesFilter(users);
    });

    const url = await axios(
      "https://www.datos.gov.co/resource/95qx-tzs7.json?$$app_token=jbTIll24dvIUspYgcPs7lYRhZ"
    );
    const info = url?.data;
    setLocation(info);
  }, []);

  useEffect(() => {
    if (refreshUserData) {
      MultiAccountAPI.getMultiAccountsUser({ id: hashId }).then((x) => {
        const {
          data: { users },
        } = x;

        setNamesFilter(users);
      });

      setRefreshUserData(false);
    }
  }, [refreshUserData]);

  return {
    userData,
    location,
    selectCity,
    selectCountryState,
    saveNewInfo,
    namesFilter,
    updateNewUserInfo,
    setRefreshUserData,
    setSelectCountryState,
    setSelectCity,
  };
};
