import { StyleSheet, Text, View } from "react-native";
import React from "react";
import { globalClasses, globalStyles } from "../styles";
import { Divider } from "react-native-paper";

const DebtsCard = ({
  debtsCxPvalue,
  debtsCxCvalue,
  countryCurrency,
  debtsQuantity,
  activeDebts,
}) => {
  const currencyFormat = Intl.NumberFormat("es-CO");
  const progress =
    !Number(debtsCxCvalue) && !Number(debtsCxPvalue)
      ? 50
      : (
          (Number(debtsCxCvalue) /
            (Number(debtsCxCvalue) + Number(debtsCxPvalue))) *
          100
        ).toFixed(0);


  return (
    <View style={styles.cardContainer}>
      <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
        <View style={{ textAlign: "left" }}>
          <Text
            style={[
              globalClasses.smallTitle,
              { color: globalStyles.colors.primary },
            ]}
          >
            Lo que te deben
          </Text>
          <Text style={globalClasses.cardTitle}>
            {countryCurrency}
            {currencyFormat.format(debtsCxCvalue)}
          </Text>
        </View>
        <View style={{ textAlign: "right" }}>
          <Text
            style={[
              globalClasses.smallTitle,
              { color: globalStyles.colors.red },
            ]}
          >
            Lo que debes
          </Text>
          <Text style={globalClasses.cardTitle}>
            {countryCurrency} {currencyFormat.format(debtsCxPvalue)}
          </Text>
        </View>
      </View>
      <View style={[styles.bar, styles.redBar]}>
        <View
          style={[
            {
              width: `${progress}%`,
              backgroundColor: globalStyles.colors.primary,
            },
            styles.bar,
          ]}
        ></View>
      </View>
      <View style={styles.captionContainer}>
        <View style={{ textAlign: "center" }}>
          <Text style={globalClasses.smallTitle}>{debtsQuantity}</Text>
          <Text style={globalClasses.caption}>Deudas</Text>
        </View>
        <Divider style={styles.divider} />
        <View style={{ textAlign: "center" }}>
          <Text
            style={[
              globalClasses.smallTitle,
              { color: globalStyles.colors.primary },
            ]}
          >
            {activeDebts?.filter((x) => x?.attributes?.currentAmount)?.length}
          </Text>
          <Text style={globalClasses.caption}>Abonadas</Text>
        </View>
        <Divider style={styles.divider} />

        <View style={{ textAlign: "center" }}>
          <Text
            style={[
              globalClasses.smallTitle,
              { color: globalStyles.colors.red },
            ]}
          >
            {activeDebts?.filter((x) => !x?.attributes?.currentAmount)?.length}
          </Text>
          <Text style={globalClasses.caption}>Sin abono</Text>
        </View>
      </View>
    </View>
  );
};

export default DebtsCard;

const styles = StyleSheet.create({
  cardContainer: {
    backgroundColor: globalStyles.colors.white,
    width: "100%",
    marginTop: globalStyles.shape.betweenSpace,
    padding: globalStyles.shape.internSpaceX,
    borderRadius: 10,
  },
  captionContainer: {
    marginTop: globalStyles.shape.betweenSpace,
    flexDirection: "row",
    justifyContent: "space-between",
    padding: globalStyles.shape.internSpaceY,
  },
  bar: { borderRadius: 7, height: 12 },
  redBar: {
    width: "100%",
    marginTop: globalStyles.shape.betweenSpace,
    backgroundColor: globalStyles.colors.red,
  },
  divider: { width: 1, height: "100%" },
});
