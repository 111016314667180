export const faqs = [
  {
    qestion: "¿Puedo cancelar mi plan?",
    answer:
      "Sí, podrás cancelar tu plan en cualquier momento. Ten presente que no podremos realizar ningún tipo de reembolso",
  },
  {
    qestion: "Al cambiar de plan, ¿mi información se pierde?",
    answer:
      "No, tu información se encuentra siempre segura y de uso privado. No se comparte ni la ve nadie 🔐",
  },
  {
    qestion: "¿Por qué elegir el plan pro?",
    answer:
      "El pran pro es el plan más completo para organizar, entender y potenciar tus finanzas porque reúne todas las funcionalidades del plan básico y el plan plus",
  },
];

export const plusInfo = [
  {
    title: "Todo lo del plan básico",
    description:
      "Registra tus ingresos, controla tus gastos, ten presente tus deudas, visualiza la información en tu reporte, recibe contenido financiero y aprende acompañado 🤝",
  },
  {
    title: "Analiza tu reporte",
    description:
      "Accede al análisis de tu información, aprende de tus finanzas y toma mejores decisiones que hagan crecer tus finanzas personales o las de tu negocio 🚀",
  },
  {
    title: "Categoriza tus finanzas",
    description:
      "Separa tus cuentas personales de las de tu negocio y ya no te desórdenes más 😉",
  },
  {
    title: "Recordatorios de deudas",
    description:
      "Recuerda la fecha en la que tienes que cobrar o pagar una deuda 📅",
  },
  {
    title: "Asesorías financieras",
    description:
      "Obtén un descuento del 30% para mejorar en la gestión de tus finanzas 🤓",
  },
];

export const proInfo = [
  {
    title: "Todo lo del plan básico + plus",
    description:
      "Registra tus ingresos, controla tus gastos, recuerda tus deudas pendientes, categoriza y analiza la información en tu reporte, recibe contenido financiero y aprende acompañado 🤝",
  },
  {
    title: "¡Dile adiós al teclado!",
    description:
      "Registra tus movimientos a través de notas de voz ¡Ya no pierdas tiempo! 😎",
  },
  {
    title: "Descarga tu reporte",
    description: "Guarda y lleva tu información donde quiera que vayas ✅ ",
  },
  {
    title: "Calcula tu salario",
    description: "Entiende cuánto ganas o cuánto deberías ganar 💸 ",
  },
  {
    title: "Analiza tus deudas",
    description:
      "Participa de una asesoría financiera individual al mes y aumenta tu gestión financiera 🤓",
  },
];

export const proPrices = [
  {
    periodTitle: "Mensual",
    periodCaption: "1 mes",
    value: "$14.900",
    valueDescription: "Por mes",
  },
  {
    periodTitle: "Trimestral",
    periodCaption: "3 meses",
    value: "$38.700",
    valueDescription: "$12.900/mes",
  },
  {
    periodTitle: "Semestral",
    periodCaption: "6 meses",
    value: "$59.400",
    valueDescription: "$9.900/mes",
  },
];

export const menuOptions = [
  { icon: "person-circle-outline", title: "Mi Perfil", screen: "Perfil" },
  { icon: "cash-outline", title: "Cambiar de plan", screen: "Planes" },
  { icon: "rocket-outline", title: "Refiere Qentaz", screen: "Referidos" },
  { icon: "book-outline", title: "Manual", screen: "Manual" },
  {
    icon: "happy-outline",
    title: "Comunidad Qentaz",
    link: "https://www.facebook.com/groups/qlub.qentaz/",
    type: "COMUNIDAD",
  },
  {
    icon: "help-circle-outline",
    title: "Necesito ayuda",
    link: "https://m.qentaz.com/Ayuda",
    type: "AYUDA",
  },
];

export const loadingMessage = [
  "Cuentas claras, amistades largas.",
  "Cuentas claras, mente tranquila.",
  "Cuentas claras, vida sana.",
  "Cuentas claras, negocios prósperos.",
  "Cuentas claras, decisiones sabias.",
  "Cuentas claras, metas alcanzadas.",
  "Cuentas claras, objetivos cumplidos.",
  "Cuentas claras, éxito asegurado.",
];
