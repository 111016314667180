import { postData } from "../api-call";
import { BASE_URL, SEND_CONFIRMATION } from "../../config";

const postConfirmationMultiAccount = ({
  ownerPhone,
  name,
  role,
  memberPhone,
}) => {
  const data = { ownerPhone, name, role, memberPhone };

  const requestUrl = `${BASE_URL}${SEND_CONFIRMATION}`;

  return postData(requestUrl, data);
};

export const ApprovalTableAPI = {
  postConfirmationMultiAccount,
};
