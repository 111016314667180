import React from "react";
import { createStackNavigator } from "@react-navigation/stack";
import { NavigationContainer } from "@react-navigation/native";
import Referrals from "../Screens/Referrals/Referrals";
import Options from "../Screens/Options/Options";
import PlansMenu from "./PlansMenu";
import Profile from "../Screens/Profile/Profile";
import Learning from "../Screens/Learning/Learning";
/*
Componente de Navegador para menú
que permite la navegación entre pantallas
Trabaja los componentes
- Options (Pantalla de menú)
- Profile (Pantalla de Perfil)
- PlansMenu (Navegador para Pantallas Planes y Checkout)
*/
const MenuNavigator = () => {
  const Stack = createStackNavigator();

  return (
    <Stack.Navigator
      screenOptions={{
        headerShown: false,
        headerLeft: () => null,
        headerBackTitleVisible: false,
        headerTransparent: true,
      }}
    >
      <Stack.Screen
        name="Menu"
        component={Options}
        options={{ headerShown: false }}
      />
      <Stack.Screen name="Perfil" component={Profile} />
      <Stack.Screen name="Planes" component={PlansMenu} />
      <Stack.Screen name="Referidos" component={Referrals} />
      <Stack.Screen name="Manual" component={Learning} />
    </Stack.Navigator>
  );
};

export default MenuNavigator;
