import { createSlice } from "@reduxjs/toolkit";

export const modalShowSlice = createSlice({
  name: "modalShow",
  initialState: [],
  reducers: {
    addModalShow: (state, action) => {
      state.push(action.payload);
    },

    clearModalShow: (state, _) => {
      state.pop();
    },
  },
});

export const { addModalShow, clearModalShow } = modalShowSlice.actions;

export default modalShowSlice.reducer;
