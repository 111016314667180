//PARSE QUERY
const PERIOD_TIME_QUERY = "?from=";
const HASH_ID_QUERY = "/u/";
//COUNTRY CODE CURRENCY
const KEY_VALUE_CURRENCY = "symbol";
const DEFAULT_CURRENCY_SYMBOL = "$";

export {
  PERIOD_TIME_QUERY,
  HASH_ID_QUERY,
  KEY_VALUE_CURRENCY,
  DEFAULT_CURRENCY_SYMBOL,
};
