import {
  AMPLITUDE_BASE_URL,
  SLACK_URL_CHECKOUT,
  SLACK_URL_ERROR,
  SLACK_URL_PLANS,
} from "../../config";
import { analyticTypeEvents } from "./model";
import { getCountryCode } from "../../../helpers/src";
import { postData } from "../../../api/src/api-call";
import { BASE_URL } from "../../../api/config";

export const openReport = async (phone) => {
  const parsePhone = phone.replace("+", "");

  const data = {
    api_key: "4499cd14304a492605e83d5810a0272a",
    events: [
      {
        user_id: parsePhone,
        event_type: analyticTypeEvents.openReport,
        platform: "Botpress",
        country: getCountryCode(phone),
      },
    ],
  };
  await postData(AMPLITUDE_BASE_URL, data);
};

export const closeReport = async (phone) => {
  const parsePhone = phone.replace("+", "");

  const data = {
    api_key: "4499cd14304a492605e83d5810a0272a",
    events: [
      {
        user_id: parsePhone,
        event_type: analyticTypeEvents.closeReport,
        platform: "Botpress",
        country: getCountryCode(phone),
      },
    ],
  };
  await postData(AMPLITUDE_BASE_URL, data);
};

export const visitPageMain = async (phone, properties) => {
  const parsePhone = phone.replace("+", "");
  const data = {
    api_key: "4499cd14304a492605e83d5810a0272a",
    events: [
      {
        user_id: parsePhone,
        event_type: analyticTypeEvents.visitPage.event,
        event_properties: {
          page: analyticTypeEvents.visitPage.options.main,
          ...properties,
        },
        platform: "Botpress",
        country: getCountryCode(phone),
      },
    ],
  };
  await postData(AMPLITUDE_BASE_URL, data);
};

export const visitPageSummary = async (phone, properties) => {
  const parsePhone = phone.replace("+", "");

  const data = {
    api_key: "4499cd14304a492605e83d5810a0272a",
    events: [
      {
        user_id: parsePhone,
        event_type: analyticTypeEvents.visitPage.event,
        event_properties: {
          page: analyticTypeEvents.visitPage.options.summary,
          ...properties,
        },
        platform: "Botpress",
        country: getCountryCode(phone),
      },
    ],
  };
  await postData(AMPLITUDE_BASE_URL, data);
};

export const visitPagePlans = async (phone, properties) => {
  const parsePhone = phone.replace("+", "");

  const data = {
    api_key: "4499cd14304a492605e83d5810a0272a",
    events: [
      {
        user_id: parsePhone,
        event_type: analyticTypeEvents.visitPage.event,
        event_properties: {
          page: analyticTypeEvents.visitPage.options.plans,
          ...properties,
        },
        platform: "Botpress",
        country: getCountryCode(phone),
      },
    ],
  };
  await postData(AMPLITUDE_BASE_URL, data);
};

export const visitPageReferred = async (phone, properties) => {
  const parsePhone = phone.replace("+", "");

  const data = {
    api_key: "4499cd14304a492605e83d5810a0272a",
    events: [
      {
        user_id: parsePhone,
        event_type: analyticTypeEvents.visitPage.event,
        event_properties: {
          page: analyticTypeEvents.visitPage.options.reffered,
          ...properties,
        },
        platform: "Botpress",
        country: getCountryCode(phone),
      },
    ],
  };
  await postData(AMPLITUDE_BASE_URL, data);
};
export const visitPageLearn = async (phone, properties) => {
  const parsePhone = phone.replace("+", "");

  const data = {
    api_key: "4499cd14304a492605e83d5810a0272a",
    events: [
      {
        user_id: parsePhone,
        event_type: analyticTypeEvents.visitPage.event,
        event_properties: {
          page: analyticTypeEvents.visitPage.options.learn,
          ...properties,
        },
        platform: "Botpress",
        country: getCountryCode(phone),
      },
    ],
  };
  await postData(AMPLITUDE_BASE_URL, data);
};

export const visitPageMovements = async (phone, properties) => {
  const parsePhone = phone.replace("+", "");

  const data = {
    api_key: "4499cd14304a492605e83d5810a0272a",
    events: [
      {
        user_id: parsePhone,
        event_type: analyticTypeEvents.visitPage.event,
        event_properties: {
          page: analyticTypeEvents.visitPage.options.movements,
          ...properties,
        },

        platform: "Botpress",
        country: getCountryCode(phone),
      },
    ],
  };

  await postData(AMPLITUDE_BASE_URL, data);
};

export const visitPageDebts = async (phone, properties) => {
  const parsePhone = phone.replace("+", "");

  const data = {
    api_key: "4499cd14304a492605e83d5810a0272a",
    events: [
      {
        user_id: parsePhone,
        event_type: analyticTypeEvents.visitPage.event,
        event_properties: {
          page: analyticTypeEvents.visitPage.options.debts,
          ...properties,
        },
        platform: "Botpress",
        country: getCountryCode(phone),
      },
    ],
  };
  await postData(AMPLITUDE_BASE_URL, data);
};

export const visitPageProfile = async (phone, properties) => {
  const parsePhone = phone.replace("+", "");

  const data = {
    api_key: "4499cd14304a492605e83d5810a0272a",
    events: [
      {
        user_id: parsePhone,
        event_type: analyticTypeEvents.visitPage.event,
        event_properties: {
          page: analyticTypeEvents.visitPage.options.profile,
          ...properties,
        },
        platform: "Botpress",
        country: getCountryCode(phone),
      },
    ],
  };
  await postData(AMPLITUDE_BASE_URL, data);
};

export const visitPageChangePlan = async (phone, properties) => {
  const parsePhone = phone.replace("+", "");

  const data = {
    api_key: "4499cd14304a492605e83d5810a0272a",
    events: [
      {
        user_id: parsePhone,
        event_type: analyticTypeEvents.visitPage.event,
        event_properties: {
          page: analyticTypeEvents.visitPage.options.changePlan,
          ...properties,
        },
        platform: "Botpress",
        country: getCountryCode(phone),
      },
    ],
  };
  await postData(AMPLITUDE_BASE_URL, data);
};

export const visitPageCheckout = async (phone, properties) => {
  const parsePhone = phone.replace("+", "");

  const data = {
    api_key: "4499cd14304a492605e83d5810a0272a",
    events: [
      {
        user_id: parsePhone,
        event_type: analyticTypeEvents.visitPage.event,
        event_properties: {
          page: analyticTypeEvents.visitPage.options.checkout,
          ...properties,
        },
        platform: "Botpress",
        country: getCountryCode(phone),
      },
    ],
  };
  await postData(AMPLITUDE_BASE_URL, data);
};
export const visitPageMenu = async (phone) => {
  const parsePhone = phone.replace("+", "");

  const data = {
    api_key: "4499cd14304a492605e83d5810a0272a",
    events: [
      {
        user_id: parsePhone,
        event_type: analyticTypeEvents.visitPage.event,
        event_properties: {
          page: analyticTypeEvents.visitPage.options.menu,
        },

        platform: "Botpress",
        country: getCountryCode(phone),
      },
    ],
  };
  await postData(AMPLITUDE_BASE_URL, data);
};

export const openTutorial = async (phone, page) => {
  const parsePhone = phone.replace("+", "");

  const data = {
    api_key: "4499cd14304a492605e83d5810a0272a",
    events: [
      {
        user_id: parsePhone,
        event_type: analyticTypeEvents.openTutorial,
        event_properties: {
          page: page,
        },
        platform: "Botpress",
        country: getCountryCode(phone),
      },
    ],
  };
  await postData(AMPLITUDE_BASE_URL, data);
};

export const buttonSelect = async (phone, properties) => {
  const parsePhone = phone.replace("+", "");
  const data = {
    api_key: "4499cd14304a492605e83d5810a0272a",
    events: [
      {
        user_id: parsePhone,
        event_type: analyticTypeEvents.selectButton.event,
        event_properties: {
          ...properties,
        },
        platform: "Botpress",
        country: getCountryCode(phone),
      },
    ],
  };
  await postData(AMPLITUDE_BASE_URL, data);
};

export const slackNotificationPaidPlan = async (phone) => {
  const parsePhone = phone.replace("+", "");
  const requestUrl = `${BASE_URL}${SLACK_URL_PLANS}`;
  const data = {
    phone: parsePhone,
  };

  await postData(requestUrl, data);
};

export const slackNotificationCheckout = async (phone, price) => {
  const parsePhone = phone.replace("+", "");
  const requestUrl = `${BASE_URL}${SLACK_URL_CHECKOUT}`;
  const data = {
    phone: parsePhone,
    price,
  };

  await postData(requestUrl, data);
};

export const visitCheckout = async (phone, properties) => {
  const parsePhone = phone.replace("+", "");
  const data = {
    api_key: "4499cd14304a492605e83d5810a0272a",
    events: [
      {
        user_id: parsePhone,
        event_type: analyticTypeEvents.checkout.event,
        event_properties: {
          ...properties,
        },
        platform: "Botpress",
        country: getCountryCode(phone),
      },
    ],
  };
  await postData(AMPLITUDE_BASE_URL, data);
};

export const slackNotificationProblem = async ({ phone, error }) => {
  const parsePhone = phone.replace("+", "");
  const requestUrl = `${BASE_URL}${SLACK_URL_ERROR}`;
  const data = {
    phone: parsePhone,
    description: error.description,
    date: error.date,
    screen: error.screen,
    errorType: error.errorType,
  };
  await postData(requestUrl, data);
};
