import React from "react";
import Icon from "react-native-vector-icons/Ionicons";
import { Text, Divider, Button } from "react-native-paper";
import { StyleSheet, View, Image, TouchableOpacity } from "react-native";
import MaterialCommunityIcons from "react-native-vector-icons/MaterialCommunityIcons";

import {
  visitPageChangePlan,
  visitPageDebts,
  visitPageMovements,
  visitPagePlans,
  visitPageSummary,
} from "../../analytics/src/open-report";
import Screen from "../../Templates/Screen";
import ProBadge from "../../Components/ProBadge";
import DebtsCard from "../../Components/DebtsCard";
import BalanceCard from "../../Components/BalanceCard";
import { globalClasses, globalStyles } from "../../styles";
import InterpretationCard from "../../Components/InterpretationCard";

const { useMainData } = require("./useMain");
const months = [
  "Enero",
  "Febrero",
  "Marzo",
  "Abril",
  "Mayo",
  "Junio",
  "Julio",
  "Agosto",
  "Septiembre",
  "Octubre",
  "Noviembre",
  "Diciembre",
  "enero",
  "febrero",
  "marzo",
  "abril",
  "mayo",
  "junio",
  "julio",
  "agosto",
  "septiembre",
  "octubre",
  "noviembre",
  "diciembre",
];
const MainScreen = ({ route, navigation }) => {
  const {
    financeData: {
      total_income,
      total_outcome,
      total_balance,
      total_amount_owe,
      total_amount_debt,
      debts,
    },
    date,
    setDate,
    userData: { name, phone, countryCurrency, userType },

    msg,
    convertDate,
    loader,
  } = useMainData({ navigation: navigation });

  const activeDebts = debts?.filter((x) => x?.attributes?.active) || [];
  const [problem, setProblem] = useState(false);

  return (
    <Screen loader={loader} action={() => setProblem(true)}>
      <Text style={globalClasses.breakTitle}>¡Hola {name}! 👋</Text>

      <View
        style={[
          { marginTop: globalStyles.shape.betweenSpace },
          globalClasses.spacedRow,
        ]}
      >
        <Text style={styles.section__title}>Tu resumen</Text>
        <TouchableOpacity
          onPress={async () => {
            navigation.navigate("Movimientos", {
              nav: true,
            });

            await visitPageMovements(phone, {
              previous_screen: "Inicio",
              type: "button",
            });
          }}
        >
          <View
            style={{
              flexDirection: "row",
              padding: 7,
              alignItems: "center",
              backgroundColor: globalStyles.colors.primary,
              borderRadius: 10,
            }}
          >
            <Text
              style={[
                globalClasses.smallTitle,
                { color: globalStyles.colors.white },
              ]}
            >
              Ver más
            </Text>
            <Icon
              name="arrow-forward"
              size={15}
              color={globalStyles.colors.white}
              style={{ marginLeft: 5 }}
            />
          </View>
        </TouchableOpacity>
      </View>
      {months.includes(date) ? (
        <Text style={globalClasses.caption}>{date}</Text>
      ) : (
        <Text style={globalClasses.caption}>{convertDate || date}</Text>
      )}
      <BalanceCard
        countryCurrency={countryCurrency}
        totalBalance={total_balance}
        incomeTotal={total_income}
        outcomeTotal={total_outcome}
      />
      <View
        style={[
          globalClasses.spacedRow,
          {
            marginTop: globalStyles.shape.sectionSpace,
            alignContent: "center",
          },
        ]}
      >
        <View
          style={{
            flexDirection: "row",
          }}
        >
          <Text style={styles.section__title}>Tu Análisis</Text>
          <ProBadge />
        </View>
        <TouchableOpacity
          onPress={async () => {
            navigation.navigate("Análisis", {
              nav: true,
            });

            await visitPageSummary(phone, {
              previous_screen: "Inicio",
              type: "button",
            });
          }}
        >
          <View
            style={{
              flexDirection: "row",
              padding: 7,
              alignItems: "center",
              backgroundColor: globalStyles.colors.primary,
              borderRadius: 10,
            }}
          >
            <Text
              style={[
                globalClasses.smallTitle,
                { color: globalStyles.colors.white },
              ]}
            >
              Ver más
            </Text>
            <Icon
              name="arrow-forward"
              size={15}
              color={globalStyles.colors.white}
              style={{ marginLeft: 5 }}
            />
          </View>
        </TouchableOpacity>
      </View>
      <View>
        {!Number(total_income) && !Number(total_outcome) ? (
          <Text>
            <Text style={globalClasses.smallTitle}>
              ¡Intenta hacer un movimiento! 😊
            </Text>
          </Text>
        ) : (
          <InterpretationCard
            description={
              Number(total_balance) === 0
                ? "¡Lo siento! No tengo información suficiente para analizar 😔"
                : msg
                    ?.replace(
                      "gastosDiarios",
                      `${countryCurrency}${total_outcome}`
                    )
                    ?.replace("gastosMes", `${countryCurrency}${total_outcome}`)
                    ?.replace(
                      "gastosSemanal",
                      `${countryCurrency}${total_outcome}`
                    )
            }
          />
        )}
      </View>

      {userType === "basic" ? (
        <View style={styles.messageCard}>
          <View style={{ marginRight: 3 }}>
            <MaterialCommunityIcons
              name="creation"
              color={globalStyles.colors.primary}
              size={25}
            />
          </View>
          <View style={{ width: "90%" }}>
            <Text style={globalClasses.smallTitle}>
              ¡Sácale el máximo provecho a Qentaz!
            </Text>
            <Divider
              style={{
                width: "95%",
                alignSelf: "center",
                marginTop: globalStyles.shape.betweenSpace,
              }}
            />
            <View style={{ flexDirection: "row", marginTop: 5 }}>
              <View style={{ padding: 5 }}>
                <MaterialCommunityIcons
                  name="checkbox-blank-circle"
                  color="#000"
                  size={7}
                />
              </View>
              <Text>
                Descubre tus mejores días de venta, cómo estás en comparación
                con negocios como tú, y muchos más análisis de tus finanzas
              </Text>
            </View>
            <View style={{ flexDirection: "row", marginTop: 5 }}>
              <View style={{ padding: 5 }}>
                <MaterialCommunityIcons
                  name="checkbox-blank-circle"
                  color="#000"
                  size={7}
                />
              </View>
              <Text>Recibe recordatorios de tus deudas</Text>
            </View>
            <View style={{ flexDirection: "row", marginTop: 5 }}>
              <View style={{ padding: 5 }}>
                <MaterialCommunityIcons
                  name="checkbox-blank-circle"
                  color="#000"
                  size={7}
                />
              </View>
              <Text>Categoriza tus ingresos y gastos </Text>
            </View>
            <Text
              style={{
                color: globalStyles.colors.primary,
                fontWeight: "700",
                marginTop: globalStyles.shape.sectionSpace,
              }}
            >
              <Button
                style={styles.textLink}
                textColor={globalStyles.colors.white}
                onPress={() =>
                  navigation.navigate("Planes", {
                    previous_screen: "Inicio",
                    type: "button",
                  })
                }
              >
                Conocer más del plan {">"}
              </Button>
            </Text>
          </View>
        </View>
      ) : (
        ""
      )}
      <TouchableOpacity
        onPress={async () => {
          navigation.navigate("Más", {
            screen: "Planes",
            nav: true,
          });

          await visitPageChangePlan(phone, {
            previous_screen: "Inicio",
            type: "banner",
          });
        }}
      >
        <View
          style={{
            marginTop: globalStyles.shape.sectionSpace,
            borderRadius: 10,

            overflow: "hidden",
          }}
        >
          <Image
            source={{
              uri: `https://qentaz.s3.amazonaws.com/public/Reporte/BANNER_Planes.jpg`,
            }}
            resizeMode="contain"
            style={{
              width: "100%",
              height: 200,
              borderRadius: 10,
            }}
          />
        </View>
      </TouchableOpacity>
      <View
        style={[
          { marginTop: globalStyles.shape.sectionSpace },
          globalClasses.spacedRow,
        ]}
      >
        <Text style={styles.section__title}>Resumen deudas</Text>
        <TouchableOpacity
          onPress={async () => {
            navigation.navigate("Deudas", {
              nav: true,
            });

            await visitPageDebts(phone, {
              previous_screen: "Inicio",
              type: "button",
            });
          }}
        >
          <View
            style={{
              flexDirection: "row",
              padding: 7,
              alignItems: "center",
              backgroundColor: globalStyles.colors.primary,
              borderRadius: 10,
            }}
          >
            <Text
              style={[
                globalClasses.smallTitle,
                { color: globalStyles.colors.white },
              ]}
            >
              Ver más
            </Text>
            <Icon
              name="arrow-forward"
              size={15}
              color={globalStyles.colors.white}
              style={{ marginLeft: 5 }}
            />
          </View>
        </TouchableOpacity>
      </View>
      <Text>Recuerda revisar tus fechas de cobro y pago</Text>
      <DebtsCard
        countryCurrency={countryCurrency}
        debtsCxCvalue={total_amount_owe}
        debtsCxPvalue={total_amount_debt}
        debtsQuantity={activeDebts?.length}
        activeDebts={activeDebts}
      />
    </Screen>
  );
};

export default MainScreen;

const styles = StyleSheet.create({
  messageCard: {
    width: "100",
    paddingHorizontal: globalStyles.shape.internSpaceX,
    paddingVertical: globalStyles.shape.internSpaceY,
    backgroundColor: globalStyles.colors.lightPositive,
    flexDirection: "row ",
    marginTop: globalStyles.shape.sectionSpace,
    borderRadius: 10,
  },
  section__title: {
    fontSize: 18,
    fontWeight: "700",
    alignSelf: "center",
  },

  textLink: {
    fontWeight: "700",
    alignSelf: "center",
    backgroundColor: globalStyles.colors.primary,
  },
});
