import { postData } from "../api-call";
import { REPORT_WEB_HOOK } from "../../config";

const postReportWebHook = ({ phone, period, txs }: UserFinancesDataProps) => {
  const data = { phone, period, txs };

  const requestUrl = REPORT_WEB_HOOK;

  return postData(requestUrl, data);
};

export const ReportWebHookAPI = {
  postReportWebHook,
};

interface UserFinancesDataProps {
  phone: string;
  period: string;
  txs: Array<any>;
}
