import { configureStore } from "@reduxjs/toolkit";
import modalShowReducer from "./slices/modalShow";
import reportUrlReducer from "./slices/reportUrl/";
import userDataSliceReducer from "./slices/userData";
import dateReducer from "./slices/date/";
import tabNameReducer from "./slices/tabName";
import modalShowTutorialReducer from "./slices/tutorialModal";
import periodTimeReducer from "./slices/periodDate";

export default configureStore({
  reducer: {
    reportUrl: reportUrlReducer,
    modalShow: modalShowReducer,
    userData: userDataSliceReducer,
    date: dateReducer,
    modalShowTutorial: modalShowTutorialReducer,
    tabName: tabNameReducer,
    periodTime: periodTimeReducer,
  },
});
