import { BASE_URL, GET_CONVERT_DATE } from "../../config";
import { postData } from "../api-call";

const getConvertDate = async ({ text, phone }) => {
  const data = {
    text,
    phone,
  };

  const requestUrl = `${BASE_URL}${GET_CONVERT_DATE}`;

  return postData(requestUrl, data);
};

export const GetConvertDateAPI = {
  getConvertDate,
};
