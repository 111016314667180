import { StyleSheet, Text, View } from "react-native";
import React, { useState } from "react";
import { TouchableOpacity } from "react-native-gesture-handler";
import { globalStyles } from "../styles";
import Ionicon from "react-native-vector-icons/Ionicons";

const ProblemButton = ({ action }) => {
  return (
    <TouchableOpacity style={{ marginTop: 20 }} onPress={action}>
      <View
        style={{
          flexDirection: "row",
          alignSelf: "center",
        }}
      >
        <Ionicon
          size={25}
          color={globalStyles.colors.darkGrey}
          name="help-circle-outline"
        />
        <Text
          style={{
            color: globalStyles.colors.darkGrey,
            fontSize: 15,
            marginLeft: 10,
            marginTop: 4,
          }}
        >
          Reportar un problema
        </Text>
      </View>
    </TouchableOpacity>
  );
};

export default ProblemButton;

const styles = StyleSheet.create({});
