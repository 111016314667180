import { createSlice } from "@reduxjs/toolkit";

export const periodTimeSlice = createSlice({
  name: "periodTime",
  initialState: {},
  reducers: {
    addPeriodTime: (state, action) => {
      state.periodTime = action.payload;
    },
  },
});

export const { addPeriodTime } = periodTimeSlice.actions;

export default periodTimeSlice.reducer;
