import {
  BASE_URL,
  GET_USERS_MULTIPLE_ACCOUNTS,
  UPDATE_MULTIPLE_ACCOUNTS_USER,
} from "../../config";
import { getData, postData } from "../api-call";

const getMultiAccountsUser = ({ id }: { id: string }) => {
  const requestUrl = `${BASE_URL}${GET_USERS_MULTIPLE_ACCOUNTS}/${id}`;

  return getData(requestUrl);
};

const updateMultiAccountUser = async ({ memberPhone, name, rol }) => {
  const data = { memberPhone, name, rol };

  const requestUrl = `${BASE_URL}${UPDATE_MULTIPLE_ACCOUNTS_USER}`;

  return postData(requestUrl, data);
};
export const MultiAccountAPI = {
  getMultiAccountsUser,
  updateMultiAccountUser,
};
