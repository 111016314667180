import { postData } from "../api-call";
import { BASE_URL, REPORT_QUERY } from "../../config";

const getUserFinancesData = ({
  id,
  from,
  type,
  members,
}: UserFinancesDataProps) => {
  const data = { id, from, type, members };

  const requestUrl = `${BASE_URL}${REPORT_QUERY}`;

  return postData(requestUrl, data);
};

export const DashboardAPI = {
  getUserFinancesData,
};

interface UserFinancesDataProps {
  id: string;
  from: string;
  type: string;
  members: string[];
}
