import { Modal, StyleSheet, View } from "react-native";
import React, { useEffect, useState } from "react";
import { IconButton } from "react-native-paper";

const ModalTemplate = ({ visible, children }) => {
  const [showModal, setShowModal] = useState(visible);
  useEffect(() => {
    toggleModal();
  }, [visible]);
  const toggleModal = () => {
    visible ? setShowModal(true) : setShowModal(false);
  };
  return (
    <Modal transparent visible={showModal} animationType="slide">
      <View style={styles.modalBackground}>
        <View style={styles.modalContainer}>{children}</View>
      </View>
    </Modal>
  );
};

export default ModalTemplate;

const styles = StyleSheet.create({
  modalBackground: {
    alignItems: "center",
    backgroundColor: "rgba(0,0,0,0.6)",
    flex: 1,
    justifyContent: "center",
    width: "100%",
    minWidth: 320,
    maxWidth: 500,
    alignSelf: "center",
  },
  modalContainer: {
    width: "85%",
    borderRadius: 10,
    paddingHorizontal: 15,
    paddingVertical: 10,
    backgroundColor: "#f6f8f7",
  },
});
