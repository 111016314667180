import _ from "lodash";
import { Portal } from "@gorhom/portal";
import React, { useState } from "react";
import { Text, View, Button, Image, TouchableOpacity } from "react-native";
import { Searchbar, Provider } from "react-native-paper";
import { ScrollView } from "react-native-gesture-handler";

import PlanPaid from "../PaidPlans/PaidPlans";
import { useTransaction } from "./useTransaction";
import DateButtons from "../../Components/DateButtons";
import BalanceCard from "../../Components/BalanceCard";
import ImageBanner from "../../Components/ImageBanner";
import PeriodSlider from "../../Components/PeriodSlider";
import { globalClasses, globalStyles } from "../../styles";
import DeleteSnackbar from "../../Components/DeleteSnackbar";
import TopTransactions from "../../Components/TopTransactions";
import TransactionsList from "../../Components/TransactionsList";
import LoadingComponent from "../../Components/LoadingComponent";
import InterpretationCard from "../../Components/InterpretationCard";
import { visitPageSummary } from "../../analytics/src/open-report";

const TransactionsPersonal = ({ navigation }) => {
  const {
    financeData: {
      transactions,
      total_balance,
      income_transactions,
      outcome_transactions,
      total_income,
      total_outcome,
      weeks_days,
    },
    userData: { countryCurrency, userType, phone, purposeUse, userMembership },
    onChangeSearch,
    searchQuery,
    deleteTransaction,
    updateTransaction,
    date,
    setDate,
    paramsDate,
    newTxData,
    updateNewTxInfo,
    msg,
    loader,
    label,
    setLabel,
  } = useTransaction({ type: "PERSONAL" });
  const plan = userMembership;

  const [showSnackbar, setShowSnackbar] = React.useState(false);
  const activateSnackbar = () => setShowSnackbar(!showSnackbar);
  const dismissSnackbar = () => setShowSnackbar(false);
  const [showPaid, setShowPaidPlan] = useState(false);

  return (
    <Provider>
      {!loader ? (
        <LoadingComponent />
      ) : plan === "basic" ? (
        <View
          style={{
            marginHorizontal: globalStyles.shape.horizontal,
            flex: 1,
            justifyContent: "center",
          }}
        >
          <Text style={{ fontSize: 20, fontWeight: "700" }}>
            ¡Ups! 😔 Esta pestaña sólo está disponible para el plan plus y pro
            de Qentaz 🚀
          </Text>
          <Text
            style={{
              marginTop: globalStyles.shape.sectionSpace,
              fontSize: 18,
              color: globalStyles.colors.darkGrey,
            }}
          >
            Para acceder a esta información puedes elegir uno de nuestros planes
            👇
            <Text>¡Potenciaremos tus finanzas! 💚 </Text>
          </Text>
          <View style={{ marginTop: globalStyles.shape.sectionSpaceBottom }}>
            <Button
              title="¡Conocer planes pagos!"
              color={globalStyles.colors.primary}
              onPress={() =>
                navigation.navigate("Más", {
                  screen: "Planes",
                  nav: true,
                })
              }
            />
          </View>
          {showPaid && (
            <Portal>
              <PlanPaid
                navigation={navigation}
                type={"tx"}
                close={() => setShowPaidPlan(false)}
              />
            </Portal>
          )}
        </View>
      ) : (
        <>
          <ScrollView>
            <View style={globalClasses.screenContainer}>
              <DateButtons
                date={date}
                setDate={setDate}
                setLabel={setLabel}
                label={label}
                contactPhone={phone}
              />
              <PeriodSlider
                from={date}
                contactPhone={phone}
                label={label}
                setCurrentDate={setDate}
                currentDate={date}
                paramsDate={paramsDate}
                weeks_days={weeks_days}
              />
              <BalanceCard
                countryCurrency={countryCurrency}
                totalBalance={total_balance}
                incomeTotal={total_income}
                outcomeTotal={total_outcome}
              />
              <InterpretationCard
                description={
                  Number(total_balance) === 0
                    ? "¡Lo siento! No tengo información suficiente para analizar 😔"
                    : msg
                        ?.replace(
                          "gastosDiarios",
                          `${countryCurrency}${total_outcome}`
                        )
                        ?.replace(
                          "gastosMes",
                          `${countryCurrency}${total_outcome}`
                        )
                        ?.replace(
                          "gastosSemanal",
                          `${countryCurrency}${total_outcome}`
                        )
                }
              />
              {purposeUse !== "META_GANANCIA" ? (
                <>
                  <Text style={globalClasses.sectionTitle}>
                    ¿En qué gasté más?
                  </Text>
                  {purposeUse === "META_DEUDA" && (
                    <Text style={globalClasses.caption}>
                      Ten tus gastos bajo control, ahorra y ya no te preocupes
                      por tus deudas
                    </Text>
                  )}
                  {outcome_transactions?.map((x, i) => (
                    <TopTransactions
                      tx={x.type === "income" ? "ingreso" : ""}
                      number={i + 1}
                      description={x.description}
                      amount={x.amount}
                      date={x.date}
                      countryCurrency={countryCurrency}
                    />
                  ))}{" "}
                </>
              ) : (
                <>
                  <Text style={globalClasses.sectionTitle}>
                    ¿En qué ganas más?
                  </Text>
                  {income_transactions?.map((x, i) => (
                    <TopTransactions
                      tx={x.type === "income" ? "ingreso" : ""}
                      number={i + 1}
                      description={x.description}
                      amount={x.amount}
                      date={x.date}
                      countryCurrency={countryCurrency}
                    />
                  ))}{" "}
                </>
              )}
              <TouchableOpacity
                onPress={async () => {
                  navigation.navigate("Análisis", {
                    nav: true,
                  });

                  await visitPageSummary(phone, {
                    previous_screen: "Movimientos",
                    type: "banner",
                  });
                }}
              >
                <View
                  style={{
                    marginTop: globalStyles.shape.sectionSpace,
                    borderRadius: 10,

                    overflow: "hidden",
                  }}
                >
                  <Image
                    source={{
                      uri: `https://qentaz.s3.amazonaws.com/public/Reporte/BANNER_Analisis.jpg`,
                    }}
                    resizeMode="contain"
                    style={{
                      width: "100%",
                      height: 200,
                      borderRadius: 10,
                    }}
                  />
                </View>
              </TouchableOpacity>
              <Text style={globalClasses.sectionTitle}>
                Tus movimientos a detalle 🔍
              </Text>
              <View
                style={{
                  marginBottom: globalStyles.shape.sectionSpaceBottom,
                }}
              >
                {transactions?.length ? (
                  <>
                    <Searchbar
                      placeholder="Busqueda"
                      onChangeText={onChangeSearch}
                      value={searchQuery}
                      inputStyle={globalClasses.smallTitle}
                    />
                    {transactions
                      .filter((x) =>
                        x.attributes.description
                          .toLowerCase()
                          .match(searchQuery)
                      )
                      .map(({ id, attributes: x }) => (
                        <TransactionsList
                          id={id}
                          description={x.description}
                          date={x.date}
                          amount={x.amount}
                          countryCurrency={countryCurrency}
                          tx={x.type}
                          updateTransaction={updateTransaction}
                          categoryType={x.categoryType}
                          deleteTransaction={deleteTransaction}
                          activateSnackbar={activateSnackbar}
                          phone={phone}
                          userType={userType}
                          userMembership={userMembership}
                          newTxData={newTxData}
                          updateNewTxInfo={updateNewTxInfo}
                        />
                      ))}
                  </>
                ) : (
                  <View>
                    <Text>No tienes movimientos recientes</Text>
                  </View>
                )}
              </View>
            </View>
          </ScrollView>
          <DeleteSnackbar
            showSnackbar={showSnackbar}
            dismissSnackbar={dismissSnackbar}
          />
        </>
      )}
    </Provider>
  );
};

export default TransactionsPersonal;
