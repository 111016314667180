import React, { useState } from "react";
import { IconButton, TextInput } from "react-native-paper";
import { Button, StyleSheet, Text, View } from "react-native";

import ModalTemplate from "../ModalTemplate";
import { globalClasses, globalStyles } from "../../styles";

const SetGoal = ({
  setGoalWeek,
  goalWeek,
  createGoalWeekValues,
  type,
  show,
  dismiss,
  content,
  setAnalytic,
}) => {
  const [placeholder, setPlaceHolder] = useState("$000.000");
  const setGoal = async () => {
    createGoalWeekValues();
    await setAnalytic();
    dismiss();
  };

  return (
    <ModalTemplate visible={show}>
      <IconButton
        icon="close"
        onPress={dismiss}
        iconColor={globalStyles.colors.darkGrey}
        size={25}
      />
      <View style={{ textAlign: "center" }}>
        <Text style={[globalClasses.cardTitle, { fontWeight: "700" }]}>
          Meta {content === "semanal" ? "semanal" : "mensual"}
        </Text>
        <Text style={{ marginTop: globalStyles.shape.betweenSpace }}>
          Escribe el valor que tienes como meta para tu análisis{" "}
          {content === "semanal" ? "semanal" : "mensual"}. Recuerda que este
          quedará activo hasta que decidas cambiarlo de nuevo.
        </Text>
      </View>
      <View style={styles.inputContainer}>
        <TextInput
          mode="outline"
          dense
          placeholder={placeholder}
          placeholderTextColor="#a6a6a6"
          onChangeText={(value) => setGoalWeek({ [type]: value })}
          value={goalWeek[type]}
          style={{ width: "80%", textAlign: "center" }}
          textAlign="center"
          left={<TextInput.Affix text="$" />}
          keyboardType="numeric"
          onFocus={() => setPlaceHolder("")}
          onBlur={() => setPlaceHolder("$000.000")}
        />
        <View
          style={{ marginTop: globalStyles.shape.betweenSpace, width: "60%" }}
        >
          <Button
            title="Continuar"
            color={globalStyles.colors.primary}
            onPress={async () => await setGoal()}
          />
        </View>
      </View>
    </ModalTemplate>
  );
};

export default SetGoal;

const styles = StyleSheet.create({
  container: {
    marginHorizontal: 20,
    padding: globalStyles.shape.horizontal,
    backgroundColor: globalStyles.colors.white,
    borderRadius: 10,
  },
  inputContainer: {
    alignItems: "center",
    marginTop: globalStyles.shape.betweenSpace,
  },
});
