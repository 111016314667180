import React, { useEffect, useState } from "react";
import { Button, StyleSheet, Text, View } from "react-native";
import Ionicons from "react-native-vector-icons/Ionicons";
import {
  IconButton,
  RadioButton,
  Snackbar,
  TextInput,
} from "react-native-paper";

import ModalTemplate from "../ModalTemplate";
import { globalClasses, globalStyles } from "../../styles";
import { slackNotificationProblem } from "../../analytics/src/open-report";

const ProblemReport = ({ show, screen, dismiss, phone }) => {
  const dateOptions = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  };
  const [option, setOption] = useState("");
  const today = new Date(new Date()).toLocaleDateString("es-ES", dateOptions);
  const [error, setError] = useState({});
  useEffect(() => {
    setError({ screen: screen, date: today, description: "", errorType: "" });
  }, []);
  const [showSnackbar, setShowSnackbar] = useState(false);
  return (
    <>
      <ModalTemplate visible={show}>
        <IconButton
          icon="close"
          onPress={dismiss}
          iconColor={globalStyles.colors.darkGrey}
          size={25}
        />
        <Text
          style={[
            globalClasses.cardTitle,
            { fontWeight: "700", textAlign: "center" },
          ]}
        >
          Reportar un problema
        </Text>

        <Text
          style={[
            globalClasses.cardTitlePeriod,
            { marginTop: globalStyles.shape.betweenSpace },
          ]}
        >
          Pantalla en la que te encuentras:
        </Text>
        <Text>{screen}</Text>

        <View style={{ marginTop: globalStyles.shape.betweenSpace }}>
          <Text style={globalClasses.cardTitlePeriod}>Fecha del reporte: </Text>
          <Text>{today}</Text>
        </View>
        <View style={{ marginTop: globalStyles.shape.betweenSpace }}>
          <Text style={globalClasses.cardTitlePeriod}>Tipo de problema:</Text>
          <RadioButton.Group
            onValueChange={(newOption) => {
              setOption(newOption);
              setError({ ...error, errorType: newOption });
            }}
            value={option}
          >
            <View style={{ flexDirection: "row" }}>
              <RadioButton
                color={globalStyles.colors.primary}
                value="informacion"
              />
              <Text style={{ marginTop: 8 }}>Información errónea</Text>
            </View>
            <View style={{ flexDirection: "row" }}>
              <RadioButton
                color={globalStyles.colors.primary}
                value="functionalidad"
              />
              <Text style={{ marginTop: 8 }}>Funcionalidad del reporte</Text>
            </View>
          </RadioButton.Group>
        </View>

        <TextInput
          mode="outlined"
          label="Describe lo ocurrido"
          multiline
          activeOutlineColor={globalStyles.colors.primary}
          style={{
            height: 100,
            marginVertical: globalStyles.shape.betweenSpace,
          }}
          onChangeText={(val) => setError({ ...error, description: val })}
        />
        <Button
          disabled={
            error.description === "" && error.errorType === "" ? true : false
          }
          onPress={async () => {
            dismiss();
            setShowSnackbar(true);            
            await slackNotificationProblem({ phone, error });
            setOption("");
          }}
          title="Enviar reporte"
          color={globalStyles.colors.primary}
        />
      </ModalTemplate>
      <Snackbar
        duration={5000}
        style={{ backgroundColor: "rgba(255,255,255,1)" }}
        visible={showSnackbar}
        onDismiss={() => setShowSnackbar(false)}
      >
        <View style={{ flexDirection: "row" }}>
          <View style={{ padding: 5 }}>
            <Ionicons
              name="checkmark-circle"
              size={30}
              color={globalStyles.colors.primary}
            />
          </View>
          <View>
            <Text>Gracias por el reporte.</Text>
            <Text style={globalClasses.smallTitle}>
              El problema será notificado al equipo
            </Text>
          </View>
        </View>
      </Snackbar>
    </>
  );
};

export default ProblemReport;

const styles = StyleSheet.create({});
