import { StyleSheet, Text, View } from "react-native";
import React from "react";
import {
  VictoryAxis,
  VictoryBar,
  VictoryChart,
  VictoryContainer,
  VictoryTheme,
} from "victory";

import { globalClasses, globalStyles } from "../styles";

const MyIncomesCard = ({
  monthIncomeValues,
  monthValuesOut,
  currentWeek,
  lastWeek,
  purposeUse,
  content,
}) => {
  const currencyFormat = Intl.NumberFormat("es-CO");
  let weekIncomes = [];
  const data = [
    { x: "Mínimo", y: 0, fill: globalStyles.colors.red },
    {
      x: content === "semanal" ? "Sem. pasada" : "Mes pasado",
      y: 0,
      fill: globalStyles.colors.darkGrey,
    },
    {
      x: content === "semanal" ? "Esta semana" : "Este mes",
      y: 0,
      fill: globalStyles.colors.secondary,
    },
    { x: "Máximo", y: 0, fill: globalStyles.colors.primary },
  ];

  if (!_.isEmpty(monthIncomeValues) && !_.isEmpty(monthValuesOut)) {
    const monthValuesInc = monthIncomeValues;

    const labels = ["Sem 1", "Sem 2", "Sem 3", "Sem 4"];

    const getValues = (data) => data?.map((x) => x?.amount);

    const weeksInc = [];
    const weeksOut = [];

    let counter = 0;
    let counter2 = 0;

    for (const key in monthValuesInc) {
      weeksInc.push({
        week: labels[counter],
        amount:
          monthValuesInc[key].length === 0
            ? monthValuesInc[key][0].amount
            : monthValuesInc[key].map((a) => a?.amount).reduce((a, b) => a + b),
      });

      counter++;
    }

    for (const key in monthValuesOut) {
      weeksOut.push({
        week: labels[counter2],
        amount:
          monthValuesOut[key].length === 0
            ? monthValuesOut[key][0].amount
            : monthValuesOut[key].map((a) => a?.amount).reduce((a, b) => a + b),
      });

      counter2++;
    }

    weekIncomes = getValues(weeksInc);
    weekIncomes = getValues(weeksOut);

    for (let weeks = weeksInc.length; weeks < labels.length; weeks++) {
      weeksInc.length < labels.length &&
        weeksInc.push({ week: labels[counter], amount: 0 });
      weeksOut.length < labels.length &&
        weeksOut.push({ week: labels[counter2], amount: 0 });
    }

    const orderIncomeWeek = weeksInc?.sort((a, b) => b.amount - a.amount);
    const orderOutcomeWeek = weeksOut?.sort((a, b) => b.amount - a.amount);

    const currentWeekIncome = currentWeek?.total_income;
    const lastWeekIncome = lastWeek?.total_income;
    const currentWeekOutcome = currentWeek?.total_outcome;
    const lastWeekOutcome = lastWeek?.total_outcome;

    const maxWeekIncome = orderIncomeWeek[0]?.amount;
    const filterArray = orderIncomeWeek.filter((x) => x.amount);
    const minWeekIncome =
      filterArray?.length > 1 ? filterArray[filterArray.length - 1]?.amount : 0;

    const maxWeekOutcome = orderOutcomeWeek[0]?.amount;
    const minWeekOutcome =
      orderOutcomeWeek?.length > 1
        ? orderOutcomeWeek[orderOutcomeWeek.length - 1]?.amount
        : 0;

    data[0].y =
      purposeUse === "META_GANANCIA"
        ? minWeekIncome == 0
          ? 0
          : Number(String(minWeekIncome)?.replace(/\D/g, ""))
        : minWeekOutcome == 0
        ? 0
        : Number(String(minWeekOutcome)?.replace(/\D/g, ""));

    data[1].y =
      purposeUse === "META_GANANCIA"
        ? lastWeekIncome == 0
          ? 0
          : Number(String(lastWeekIncome)?.replace(/\D/g, ""))
        : lastWeekOutcome == 0
        ? 0
        : Number(String(lastWeekOutcome)?.replace(/\D/g, ""));

    data[2].y =
      purposeUse === "META_GANANCIA"
        ? currentWeekIncome == 0
          ? 0
          : Number(String(currentWeekIncome)?.replace(/\D/g, ""))
        : currentWeekOutcome == 0
        ? 0
        : Number(String(currentWeekOutcome)?.replace(/\D/g, ""));

    data[3].y =
      purposeUse === "META_GANANCIA"
        ? Number(String(maxWeekIncome)?.replace(/\D/g, "")) >
          Number(String(lastWeekIncome)?.replace(/\D/g, ""))
          ? Number(String(maxWeekIncome)?.replace(/\D/g, ""))
          : Number(String(lastWeekIncome)?.replace(/\D/g, ""))
        : Number(String(maxWeekOutcome)?.replace(/\D/g, "")) >
          Number(String(lastWeekOutcome)?.replace(/\D/g, ""))
        ? Number(String(maxWeekOutcome)?.replace(/\D/g, ""))
        : Number(String(lastWeekOutcome)?.replace(/\D/g, ""));
  }

  return (
    <>
      <Text style={globalClasses.sectionTitle}>
        ¿Cómo van mis {purposeUse === "META_GANANCIA" ? "ventas" : "gastos"}?
      </Text>
      <Text>
        Aquí una comparación de tus{" "}
        <Text style={globalClasses.smallTitle}>
          {purposeUse === "META_GANANCIA" ? "ventas" : "gastos"}
        </Text>{" "}
        de{" "}
        {content === "semanal"
          ? "esta semana versus la semana pasada"
          : "este mes versus el mes pasado"}
        .
      </Text>
      <View style={globalClasses.card} pointerEvents="none">
        <VictoryChart
          theme={VictoryTheme.material}
          maxDomain={{
            y: data[3].y,
          }}
          containerComponent={
            <VictoryContainer
              style={{
                pointerEvents: "auto",
                userSelect: "auto",
                touchAction: "auto",
              }}
            />
          }
        >
          <VictoryBar
            labels={({ datum }) => `$${currencyFormat.format(datum.y)}`}
            cornerRadius={4}
            barWidth={62}
            data={data}
            y="y"
            style={{
              labels: {
                fill: "black",
              },
              data: {
                fill: ({ datum }) => datum.fill,
              },
            }}
            animate={{
              duration: 2000,
              onLoad: { duration: 1000 },
            }}
          />
          <VictoryAxis
            crossAxis={false}
            style={{
              grid: {
                stroke: "none",
              },
            }}
          />
        </VictoryChart>

        <View style={styles.captionContainer}>
          {purposeUse === "META_GANANCIA" ? (
            <Text style={globalClasses.caption}>
              El mínimo representa cuánto vendiste{" "}
              {content === "semanal" ? "la semana" : "el mes"} que menos te
              ingresó, y el máximo,
              {content === "semanal" ? "la semana" : "el mes"} que más te
              ingresó, desde que te registraste en Qentaz.
            </Text>
          ) : (
            <Text style={globalClasses.caption}>
              El mínimo representa{" "}
              {content === "semanal" ? "la semana" : "el mes"} que menos
              gastaste, y el máximo,{" "}
              {content === "semanal" ? "la semana" : "el mes"} que más gastaste,
              desde que te registraste en Qentaz.
            </Text>
          )}
        </View>
      </View>
    </>
  );
};

export default MyIncomesCard;

const styles = StyleSheet.create({
  captionContainer: {
    width: "95%",
    padding: 5,
    borderRadius: 7,
    alignSelf: "center",
    textAlign: "center",
    backgroundColor: globalStyles.colors.lightGrey,
    marginTop: globalStyles.shape.betweenSpace,
  },
});
