import { BASE_URL, UPDATE_MEMBERSHIP } from "../../config";
import { postData } from "../api-call";

const updateMembership = async ({
  id,
  paidInitialPlanDate,
  paidEndPlanDate,
  userMembership,
}) => {
  const data = {
    id,
    paidInitialPlanDate,
    paidEndPlanDate,
    userMembership,
  };

  const requestUrl = `${BASE_URL}${UPDATE_MEMBERSHIP}`;

  return postData(requestUrl, data);
};

export const UpdateMembershipAPI = {
  updateMembership,
};
