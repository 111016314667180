import React from "react";
import { View, Text, TouchableOpacity } from "react-native";

import { globalClasses, globalStyles } from "../styles";

const ProfileCard = ({
  title,
  description,
  visible,
  setVisible,
  saveNewInfo,
  onToggleSnackBar,
  children,
  purpose,
}) => {
  const toggleVisible = () => setVisible(!visible);

  const saveAction = () => {
    saveNewInfo();
    toggleVisible;
    onToggleSnackBar();
  };
  return (
    <View style={globalClasses.card}>
      <View style={globalClasses.spacedRow}>
        <View>
          <Text style={globalClasses.smallTitle}>{title}</Text>
          <Text style={{ width: 200, marginTop: 4 }}>{description}</Text>
          {purpose && <Text>{purpose}</Text>}
        </View>
        {!visible ? (
          <TouchableOpacity
            // style={{ justifyContent: "center" }}
            onPress={toggleVisible}
          >
            <Text
              style={[
                globalClasses.smallTitle,
                { color: globalStyles.colors.primary, textAlign: "center" },
              ]}
            >
              Editar
            </Text>
          </TouchableOpacity>
        ) : (
          <View style={{ flexDirection: "row" }}>
            <TouchableOpacity
              onPress={toggleVisible}
              style={{ marginRight: 8 }}
            >
              <Text style={globalClasses.smallTitle}>Cancelar</Text>
            </TouchableOpacity>
            <TouchableOpacity onPress={saveAction}>
              <Text
                style={[
                  globalClasses.smallTitle,
                  {
                    color: globalStyles.colors.primary,
                    textAlign: "center",
                  },
                ]}
              >
                Guardar
              </Text>
            </TouchableOpacity>
          </View>
        )}
      </View>
      {visible && children}
    </View>
  );
};

export default ProfileCard;
