import { BASE_URL, UPDATE_CONTACT_DATA, USER_IN_QUERY } from "../../config";
import { getData, postData, putData } from "../api-call";

const getInfo = ({ id }: { id: string }) => {
  const requestUrl = `${BASE_URL}${USER_IN_QUERY}/${id}`;

  return getData(requestUrl);
};
const putInfo = ({ id, info }: { id: string; info: {} }) => {
  const requestUrl = `${BASE_URL}${UPDATE_CONTACT_DATA}`;
  const data = {id, ...info };
  return postData(requestUrl, data);
};

export const UserInfoAPI = {
  getInfo,
  putInfo,
};
