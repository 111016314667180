import _ from "lodash";
import React, { useEffect, useState } from "react";
import { SegmentedButtons } from "react-native-paper";
import { Text, TouchableOpacity, View, Image } from "react-native";

import { useDebts } from "./useDebt";
import {
  visitPageChangePlan,
  visitPageDebts,
} from "../../analytics/src/open-report";
import Screen from "../../Templates/Screen";
import DebtsList from "../../Components/DebtsList";
import DebtsCard from "../../Components/DebtsCard";
import DebtsDropdown from "../../Components/DebtsDropdown";
import { globalClasses, globalStyles } from "../../styles";
import DeleteSnackbar from "../../Components/DeleteSnackbar";
import ModifySnackbar from "../../Components/ModifySnackbar";

const Debts = ({ navigation, route }) => {
  const [value, setValue] = React.useState("pending");

  const {
    financeData: {
      total_amount_debt,
      total_amount_owe,
      debts,
      week_period_debt,
    },
    deleteDebt,
    updateDebt,
    userData: { countryCurrency, phone },
    payDebt,
    setPayDebt,
    setNewDate,
    newDate,
    msg,
    loader,
  } = useDebts();

  const setAnalytic = async (route) => {
    await visitPageDebts(phone, { type: "navigation" });
  };

  useEffect(() => {
    if (phone && !route?.params?.nav) {
      setAnalytic();
    }
  }, [phone]);

  const activeDebts = debts?.filter((x) => x?.attributes?.active) || [];
  const completeDebts = debts?.filter((x) => !x?.attributes?.active) || [];

  const expireDebts =
    debts?.filter(
      (x) =>
        new Date(x?.attributes?.account_type[0]?.payment_date) < new Date() &&
        x?.attributes.active
    ) || [];

  //delete
  const [snackDelete, setSnackDelete] = React.useState(false);
  const activateDeleteSnackbar = () => setSnackDelete(!snackDelete);
  const dismissDeleteSnackbar = () => setSnackDelete(false);

  //modify
  const [snackModify, setSnackModify] = React.useState(false);
  const activateModifySnackbar = () => setSnackModify(!snackModify);
  const dismissModifySnackbar = () => setSnackModify(false);

  //problem
  const [problem, setProblem] = useState(false);
  return (
    <>
      <Screen loader={loader} route={route} phone={phone}>
        <Text style={globalClasses.sectionTitle}>Tus deudas en resumen</Text>
        <DebtsCard
          countryCurrency={countryCurrency}
          debtsCxCvalue={total_amount_owe}
          debtsCxPvalue={total_amount_debt}
          debtsQuantity={activeDebts?.length}
          activeDebts={activeDebts}
        />
        {!_.isEmpty(expireDebts) && (
          <DebtsDropdown
            type="vencidas"
            countryCurrency={countryCurrency}
            debtsNotification={expireDebts}
            activateDeleteSnackbar={activateDeleteSnackbar}
            activateModifySnackbar={activateModifySnackbar}
            deleteDebt={deleteDebt}
            updateDebt={updateDebt}
            payDebt={payDebt}
            setPayDebt={setPayDebt}
            setNewDate={setNewDate}
            newDate={newDate}
            phone={phone}
          />
        )}
        {!_.isEmpty(week_period_debt) && (
          <DebtsDropdown
            debtsNotification={week_period_debt}
            countryCurrency={countryCurrency}
            activateDeleteSnackbar={activateDeleteSnackbar}
            activateModifySnackbar={activateModifySnackbar}
            deleteDebt={deleteDebt}
            updateDebt={updateDebt}
            payDebt={payDebt}
            setPayDebt={setPayDebt}
            setNewDate={setNewDate}
            newDate={newDate}
            phone={phone}
          />
        )}
        {/* <>
                {msg?.pending !== "." ? (
                  <>
                    <Text style={globalClasses.sectionTitle}>
                      Una mirada más profunda a tus deudas 🔎
                    </Text>
                    <View>
                      <View style={styles.messageCard}>
                        <View style={{ marginRight: 7 }}>
                          <MaterialCommunityIcons
                            name="creation"
                            color={globalStyles.colors.primary}
                            size={25}
                          />
                        </View>
                        <View style={{ width: "91%" }}>
                          <>
                            <Text style={globalClasses.smallTitle}>
                              Las deudas no son malas, solo ten en cuenta estas
                              recomendaciones:
                            </Text>
                            <br />

                            <Text>-{msg?.pending}</Text>
                            <br />
                            <Text>-{msg?.expires}</Text>
                            <br />
                            <Text>-{msg?.capacity}</Text>
                            <br />
                            <Text>-{msg?.debts}</Text>
                            <br />
                            <Text>{msg?.goals?.snow_ball}</Text>
                            <br />
                            <Text>{msg?.goals?.motivation}</Text>
                          </>
                        </View>
                      </View>
                    </View>
                  </>
                ) : (
                  ""
                )}
              </> */}
        <TouchableOpacity
          onPress={async () => {
            navigation.navigate("Más", {
              screen: "Planes",
              nav: true,
            });
            await visitPageChangePlan(phone, {
              previous_screen: "Deudas",
              type: "banner",
            });
          }}
        >
          <View
            style={{
              marginTop: globalStyles.shape.sectionSpace,
              borderRadius: 10,

              overflow: "hidden",
            }}
          >
            <Image
              source={{
                uri: `https://qentaz.s3.amazonaws.com/public/Reporte/BANNER_Planes.jpg`,
              }}
              resizeMode="contain"
              style={{
                width: "100%",
                height: 200,
                borderRadius: 10,
              }}
            />
          </View>
        </TouchableOpacity>
        <Text style={globalClasses.sectionTitle}>Tus deudas a detalle</Text>
        <SegmentedButtons
          value={value}
          onValueChange={setValue}
          checkedColor="white"
          uncheckedColor="black"
          style={{
            width: "100%",
            marginTop: globalStyles.shape.sectionSpace,
          }}
          buttons={[
            {
              value: "pending",
              label: "Pendientes",
              style: {
                backgroundColor:
                  value === "pending" ? globalStyles.colors.primary : "white",
              },
            },
            {
              value: "completed",
              label: "Completadas",
              style: {
                backgroundColor:
                  value === "completed" ? globalStyles.colors.primary : "white",
              },
            },
          ]}
        />
        {value === "pending" ? (
          activeDebts?.length ? (
            activeDebts?.map(({ id, attributes: x }) => (
              <DebtsList
                amount={x.amount}
                active={x.active}
                deleteDebt={deleteDebt}
                updateDebt={updateDebt}
                payDebt={payDebt}
                setPayDebt={setPayDebt}
                setNewDate={setNewDate}
                newDate={newDate}
                type={x.type}
                paymentDate={x?.account_type[0]?.payment_date}
                payFinaldate={x?.payFinaldate}
                holder={x?.holder}
                description={x?.description}
                currentAmount={x?.currentAmount}
                countryCurrency={countryCurrency}
                debtId={id}
                activateModifySnackbar={activateModifySnackbar}
                activateDeleteSnackbar={activateDeleteSnackbar}
                phone={phone}
              />
            ))
          ) : (
            <Text style={globalClasses.smallTitle}>
              No tienes deudas pendientes 😊
            </Text>
          )
        ) : completeDebts?.length ? (
          completeDebts?.map(({ id, attributes: x }) => (
            <DebtsList
              amount={x.amount}
              active={x.active}
              type={x.type}
              setNewDate={setNewDate}
              newDate={newDate}
              deleteDebt={deleteDebt}
              paymentDate={x?.account_type[0]?.payment_date}
              payFinaldate={x?.payFinaldate}
              holder={x?.holder}
              description={x?.description}
              currentAmount={x?.currentAmount}
              countryCurrency={countryCurrency}
              debtId={id}
              activateSnackbar={activateModifySnackbar}
              activateDeleteSnackbar={activateDeleteSnackbar}
              phone={phone}
            />
          ))
        ) : (
          <Text style={globalClasses.smallTitle}>
            No tienes deudas completadas 😃
          </Text>
        )}
      </Screen>
      <DeleteSnackbar
        showSnackbar={snackDelete}
        dismissSnackbar={dismissDeleteSnackbar}
      />
      <ModifySnackbar
        showSnackbar={snackModify}
        dismissSnackbar={dismissModifySnackbar}
      />
    </>
  );
};

export default Debts;
