import React, { useState, useEffect } from "react";

import { DashboardAPI } from "../../api/src/dashboard";
import { UserInfoAPI } from "../../api/src/user-info";
import { ContactGoalAPI } from "../../api/src/contact-goal";

import {
  checkProperty,
  getHashId,
  getDate,
  getCurrencySymbol,
  changeDateFilter,
} from "../../helpers/src";
import axios from "axios";

export const useClearFinances = () => {
  const paramsId = window.location.pathname || localStorage.getItem("hashId");
  const paramsDate = window.location.search || "?from=hoy";
  const [isFetchingData, setIsFetchingData] = useState(false);
  const [isFetchingUserData, setIsFetchingUserData] = useState(false);

  const [incomesCardData, setIncomesCardData] = useState({});
  const [mySalesCardData, setMySalesCardData] = useState({});
  const [goalsCardData, setGoalsCardData] = useState({});

  const [financeData, setFinanceData] = useState({});
  const [financeDataWeek, setFinanceDataWeek] = useState({});
  const [financeDataLastWeek, setFinanceDataLastWeek] = useState({});

  const [financeDataMonth, setFinanceDataMonth] = useState({});
  const [financeDataLastMonth, setFinanceDataLastMonth] = useState({});

  const [userData, setUserData] = useState({});
  const [date, setDate] = useState(getDate(paramsDate));
  const [refreshContactGoal, setRefreshContactGoal] = useState(false);
  const [msg, setMsg] = useState("");
  const [loader, setLoader] = useState(false);

  const [goalWeek, setGoalWeek] = useState({
    income: "",
    outcome: "",
  });

  const [goalWeekValues, setGoalWeekValues] = useState({
    income: "",
    outcome: "",
  });

  const [goalMonth, setGoalMonth] = useState({
    income: "",
    outcome: "",
  });

  const [goalMonthValues, setGoalMonthValues] = useState({
    income: "",
    outcome: "",
  });

  const [type, setType] = useState("");

  const [urlRoutes, setUrlRoute] = useState("");

  const hashId = getHashId(paramsId);

  const clearGoalWeekValues = (type) => {
    const data = {
      id: getHashId(paramsId),
      incomeGoal: type === "incomeWeek" ? "0" : String(goalWeekValues.income),
      outcomeGoal:
        type === "outcomeWeek" ? "0" : String(goalWeekValues.outcome),
      incomeGoalMonth:
        type === "incomeMonth" ? "0" : String(goalMonthValues.income),
      outcomeGoalMonth:
        type === "outcomeMonth" ? "0" : String(goalMonthValues.outcome),
    };

    ContactGoalAPI.updateContactGoal(data)
      .then((x) => {
        setGoalWeekValues({
          income: checkProperty(x?.data?.goalIncomeWeek),
          outcome: checkProperty(x?.data?.goalOutcomeWeek),
        });
        setGoalMonthValues({
          income: checkProperty(x?.data?.goalIncomeMonth),
          outcome: checkProperty(x?.data?.goalOutcomeMonth),
        });
      })
      .then(() => setRefreshContactGoal(true))
      .finally(() => {
        setIsFetchingUserData(false);
        setRefreshContactGoal(false);
      });
  };

  const createGoalWeekValues = () => {
    const data = {
      id: getHashId(paramsId),
      incomeGoal: goalWeek.income || String(goalWeekValues.income),
      outcomeGoal: goalWeek.outcome || String(goalWeekValues.outcome),
      incomeGoalMonth: goalMonth.income || String(goalMonthValues.income),
      outcomeGoalMonth: goalMonth.outcome || String(goalMonthValues.outcome),
    };
    ContactGoalAPI.updateContactGoal(data)
      .then((x) =>
        setGoalWeekValues({
          income: checkProperty(x?.data?.goalIncomeWeek),
          outcome: checkProperty(x?.data?.goalOutcomeWeek),
        })
      )
      .then(() => setRefreshContactGoal(true))
      .finally(() => {
        setIsFetchingUserData(false);
        setRefreshContactGoal(false);
      });
  };

  const createGoalMonthValues = () => {
    const data = {
      id: getHashId(paramsId),
      incomeGoalMonth: goalMonth.income || String(goalMonthValues.income),
      outcomeGoalMonth: goalMonth.outcome || String(goalMonthValues.outcome),
      incomeGoal: goalWeek.income || String(goalWeekValues.income),
      outcomeGoal: goalWeek.outcome || String(goalWeekValues.outcome),
    };
    ContactGoalAPI.updateContactGoal(data)
      .then((x) =>
        setGoalMonthValues({
          income: checkProperty(x?.data?.goalIncomeWeek),
          outcome: checkProperty(x?.data?.goalOutcomeWeek),
        })
      )
      .then(() => setRefreshContactGoal(true))
      .finally(() => {
        setIsFetchingUserData(false);
        setRefreshContactGoal(false);
      });
  };

  useEffect(() => {
    setIsFetchingData(true);
    axios
      .post(
        "https://qentaz-notification-server.herokuapp.com/report-message-notification",
        {
          id: hashId,
          from: "Hoy",
        }
      )
      .then((x) =>
        setMsg({
          score: x?.data?.data?.scoreMsg,
          goalsValue: x?.data?.data?.goals,
          goals: x?.data?.data?.goalsMsg,
          scoreValue: x?.data?.data?.score,
        })
      )
      .finally(() => setIsFetchingData(false));

    setUrlRoute(paramsId + paramsDate);

    DashboardAPI.getUserFinancesData({ id: hashId, from: date })
      .then((x) => {
        setIncomesCardData({
          most_amount_month_incomes: checkProperty(
            x?.most_amount_month_incomes
          ),
          most_amount_month_outcome: checkProperty(
            x?.most_amount_month_outcomes
          ),
          most_amount_year_incomes: checkProperty(x?.most_amount_year),
          most_amount_year_outcomes: checkProperty(x?.most_amount_year_outcome),
        });
        setMySalesCardData({
          most_amount_day: checkProperty(x?.most_amount_day),
          most_amount_day_outcome: checkProperty(x?.most_amount_day_outcome),
          most_profit_day: checkProperty(x?.most_profit_day),
          most_profit_day_outcome: checkProperty(x?.most_profit_day_outcome),
          count_day_income: checkProperty(x?.count_day_income),
          count_day_outcome: checkProperty(x?.count_day_outcome),
          loss_profit_day_outcome: checkProperty(x?.most_loss_day_outcome),
          loss_profit_day: checkProperty(x?.most_loss_day),
        });
        setFinanceData({
          great_movement: checkProperty(x?.great_movement),
          minor_movement: checkProperty(x?.minor_movement),
          total_amount_transactions: x?.total_amount_transactions,
          outcome_average: checkProperty(x?.outcome_average),
          outcome_average_type: checkProperty(x?.outcome_average_type),
          transactions: checkProperty(x?.transactions).length,
        });
      })
      .finally(() => setIsFetchingData(false));

    DashboardAPI.getUserFinancesData({ id: hashId, from: "semana" })
      .then((x) => {
        setFinanceDataWeek({
          total_income: checkProperty(x?.total_income),
          total_outcome: checkProperty(x?.total_outcome),
        });
      })
      .finally(() => setIsFetchingData(false));

    DashboardAPI.getUserFinancesData({ id: hashId, from: "week1" })
      .then((x) => {
        setFinanceDataLastWeek({
          total_income: checkProperty(x?.total_income),
          total_outcome: checkProperty(x?.total_outcome),
        });
      })
      .finally(() => setIsFetchingData(false));

    DashboardAPI.getUserFinancesData({ id: hashId, from: "mes-pasado" })
      .then((x) => {
        setFinanceDataLastMonth({
          total_income: checkProperty(x?.total_income),
          total_outcome: checkProperty(x?.total_outcome),
        });
      })
      .finally(() => setIsFetchingData(false));

    DashboardAPI.getUserFinancesData({ id: hashId, from: "mes" })
      .then((x) => {
        setFinanceDataMonth({
          total_income: checkProperty(x?.total_income),
          total_outcome: checkProperty(x?.total_outcome),
        });
      })
      .finally(() => setIsFetchingData(false));

    UserInfoAPI.getInfo({ id: hashId })
      .then((x) =>
        setUserData({
          userId: checkProperty(x?.contact?.id),
          countryCurrency: getCurrencySymbol(
            checkProperty(x?.contact?.attributes?.countryCode)
          ),
          userType: checkProperty(x?.contact?.attributes?.userType),
          userMembership: checkProperty(x?.contact?.attributes?.userMembership),
          phone: checkProperty(x?.contact?.attributes?.phone),
          purposeUse: checkProperty(x?.contact?.attributes?.purposeUse),
          createdAt: checkProperty(x?.contact?.attributes?.createdAt),
        })
      )
      .finally(() => setIsFetchingUserData(false));

    setTimeout(() => {
      setLoader(true);
    }, 2000);
  }, [date]);

  useEffect(() => {
    ContactGoalAPI.getContactGoal({ id: hashId })
      .then((x) =>
        setGoalWeekValues({
          income: checkProperty(x?.data?.goalIncomeWeek),
          outcome: checkProperty(x?.data?.goalOutcomeWeek),
        })
      )
      .finally(() => setIsFetchingUserData(false));

    ContactGoalAPI.getContactGoal({ id: hashId })
      .then((x) =>
        setGoalMonthValues({
          income: checkProperty(x?.data?.goalIncomeMonth),
          outcome: checkProperty(x?.data?.goalOutcomeMonth),
        })
      )
      .finally(() => setIsFetchingUserData(false));
  }, [refreshContactGoal]);

  return {
    financeData,
    userData,
    isFetchingUserData,
    financeDataWeek,
    financeDataLastWeek,
    isFetchingData,
    urlRoutes,
    setGoalWeek,
    goalWeek,
    setGoalMonth,
    goalMonth,
    setDate,
    setType,
    type,
    date,
    changeDateFilter,
    goalWeekValues,
    goalMonthValues,
    financeDataMonth,
    financeDataLastMonth,
    clearGoalWeekValues,
    createGoalWeekValues,
    createGoalMonthValues,
    msg,
    loader,
    mySalesCardData,
    incomesCardData,
  };
};
