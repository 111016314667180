import { StyleSheet, Button, Text, View } from "react-native";
import React, { useState } from "react";
import {
  IconButton,
  Modal,
  Portal,
  Snackbar,
  TextInput,
} from "react-native-paper";
import { globalClasses, globalStyles } from "../../styles";
import { Calendar, LocaleConfig } from "react-native-calendars";
import { TouchableOpacity } from "react-native-gesture-handler";
import Ionicons from "react-native-vector-icons/Ionicons";
import ModalTemplate from "../ModalTemplate";
TextInput;
const ModifyDebt = ({
  show,
  dismiss,
  type,
  setNewDate,
  countryCurrency,
  currencyFormat,
  setPayDebt,
  newDate,
  payDebt,
  holder,
  action,
  amount,
  expireDate,
  showSnackbar,
}) => {
  let content = {
    title: "",
    description: "",
    input: "",
  };
  if (type === "abonar") {
    content.title = "Abono/parcial";
    content.description = `Escribe el valor del abono a tu deuda con ${holder} por valor total de ${countryCurrency}${currencyFormat.format(
      amount
    )}`;
    content.input = "valor";
  } else {
    content.title = "Reprogramar";
    content.description = `Escribe la nueva fecha de vencimiento de tu deuda con ${holder} la cual vencía el ${expireDate}  `;
    content.input = "fecha";
  }
  const [selected, setSelected] = useState("");
  const [placeholder, setPlaceHolder] = useState("$000.000");
  return (
    <ModalTemplate visible={show}>
      <IconButton
        icon="close"
        onPress={dismiss}
        iconColor={globalStyles.colors.darkGrey}
        size={25}
      />
      <View style={{ textAlign: "center" }}>
        <Text style={[globalClasses.cardTitle, { fontWeight: "700" }]}>
          {content.title}
        </Text>
        <Text
          style={[
            globalClasses.cardTitlePeriod,
            { marginTop: globalStyles.shape.betweenSpace },
          ]}
        >
          {content.description}
        </Text>
      </View>
      {type === "abonar" ? (
        <View style={styles.inputContainer}>
          <TextInput
            placeholder={placeholder}
            placeholderTextColor="#a6a6a6"
            value={payDebt}
            onChangeText={setPayDebt}
            style={{ width: "80%", textAlign: "center" }}
            textAlign="center"
            left={<TextInput.Affix text="$" />}
            keyboardType="numeric"
            onFocus={() => setPlaceHolder("")}
            onBlur={() => setPlaceHolder("$000.000")}
          />
          <View
            style={{
              marginTop: globalStyles.shape.betweenSpace,
              width: "60%",
            }}
          >
            <Button
              title="Abonar"
              color={globalStyles.colors.primary}
              onPress={() => {
                action();
                dismiss();
                showSnackbar();
              }}
            />
          </View>
        </View>
      ) : (
        <View>
          <Calendar
            onDayPress={(day) => {
              setSelected(day.dateString);
              setNewDate(day.dateString);
            }}
            markedDates={{
              [selected]: {
                selected: true,
                disableTouchEvent: true,
                selectedDotColor: "green",
              },
            }}
          />
          <View
            style={{
              marginTop: globalStyles.shape.betweenSpace,
              width: "100%",
            }}
          >
            <Button
              title="Reprogramar"
              color={globalStyles.colors.primary}
              onPress={() => {
                action();
                dismiss();
                setPayDebt(null);
                showSnackbar();
              }}
            />
          </View>
        </View>
      )}
    </ModalTemplate>
  );
};

export default ModifyDebt;

const styles = StyleSheet.create({
  container: {
    marginHorizontal: 20,
    padding: globalStyles.shape.horizontal,
    backgroundColor: globalStyles.colors.white,
    borderRadius: 10,
  },
  inputContainer: {
    alignItems: "center",
    marginTop: globalStyles.shape.betweenSpace,
  },

  redButton: {
    borderColor: globalStyles.colors.red,
    borderWidth: 2,
    flex: 1,
    justifyContent: "center",
    textAlign: "center",
    borderRadius: 10,
    width: "100%",
    paddingVertical: globalStyles.shape.internSpaceY,
    paddingHorizontal: 20,
  },
  greenButton: {
    backgroundColor: globalStyles.colors.primary,
    borderWidth: 2,
    borderColor: globalStyles.colors.primary,
    width: "100%",
    flex: 1,
    justifyContent: "center",
    textAlign: "center",
    borderRadius: 10,
    paddingVertical: globalStyles.shape.internSpaceY,
    paddingHorizontal: 20,
  },
});
