import React, { useState, useEffect, useMemo } from "react";
import { WebHookUserInfoAPI } from "../../api/src/info-user";
import { DashboardAPI } from "../../api/src/dashboard";
import { UserInfoAPI } from "../../api/src/user-info";
import { GetConvertDateAPI } from "../../api/src/get-convert-date";
import { ReportWebHookAPI } from "../../api/src/report-data";
import {
  checkProperty,
  getHashId,
  getDate,
  getCurrencySymbol,
  changeDateFilter,
} from "../../helpers/src";
import { useDispatch, useSelector } from "react-redux";
import { addReportUrl } from "../../../store/slices/reportUrl";
import { addUserData } from "../../../store/slices/userData";
import { RewindAPI } from "../../api/src/rewind-data";
import { openReport } from "../../analytics/src/open-report";
import { visitPageMain } from "../../analytics/src/open-report";
import { addModalShow } from "../../../store/slices/tutorialModal";
import axios from "axios";
import _ from "lodash";

export const useMainData = ({ navigation }) => {
  const paramsId =
    window.location.pathname !== "/"
      ? window.location.pathname
      : `/u/${localStorage.getItem("hashId")}`;
  const paramsDate = window.location.search || "?from=Hoy";
  const [dateS] = useSelector((state) => state.date).slice(-1);
  const [modal] = useSelector((state) => state.modalShow);
  const currencyFormat = Intl.NumberFormat("es-CO");

  const [isFetchingData, setIsFetchingData] = useState(false);
  const [isFetchingUserData, setIsFetchingUserData] = useState(false);
  const [isSendReportWebHook, setIsSendReportWebHook] = useState(false);
  const [financeData, setFinanceData] = useState({});
  const [userData, setUserData] = useState({});
  const [rewindData, setRewindData] = useState({});
  const [showRewind, setShowRewind] = useState(true);
  const [date, setDate] = useState(getDate(paramsDate));
  const [convertDate, setConverDate] = useState("");

  const validPeriod = ["Hoy", "Semana", "Mes"];

  const [urlRoutes, setUrlRoute] = useState("");
  const [openCTA, setOpenCTA] = useState(false);
  const [msg, setMsg] = useState("");
  const [loader, setLoader] = useState(false);

  const [showModal, setShowModal] = useState(false);
  const hashId = getHashId(paramsId);
  const dispatch = useDispatch();

  const setReportUrl = (url) => {
    dispatch(addReportUrl(url));
  };

  const setUserDataS = (data) => {
    dispatch(addUserData(data));
  };

  const setShowTutorial = (value) => {
    dispatch(addModalShow(value));
  };

  const modalTiming = () => {
    setTimeout(() => {
      setShowModal(true);
    }, 10000);
  };

  const dateOptions = {
    year: "numeric",
    month: "long",
    day: "numeric",
  };
  const getNavIndex = window.location.search.indexOf("nav=") + 4;
  const nav = window.location.search
    .substring(getNavIndex, paramsDate.length)
    .replace("%C3%A1", "á");

  useEffect(() => {
    nav && navigation.navigate(nav, { screen: nav, nav: true });
  }, [nav]);
  useEffect(async () => {
    modalTiming();
    setIsFetchingData(true);

    setUrlRoute(paramsId + paramsDate);

    DashboardAPI.getUserFinancesData({
      id: hashId,
      from: dateS?.text || date,
    })
      .then((x) => {
        setFinanceData({
          total_actual_balance: checkProperty(x?.total_actual_balance),
          total_income: checkProperty(x?.total_income),
          total_balance: checkProperty(x?.total_balance),
          total_outcome: checkProperty(x?.total_outcome),
          growth_margin: Math.round(x?.growth_margin / 1000) || 0,
          transactions: checkProperty(x?.transactions),
          most_amount_week: checkProperty(x?.most_amount_week),
          most_amount_month: checkProperty(x?.most_amount_month),
          total_amount_owe: checkProperty(x?.total_amount_owe),
          total_amount_debt: checkProperty(x?.total_amount_debt),
          debts: checkProperty(x?.debts),
        });
      })
      .finally(() => setIsFetchingData(false));

    if (!localStorage.getItem("hashId")) {
      setShowTutorial(true);
    }

    if (window.location.pathname !== "/") {
      localStorage.setItem("hashId", hashId);
    }

    UserInfoAPI.getInfo({ id: hashId })
      .then((x) => {
        setUserData({
          name: checkProperty(x?.contact?.attributes?.name),
          countryCurrency: getCurrencySymbol(
            checkProperty(x?.contact?.attributes?.countryCode)
          ),
          userMembership: checkProperty(x?.contact?.attributes?.userMembership),
          purposeUse: checkProperty(x?.contact?.attributes?.purposeUse),
          phone: checkProperty(x?.contact?.attributes?.phone),
          id: checkProperty(x?.contact?.id),
          userType: checkProperty(x?.contact?.attributes?.userType),
        });

        if (!validPeriod.includes(date)) {
          GetConvertDateAPI.getConvertDate({
            text: date,
            phone: checkProperty(x?.contact?.attributes?.phone),
          }).then((x) => {
            const date = new Date(x?.data);
            date.setDate(date.getDate() + 1);

            const convert = date.toLocaleDateString("es-ES", dateOptions);
            setConverDate(convert);
          });
        }
      })
      .finally(() => setIsFetchingUserData(false));

    axios
      .post(
        "https://qentaz-notification-server.herokuapp.com/report-message-notification",
        {
          id: hashId,
          from: date,
        }
      )
      .then((x) => {
        setMsg(x?.data?.data?.balanceMsg);
      })
      .finally(() => setIsFetchingData(false));

    setTimeout(() => {
      setLoader(true);
    }, 2000);
  }, [date]);

  useEffect(() => {
    if (userData) {
      const contactPhone = userData?.phone?.replace("+", "");
      const contactid = userData?.id;
      try {
        (function () {
          window.satismeter =
            window.satismeter ||
            function () {
              (window.satismeter.q = window.satismeter.q || []).push(arguments);
            };
          window.satismeter.l = 1 * new Date();
          var script = document.createElement("script");
          var parent = document.getElementsByTagName("script")[0].parentNode;
          script.async = 1;
          script.src = "https://app.satismeter.com/satismeter.js";
          parent.appendChild(script);
        })();

        window.satismeter({
          writeKey: "NVSS2RZLQ6cU4pwe",
          userId: contactPhone,
          contact: contactid,
        });
      } catch (err) {
        setError(err);
      }
    }
  }, [userData]);

  useEffect(() => {
    RewindAPI.getRewindFinancesData({
      id: hashId,
    })
      .then((x) => {
        if (x?.amount_transactions) {
          setRewindData({
            amount_transactions: checkProperty(x?.amount_transactions),
            amount_income: checkProperty(x?.amount_income),
            amount_outcome: checkProperty(x?.amount_outcome),
            total_income: checkProperty(x?.total_income),
            total_outcome: checkProperty(x?.total_outcome),
            most_profit_day: checkProperty(x?.most_profit_day),
            most_loss_day: checkProperty(x?.most_loss_day),
            most_profit_month: checkProperty(x?.most_profit_month),
            most_loss_month: checkProperty(x?.most_loss_month),
            personality_type: checkProperty(x?.personality_type),
          });
        } else {
          setShowRewind(false);
        }
      })
      .finally(() => setIsFetchingData(false));
  }, []);

  useEffect(() => {
    if (modal) {
      if (!_.isEmpty(financeData) && !_.isEmpty(userData)) {
        DashboardAPI.getUserFinancesData({
          id: hashId,
          from: dateS?.text,
        })
          .then((x) => {
            if (x?.transactions?.length) {
              const parseTransactions =
                dateS?.text !== "year"
                  ? x?.transactions?.map(({ id, attributes }) => ({
                      id,
                      attributes: {
                        ...attributes,
                        type:
                          attributes?.type === "income" ? "Ingreso" : "Gasto",
                        amount: currencyFormat.format(attributes?.amount),
                      },
                    }))
                  : x?.transactions?.map((t) => ({
                      id: t.id,
                      attributes: {
                        description: t.description,
                        date: t?.date,
                        type: t?.type === "income" ? "Ingreso" : "Gasto",
                        amount: currencyFormat.format(t?.amount),
                      },
                    }));

              ReportWebHookAPI.postReportWebHook({
                phone: userData.phone,
                period: dateS?.text === "year" ? "Año" : dateS?.text,
                txs: parseTransactions,
              })
                .then((x) => {
                  setReportUrl(checkProperty(x?.url));
                })
                .finally(() => setIsSendReportWebHook(false));
            }
          })
          .finally(() => setIsFetchingData(false));
      }
    }
  }, [modal, dateS?.text]);

  useMemo(() => {
    if (userData.phone) {
      openReport(userData.phone);
      setUserDataS(userData);
    }
  }, [userData.phone]);

  return {
    financeData,
    userData,
    rewindData,
    isFetchingUserData,
    isFetchingData,
    urlRoutes,
    date,
    convertDate,
    isSendReportWebHook,
    currencyFormat,
    showRewind,
    showModal,
    openCTA,
    setOpenCTA,
    setShowModal,
    setDate,
    changeDateFilter,
    msg,
    loader,
  };
};
