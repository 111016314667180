import _ from "lodash";
import React, { useState } from "react";
import Icon from "react-native-vector-icons/Ionicons";
import { SelectList } from "react-native-dropdown-select-list";
import { StyleSheet, Text, TouchableOpacity, View } from "react-native";
import { Divider, IconButton, Snackbar, TextInput } from "react-native-paper";

import Screen from "../../Templates/Screen";
import { useProfileData } from "./UseProfile";
import ProfileCard from "../../Components/ProfileCard";
import { globalClasses, globalStyles } from "../../styles";
import MultiUserForm from "../../Components/Modal/MultiUserForm/MultiUserForm";

/* Pantalla Contenedor Perfil
En esta pantalla el usuario puede
- Modificar su información personal (name,lastName, nickname)
- Cambiar su  proposito de la herramienta (purposeUse)
- Navegar a la pantalla planes para cambiar el suyo
- Agregar su ubicación (countryState, city) solo para Colombia
- Manipular la info con las respectivas funciones */

const Profile = ({ navigation, route }) => {
  const {
    location,
    selectCountryState,
    saveNewInfo,
    updateNewUserInfo,
    setSelectCountryState,
    namesFilter,
    setRefreshUserData,
    userData: {
      name,
      lastName,
      nickname,
      purposeUse,
      countryCode,
      userMembership,
      paidEndPlanDate,
      phone,
      bizName,
      beta,
    },
  } = useProfileData();

  const [showPersonal, setShowPersonal] = useState(false);
  const [showGoal, setShowGoal] = useState(false);
  const [showLocation, setShowLocation] = useState(false);
  const [showBusiness, setShowBusiness] = useState(false);

  const [visible, setVisible] = React.useState(false);

  const onToggleSnackBar = () => setVisible(!visible);

  const [multiUser, setMultiUser] = useState(false);

  const onDismissSnackBar = () => setVisible(false);
  const dateOptions = {
    year: "numeric",
    month: "long",
    day: "numeric",
  };
  const countryStates = _.uniqBy(location, "departamento").map((dep, i) => ({
    key: i,
    value: dep.departamento,
  }));
  const endDayPlan = new Date(paidEndPlanDate).toLocaleDateString(
    "es-ES",
    dateOptions
  );

  const citiesPerCountryState = location.filter(
    (el) => el.departamento === selectCountryState
  );
  const cities = citiesPerCountryState.map((cit, i) => ({
    key: i,
    value: cit.municipio,
  }));

  const purposeArr = [
    { key: "META_GASTOS", value: "Controlar mis gastos" },
    { key: "META_GANANCIA", value: "Entender cuánto gano" },
    { key: "META_DEUDAS", value: "Controlar mis deudas" },
  ];

  let purpose = "";
  purposeUse === "META_GASTOS"
    ? (purpose = "Controlar mis gastos")
    : purposeUse === "META_GANANCIA"
    ? (purpose = "Entender cuánto gano")
    : (purpose = "Controlar mis deudas");

  const users = namesFilter;

  return (
    <>
      <Screen loader={true} route={route} phone={phone}>
        <TouchableOpacity onPress={() => navigation.push("Menu")}>
          <Icon name="arrow-back-outline" size={30} color="#000000" />
        </TouchableOpacity>
        <Text style={globalClasses.breakTitle}>Perfil</Text>
        <ProfileCard
          title="Personal"
          description={name}
          visible={showPersonal}
          onToggleSnackBar={onToggleSnackBar}
          saveNewInfo={saveNewInfo}
          setVisible={setShowPersonal}
        >
          <>
            <View style={{ marginTop: 10 }}>
              <TextInput
                value={name}
                mode="outlined"
                label="Nombre"
                onChangeText={(val) => updateNewUserInfo("name", val)}
                activeOutlineColor={globalStyles.colors.primary}
                theme={{
                  roundness: 10,
                }}
              />
            </View>
            <View style={{ marginTop: 10 }}>
              <TextInput
                value={lastName}
                mode="outlined"
                label="Apellido"
                onChangeText={(val) => updateNewUserInfo("lastName", val)}
                activeOutlineColor={globalStyles.colors.primary}
                theme={{
                  roundness: 10,
                }}
              />
            </View>
            <View style={{ marginTop: 10 }}>
              <TextInput
                value={nickname}
                mode="outlined"
                label="¿Cómo quieres que te llamemos?"
                onChangeText={(val) => updateNewUserInfo("nickname", val)}
                activeOutlineColor={globalStyles.colors.primary}
                theme={{ roundness: 10 }}
              />
            </View>
          </>
        </ProfileCard>
        {beta && (
          <ProfileCard
            visible={showBusiness}
            setVisible={setShowBusiness}
            title="Negocio"
            description={bizName ? bizName : " "}
          >
            <View style={{ marginTop: 10 }}>
              <TextInput
                value={bizName}
                mode="outlined"
                label="Nombre del negocio"
                onChangeText={(val) => updateNewUserInfo("bizName", val)}
                activeOutlineColor={globalStyles.colors.primary}
                theme={{
                  roundness: 10,
                }}
              />
            </View>
          </ProfileCard>
        )}
        <ProfileCard
          title="¿Cuál es tu meta financiera?"
          description="Actualmente tienes esta:"
          purpose={purpose}
          visible={showGoal}
          setVisible={setShowGoal}
          onToggleSnackBar={onToggleSnackBar}
          saveNewInfo={saveNewInfo}
        >
          <View style={{ marginTop: 8 }}>
            <SelectList
              placeholder="Selecciona tu meta"
              setSelected={(key) => updateNewUserInfo("purposeUse", key)}
              data={purposeArr}
              save="key"
              search={false}
            />
          </View>
        </ProfileCard>
        <View style={globalClasses.card}>
          <View style={globalClasses.spacedRow}>
            <View style={{}}>
              <Text style={globalClasses.smallTitle}>Tipo de Plan</Text>

              <Text>
                {userMembership === "basic" ? "Básico" : userMembership}
              </Text>
            </View>
            {userMembership === "basic" ? (
              <TouchableOpacity
                style={{ justifyContent: "center" }}
                onPress={() => navigation.navigate("Planes")}
              >
                <Text
                  style={[
                    globalClasses.smallTitle,
                    {
                      color: globalStyles.colors.primary,
                      textAlign: "center",
                    },
                  ]}
                >
                  Mejorar mi plan
                </Text>
              </TouchableOpacity>
            ) : (
              <View style={{ textAlign: "right" }}>
                <Text style={globalClasses.smallTitle}>
                  Fecha de vencimiento
                </Text>
                <Text>Vence el {endDayPlan}</Text>
              </View>
            )}
          </View>
        </View>
        {countryCode === "CO" && (
          <ProfileCard
            title="¿Dónde vives?"
            description="Con esta información podremos personalizar tu experiencia en Qentaz"
            visible={showLocation}
            setVisible={setShowLocation}
            onToggleSnackBar={onToggleSnackBar}
            saveNewInfo={saveNewInfo}
          >
            <>
              <View style={{ marginTop: 10 }}>
                <SelectList
                  placeholder="Elige tu Departamento"
                  setSelected={(val) => {
                    updateNewUserInfo("countryState", val);
                    setSelectCountryState(val);
                  }}
                  data={countryStates}
                  save="value"
                />
              </View>
              <View style={{ marginTop: 10 }}>
                <SelectList
                  placeholder="Elige tu Ciudad"
                  setSelected={(val) => updateNewUserInfo("city", val)}
                  data={cities}
                  save="value"
                />
              </View>
            </>
          </ProfileCard>
        )}
        {beta && (
          <View style={globalClasses.card}>
            {/* <View style={[globalClasses.spacedRow, { marginBottom: 4 }]}>
              <Text style={globalClasses.smallTitle}>Equipo</Text>
              <TouchableOpacity onPress={() => setMultiUser(true)}>
                <Text
                  style={[
                    globalClasses.smallTitle,
                    { color: globalStyles.colors.primary, textAlign: "center" },
                  ]}
                >
                  Agregar
                </Text>
              </TouchableOpacity>
            </View> */}
            <Text>Personas que pueden registrar información de tu negocio</Text>
            {users.map((user) => (
              <>
                <View
                  style={[
                    globalClasses.spacedRow,
                    { alignItems: "center", marginVertical: 8 },
                  ]}
                >
                  <View>
                    <View style={styles.centeredRow}>
                      <Text>{user.name}</Text>
                      <View
                        style={[
                          styles.badge,
                          user.user_role === "admin"
                            ? styles.admin
                            : styles.member,
                        ]}
                      >
                        <Text
                          style={[
                            {
                              fontSize: 12,
                              fontWeight: "600",
                              color:
                                user.user_role === "admin"
                                  ? globalStyles.colors.primary
                                  : globalStyles.colors.blue,
                            },
                          ]}
                        >
                          {user.user_role}
                        </Text>
                      </View>
                    </View>
                    {!user.approved && (
                      <Text
                        style={[
                          globalClasses.caption,
                          { color: globalStyles.colors.orange },
                        ]}
                      >
                        ¡Usuario pendiente por confirmar!
                      </Text>
                    )}
                  </View>
                  <View style={styles.centeredRow}>
                    <TouchableOpacity onPress={() => setMultiUser(true)}>
                      <Text style={globalClasses.smallTitle}>Editar</Text>
                    </TouchableOpacity>
                    <IconButton
                      icon="close"
                      iconColor={globalStyles.colors.red}
                      size={20}
                      style={{ margin: 0 }}
                      // onPress={() => console.log('Pressed')}
                    />
                  </View>
                </View>
                <Divider />
                <MultiUserForm
                  show={multiUser}
                  dismiss={() => setMultiUser(false)}
                  userInfo={user}
                  setRefreshUserData={setRefreshUserData}
                />
              </>
            ))}
          </View>
        )}
      </Screen>
      <MultiUserForm
        show={multiUser}
        dismiss={() => setMultiUser(false)}
        userInfo={{}}
        setRefreshUserData={setRefreshUserData}
      />
      <Snackbar
        duration={5000}
        style={{ backgroundColor: "rgba(255,255,255,1)" }}
        visible={visible}
        onDismiss={onDismissSnackBar}
      >
        <View style={{ flexDirection: "row" }}>
          <View style={{ padding: 5 }}>
            <Icon
              name="checkmark-circle"
              size={30}
              color={globalStyles.colors.primary}
            />
          </View>
          <View>
            <Text style={globalClasses.smallTitle}>¡Listo!</Text>
            <Text>¡Tu información ha sido actualizada!</Text>
          </View>
        </View>
      </Snackbar>
    </>
  );
};

export default Profile;

const styles = StyleSheet.create({
  badge: {
    marginLeft: 8,
    borderWidth: 2,
    borderRadius: 20,
    paddingVertical: 3,
    paddingHorizontal: 6,
  },
  admin: {
    borderColor: globalStyles.colors.primary,
    backgroundColor: globalStyles.colors.lightPositive,
  },
  member: {
    borderColor: globalStyles.colors.blue,
    backgroundColor: globalStyles.colors.lightBlue,
  },
  centeredRow: { flexDirection: "row", alignItems: "center" },
});
