import { P } from "@expo/html-elements";

export const analyticTypeEvents = {
  openReport: "ReporteAbierto",
  closeReport: "ReporteCerrado",
  visitPage: {
    event: "PaginaVisitada",
    options: {
      main: "INICIO",
      summary: "ANALISIS",
      movements: "MOVIMIENTOS",
      debts: "DEUDAS",
      menu: "MENU",
      plans: "PLANES",
      checkout: "CHECKOUT",
      reffered: "REFERIDOS",
      profile: "PERFIL",
      learn: "APRENDE",
      changePlan: "PLANES",
    },
  },
  selectButton: {
    event: "BotonSeleccionado",
    options: {
      movementsBar: "Barra Movimientos",
      periodBar: "Barra Periodo",
      changeDate: "Cambio Fecha",
      debts: "Deudas",
      menuMovements: "Menu Movimientos",
      editGoals: "Editar Meta",
      debtMenu: "Menu Deudas",
      menuPlan: "Menu Planes",
      plans: "Plans",
      GENERAL: "General",
      NEGOCIO: "Negocios",
      PERSONAL: "Personal",
      day: "Dia",
      week: "Semana",
      month: "Mes",
      delete: "Eliminar",
    },
  },
  checkout: {
    event: "InicioCheckOut",
    options: {
      nequi: "Nequi",
      pse: "PSE",
      stripe: "Tarjeta de credito",
    },
  },

  openTutorial: "TutorialAbierto",
};
