import { StyleSheet, Text, View, Linking } from "react-native";
import React from "react";
import { ActivityIndicator, IconButton } from "react-native-paper";
import { globalClasses, globalStyles } from "../../styles";
import { TouchableOpacity } from "react-native-gesture-handler";

import ModalTemplate from "../ModalTemplate";

const CheckoutConfirmation = ({ show, dismiss, bankLink }) => {
  return (
    <ModalTemplate visible={show}>
      <IconButton
        icon="close"
        onPress={dismiss}
        iconColor={globalStyles.colors.darkGrey}
        size={25}
      />

      <View style={{ textAlign: "center" }}>
        <Text style={[globalClasses.cardTitle, { fontWeight: "700" }]}>
          Vamos a tu banco!
        </Text>
        <Text style={[globalClasses.cardTitlePeriod, { marginTop: 10 }]}>
          Te redigiremos a tu entidad bancaria para que puedas completar el pago
        </Text>
      </View>
      <View
        style={{
          marginTop: globalStyles.shape.betweenSpace,
          width: "100%",
        }}
      >
        <TouchableOpacity
          onPress={() => {
            Linking.openURL(bankLink);
            dismiss();
          }}
          style={{ justifyContent: "center" }}
          disabled={!bankLink ? true : false}
        >
          <View style={styles.greenButton}>
            <Text
              style={[
                globalClasses.smallTitle,
                { color: globalStyles.colors.white },
              ]}
            >
              {!bankLink ? (
                <ActivityIndicator animating={true} color="white" />
              ) : (
                "Continuar"
              )}{" "}
            </Text>
          </View>
        </TouchableOpacity>
      </View>
    </ModalTemplate>
  );
};

export default CheckoutConfirmation;

const styles = StyleSheet.create({
  container: {
    marginHorizontal: 20,
    padding: globalStyles.shape.horizontal,
    backgroundColor: globalStyles.colors.white,
    borderRadius: 10,
  },
  redButton: {
    borderColor: globalStyles.colors.red,
    borderWidth: 2,
    flex: 1,
    justifyContent: "center",
    textAlign: "center",
    borderRadius: 10,
    width: "100%",
    paddingVertical: globalStyles.shape.internSpaceY,
    paddingHorizontal: 20,
  },
  greenButton: {
    backgroundColor: globalStyles.colors.primary,
    borderWidth: 2,
    borderColor: globalStyles.colors.primary,
    width: "100%",
    flex: 1,
    justifyContent: "center",
    textAlign: "center",
    borderRadius: 10,
    paddingVertical: globalStyles.shape.internSpaceY,
    paddingHorizontal: 20,
  },
});
