import { Text, TouchableOpacity, View } from "react-native";
import React from "react";
import Ionicon from "react-native-vector-icons/Ionicons";
import {
  visitPageProfile,
  visitPageReferred,
  visitPageChangePlan,
  buttonSelect,
  visitPageLearn,
} from "../analytics/src/open-report";
import { globalClasses, globalStyles } from "../styles";

const MenuButton = ({
  icon,
  title,
  screen,
  navigation,
  link,
  contactPhone,
  type,
}) => {
  return (
    <View>
      {screen ? (
        <TouchableOpacity
          onPress={async () => {
            if (screen) {
              if (screen === "Perfil") {
                await visitPageProfile(contactPhone, {
                  type: "button",
                  previous_screen: "Menu",
                });
              }
              if (screen === "Planes") {
                await visitPageChangePlan(contactPhone, {
                  type: "button",
                  previous_screen: "Menu",
                });
              }
              if (screen === "Referidos") {
                await visitPageReferred(contactPhone, {
                  type: "button",
                  previous_screen: "Menu",
                });
              }
              if (screen === "Aprende") {
                await visitPageLearn(contactPhone, {
                  type: "button",
                  previous_screen: "Menu",
                });
              }
            } else {
              await buttonSelect(contactPhone, {
                page: type,
                type: "button",
                previous_screen: "Menu",
              });
            }
            navigation.navigate(screen, { nav: true });
          }}
        >
          <View style={{ flexDirection: "row", marginTop: 8 }}>
            <View style={{ padding: 5 }}>
              <Ionicon
                name={icon}
                size={30}
                color={globalStyles.colors.primary}
              />
            </View>
            <Text
              style={[globalClasses.cardTitle, { marginTop: 5, marginLeft: 8 }]}
            >
              {title}
            </Text>
          </View>
        </TouchableOpacity>
      ) : (
        <a href={link} target="_blank" style={{ textDecoration: "none" }}>
          <View style={{ flexDirection: "row", marginTop: 8 }}>
            <View style={{ padding: 5 }}>
              <Ionicon
                name={icon}
                size={30}
                color={globalStyles.colors.primary}
              />
            </View>
            <Text
              style={[globalClasses.cardTitle, { marginTop: 5, marginLeft: 8 }]}
            >
              {title}
            </Text>
          </View>
        </a>
      )}
    </View>
  );
};

export default MenuButton;
