import { StyleSheet, Text, View } from "react-native";
import React from "react";
import { Badge } from "react-native-paper";
import { globalClasses, globalStyles } from "../styles";

const ProBadge = () => {
  return (
    <Badge
      style={{
        backgroundColor: globalStyles.colors.secondary,
        marginLeft: 5,
        marginBottom: 5,
      }}
    >
      <Text style={globalClasses.smallTitle}>PLUS</Text>
    </Badge>
  );
};

export default ProBadge;

const styles = StyleSheet.create({});
