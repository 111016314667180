import React from "react";
import { View } from "react-native";
import { List } from "react-native-paper";
import { globalClasses, globalStyles } from "../styles";
import DebtsList from "./DebtsList";

const DebtsDropdown = ({
  type,
  countryCurrency,
  debtsNotification,
  activateDeleteSnackbar,
  activateModifySnackbar,
  deleteDebt,
  updateDebt,
  payDebt,
  setPayDebt,
  setNewDate,
  newDate,
  phone,
}) => {
  const [expanded, setExpanded] = React.useState(true);

  const handlePress = () => setExpanded(!expanded);
  return (
    <View style={{ marginTop: globalStyles.shape.sectionSpace }}>
      <List.Accordion
        title={type === "vencidas" ? "⚠ ¡Vencidas!" : "🚨 ¡Vencen pronto!"}
        expanded={expanded}
        onPress={handlePress}
        style={{
          backgroundColor:
            type === "vencidas"
              ? globalStyles.colors.red
              : globalStyles.colors.secondary,
        }}
        titleStyle={
          type === "vencidas"
            ? [globalClasses.smallTitle, { color: globalStyles.colors.white }]
            : globalClasses.smallTitle
        }
        theme={{ colors: { primary: "#fff" } }}
      >
        {debtsNotification.map(({ id, attributes: x }) => (
          <DebtsList
            amount={x.amount}
            active={x.active}
            deleteDebt={deleteDebt}
            updateDebt={updateDebt}
            payDebt={payDebt}
            setPayDebt={setPayDebt}
            setNewDate={setNewDate}
            newDate={newDate}
            type={x.type}
            paymentDate={x?.account_type[0]?.payment_date}
            payFinaldate={x?.payFinaldate}
            holder={x?.holder}
            description={x?.description}
            currentAmount={x?.currentAmount}
            countryCurrency={countryCurrency}
            debtId={id}
            activateDeleteSnackbar={activateDeleteSnackbar}
            activateModifySnackbar={activateModifySnackbar}
            phone={phone}
          />
        ))}{" "}
      </List.Accordion>
    </View>
  );
};

export default DebtsDropdown;
