import { StyleSheet, Text, View } from "react-native";
import React from "react";
import { globalClasses, globalStyles } from "../styles";
import { Divider, IconButton, Menu, ProgressBar } from "react-native-paper";
import { currencyFormat } from "../helpers/src";
import ModifyDebt from "./Modal/ModifyDebt";
import DeleteDebt from "./Modal/DeleteDebt";
import { buttonSelect } from "../analytics/src/open-report";
const DebtsList = ({
  amount,
  active,
  type,
  paymentDate,
  payFinaldate,
  payDebt,
  setPayDebt,
  newDate,
  setNewDate,
  holder,
  description,
  deleteDebt,
  updateDebt,
  debtId,
  currentAmount,
  activateDeleteSnackbar,
  activateModifySnackbar,
  countryCurrency,
  phone,
}) => {
  const dateOptions = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  };

  const currencyFormat = Intl.NumberFormat("es-CO");

  const [visible, setVisible] = React.useState(false);
  const [showModal, setShowModal] = React.useState(false);
  const [typeModal, setTypeModal] = React.useState("");
  const [showDelete, setShowDelete] = React.useState(false);

  const setAnalytic = async (button) => {
    await buttonSelect(phone, {
      type: "Menu Deudas",
      button,
    });
  };

  const openMenu = async () => {
    setVisible(true);
  };

  const closeMenu = () => setVisible(false);

  let time = new Date(active ? paymentDate : payFinaldate);

  time.setDate(time.getDate() + 1);

  return (
    <>
      <View style={styles.listContainer}>
        <View style={globalClasses.spacedRow}>
          <View style={styles.contentContainer}>
            <View style={[globalClasses.spacedRow, { width: "100%" }]}>
              <Text
                style={{
                  color: !active
                    ? globalStyles.colors.primary
                    : type === "cuenta-x-cobrar"
                    ? globalStyles.colors.primary
                    : globalStyles.colors.red,
                }}
              >
                {!active
                  ? type === "cuenta-x-cobrar"
                    ? "COBRADA"
                    : "PAGADA"
                  : type === "cuenta-x-cobrar"
                  ? "POR COBRAR"
                  : "POR PAGAR"}
              </Text>
              <Text style={globalClasses.caption}>
                {time.toLocaleDateString("es-ES", dateOptions)}
              </Text>
            </View>
            <View style={[globalClasses.spacedRow]}>
              <Text style={globalClasses.cardTitle}>
                {countryCurrency}{" "}
                {currencyFormat.format(amount - currentAmount)}
              </Text>
              <Text style={globalClasses.cardTitle}>{holder} </Text>
            </View>
            <Text style={globalClasses.caption}>{description} </Text>
          </View>
          {active ? (
            <Menu
              visible={visible}
              onDismiss={closeMenu}
              contentStyle={{ marginTop: -180, marginRight: 50 }}
              anchor={
                <IconButton
                  onPress={openMenu}
                  icon="dots-horizontal-circle"
                  iconColor={globalStyles.colors.darkGrey}
                  size={25}
                />
              }
            >
              <Menu.Item
                leadingIcon="check-circle-outline"
                onPress={async () => {
                  updateDebt({
                    id: debtId,
                    active: false,
                    currentAmount: 0,
                    newDate: new Date().toISOString().slice(0, 10),
                    type,
                    holder,
                    typeAction: "Complete",
                  });

                  await setAnalytic("Completada");
                  closeMenu();
                }}
                title="Completada"
              />
              <Menu.Item
                leadingIcon="pause-circle-outline"
                onPress={() => {
                  setTypeModal("abonar");
                  setShowModal(true);
                  setVisible(false);
                  closeMenu();
                }}
                title="Abono/parcial"
              />
              <Menu.Item
                leadingIcon="calendar-clock"
                onPress={() => {
                  setTypeModal("reschedule");
                  setShowModal(true);
                  setVisible(false);
                  closeMenu();
                }}
                title="Reprogramar"
              />
              <Divider />
              <Menu.Item
                leadingIcon="delete"
                onPress={() => {
                  setShowDelete(true);
                  closeMenu();
                }}
                title="Eliminar"
                titleStyle={{ color: globalStyles.colors.red }}
              />
            </Menu>
          ) : (
            ""
          )}
        </View>
        {currentAmount && active ? (
          <View style={globalClasses.spacedRow}>
            <Text
              style={[
                globalClasses.caption,
                { width: "25%", textAlign: "left" },
              ]}
            >
              {countryCurrency} {currencyFormat.format(currentAmount)}
            </Text>
            <View style={{ width: "50%" }}>
              <ProgressBar
                style={{
                  marginTop: 7,
                  backgroundColor: globalStyles.colors.darkGrey,
                }}
                color={globalStyles.colors.primary}
                progress={currentAmount ? currentAmount / amount : 0}
              />
            </View>
            <Text
              style={[
                globalClasses.caption,
                { width: "25%", textAlign: "right" },
              ]}
            >
              {countryCurrency}
              {currencyFormat.format(amount)}
            </Text>
          </View>
        ) : (
          ""
        )}
      </View>
      <Divider />
      <ModifyDebt
        show={showModal}
        currencyFormat={currencyFormat}
        action={async () => {
          typeModal === "abonar"
            ? (updateDebt({
                id: debtId,
                currentAmount:
                  Number(payDebt) +
                  Number(String(currentAmount)?.replace(/\D/g, "")),
                amount,
                type,
                holder,
                typeAction: "Pay",
              }),
              await setAnalytic("Abonar"))
            : (updateDebt({
                id: debtId,
                newPaymentDate: newDate,
                type: String(type).substring(9, type?.length),
                holder,
                typeAction: "Reschedule",
              }),
              await setAnalytic("Reagendar"));
        }}
        setNewDate={setNewDate}
        newDate={newDate}
        setPayDebt={setPayDebt}
        payDebt={payDebt}
        expireDate={paymentDate}
        countryCurrency={countryCurrency}
        dismiss={() => setShowModal(false)}
        type={typeModal}
        holder={holder}
        amount={amount}
        showSnackbar={activateModifySnackbar}
      />

      <DeleteDebt
        show={showDelete}
        dismiss={() => setShowDelete(false)}
        deleteAction={async () => {
          deleteDebt(debtId, type, holder);
          setShowDelete(false);
          await setAnalytic("Eliminar");
          activateDeleteSnackbar();
        }}
      />
    </>
  );
};

export default DebtsList;

const styles = StyleSheet.create({
  listContainer: {
    marginVertical: 5,
    paddingHorizontal: globalStyles.shape.betweenSpace,
    paddingVertical: globalStyles.shape.internSpaceY,
  },

  contentContainer: {
    marginTop: 5,
    width: "90%",
  },
});
