import axios from "axios";

export function setup() {
  const baseURL = "/";

  const apiClient = axios.create({
    baseURL,
    headers: {
      "x-auth-token":
        "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyb2xlcyI6WyJhZG1pbiIsImVkaXRvciIsInZpZXdlciJdLCJpYXQiOjE2Njk3NDQ3NjF9.POiSGVbRxCFZWveqpRPzsvVC4MqbNKviSGhUMOSVVvM",
    },
  });

  return { apiClient };
}

const {
  apiClient: { get, post, put, patch, delete: destroy },
} = setup();

export { get, post, put, patch, destroy };
