import { BASE_URL, GET_CONTACT_GOAL, UPDATE_CONTACT_GOAL } from "../../config";
import { getData, postData } from "../api-call";

const getContactGoal = ({ id }: { id: string }) => {
  const requestUrl = `${BASE_URL}${GET_CONTACT_GOAL}/${id}`;

  return getData(requestUrl);
};

const updateContactGoal = (data) => {
  const requestUrl = `${BASE_URL}${UPDATE_CONTACT_GOAL}`;
  return postData(requestUrl, data);
};

export const ContactGoalAPI = {
  getContactGoal,
  updateContactGoal,
};
