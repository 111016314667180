import { postData } from "../api/src/api-call";
import { NOTIFICATION_CHATWOOT, SEND_MESSAGE } from "./config";

const sendMessageEvent = (phone, body) => {
  const requestUrl = SEND_MESSAGE;
  const parsePhone = phone.replace("+", "");

  const data = {
    phone: parsePhone,
    body,
  };
  postData(requestUrl, data);
};

export const sendEventChatwood = (phone, title, body) => {
  const requestUrl = NOTIFICATION_CHATWOOT;
  const parsePhone = phone.replace("+", "");

  const data = {
    phone: parsePhone,
    title: "REPORT NOTIFICATION",
    body: title,
  };

  sendMessageEvent(phone, body);

  postData(requestUrl, data);
};
