"use strict";
import { StyleSheet } from "react-native";

export const globalClasses = StyleSheet.create({
  breakTitle: {
    fontSize: 28,
    fontWeight: "700",
  },
  sectionTitle: {
    fontSize: 18,
    fontWeight: "700",
    marginTop: 20,
    color: "#000",
  },
  cardTitle: { fontSize: 20, fontWeight: "600", color: "#000" },
  cardTitlePeriod: { fontSize: 15, fontWeight: "600", color: "#000" },
  smallTitle: { fontSize: 14, fontWeight: "700", color: "#000" },
  bodyText: { fontSize: 14, fontWeight: "400", color: "#000" },
  caption: { fontSize: 12, fontWeight: "500", color: "#707070" },
  shadow: {
    elevation: 8,
    shadowRadius: 10,
    shadowColor: "#787777",
    shadowOffset: { width: 0, height: 1 },
    shadowOpacity: 0.25,
  },
  spacedRow: { flexDirection: "row", justifyContent: "space-between" },
  card: {
    width: "100%",
    borderRadius: 10,
    paddingHorizontal: 15,
    paddingVertical: 10,
    marginTop: 20,
    backgroundColor: "#f6f8f7",
  },
  screenContainer: {
    marginHorizontal: 14,
    marginTop: 5,
    marginBottom: 20,
    padding: 5,
  },
});

export const globalStyles = {
  colors: {
    darkGrey: "#707070",
    lightGrey: "#F2F2F2",
    lightNegative: "#FAEAEA",
    lightPositive: "#EAFAF4",
    lightSecondary: "#FAF7EA",
    lightOrange: "#fff2e5",
    lightBlue: "#ceecff",
    blue: "#205374",
    orange: "#F7931A",
    primary: "#30ce88",
    red: "#C00000",
    secondary: "#FFD30A",
    white: "#F6F8F7",
  },
  shape: {
    horizontal: 14, //padding Horizontal de pantalla
    sectionSpace: 20, //margin top entre secciones
    sectionSpaceBottom: 50, //margin top entre secciones
    betweenSpace: 10, //margin top entre elementos de la misma sección
    internSpaceX: 15, //padding horizontal de tarjetas
    internSpaceY: 5, //padding vertical de tarjetas
  },
};
