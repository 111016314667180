import React, { useState, useEffect } from "react";
import axios from "axios";
import _ from "lodash";
import Stripe from "stripe";
import { StripeLinkAPI } from "../../api/src/stripe";
import { UpdateMembershipAPI } from "../../api/src/update-membership";
const stripe = new Stripe(
  "sk_test_51LyzhSDcuqqCJd0FvB5pHrlTnD0L8F9FXdBiEkx5KQfkHPKZzUczU9LLTy3fgSXqldlzUfPvxKapH8eXqhGgbSwJ00fRyeaFs9"
);

const useCheckout = () => {
  const [banks, setBanks] = useState([]);
  const [bankLink, setBankLink] = useState("");
  const [userId, setUserId] = useState("");
  const [stripeLink, setStripeLink] = useState("");

  const updateUserPlanMemberShip = async ({
    id,
    paidInitialPlanDate,
    paidEndPlanDate,
    userMembership,
  }) => {
    UpdateMembershipAPI.updateMembership({
      id,
      paidInitialPlanDate,
      paidEndPlanDate,
      userMembership,
    });
  };

  const generatePSITx = async (
    contactFullname,
    phone,
    value,
    plan,
    financialInstitution,
    codePrice
  ) => {
    const token = await generateToken();
    const referenceId = generateReferenceId(28);
    const parseValue = Number(String(value).replace(/\D/g, "") + "00");

    const data = {
      acceptance_token: token,
      amount_in_cents: parseValue,
      currency: "COP",
      customer_email: `${phone}@qentaz.com`,
      customer_data: {
        phone_number: phone,
        full_name: contactFullname,
      },
      payment_method: {
        type: "PSE",
        user_type: 0,
        user_legal_id_type: "CC",
        user_legal_id: userId,
        financial_institution_code: String(
          financialInstitution.replace(/\D/g, "")
        ),
        payment_description: "Qentaz - Plan Diseñado para ti",
      },
      metadata: {
        phone,
        plan,
      },
      reference: codePrice?.toUpperCase() + "-" + referenceId,
    };

    const txResponse = await axios.post(
      "https://production.wompi.co/v1/transactions",
      data,
      {
        headers: {
          Accept: "application/json",
          Authorization: "Bearer prv_prod_ruPLrCuxPJX8GPSV3NLUOFUpIiM0JTVT",
        },
      }
    );

    const txResponseDataId = _.get(txResponse, "data.data");
    const txId = _.get(txResponseDataId, "id");

    setTimeout(async () => {
      const psiStatus = await axios.get(
        `https://production.wompi.co/v1/transactions/${txId}`,
        {
          headers: {
            Accept: "*/*",
            Authorization: "Bearer prv_prod_ruPLrCuxPJX8GPSV3NLUOFUpIiM0JTVT",
            "Content-Type": "application/json",
          },
        }
      );

      const txResponseData = _.get(psiStatus, "data.data");

      if (txResponseData) {
        const txPaymentUrl = _.get(
          txResponseData,
          "payment_method.extra.async_payment_url"
        );

        setBankLink(txPaymentUrl);
        const status = _.get(txResponseData, "status");
      }
    }, 5000);
  };

  const stipePayment = async (planType, price, phone, codePrice) => {
    const parseContact = phone?.replace("+", "");
    StripeLinkAPI.getStripeLink({
      planType,
      price,
      phone: parseContact,
      codePrice: codePrice?.toUpperCase(),
    }).then((x) => {
      setStripeLink(x?.data?.url);
    });
  };

  const nequiPayment = async (plan, value, phone, codePrice) => {
    const contactPhone = phone.slice(3);
    const parseValue = Number(String(value).replace(/\D/g, "") + "00");
    const token = await generateToken();
    const referenceId = generateReferenceId(28);

    const data = {
      acceptance_token: token,
      amount_in_cents: parseValue,
      currency: "COP",
      customer_email: `${contactPhone}@qentaz.com`,
      payment_method: {
        type: "NEQUI",
        phone_number: contactPhone,
      },
      metadata: {
        phone,
        plan,
      },
      reference: codePrice?.toUpperCase() + "-" + referenceId,
    };

    await axios.post("https://production.wompi.co/v1/transactions", data, {
      headers: {
        Accept: "*/*",
        Authorization: "Bearer prv_prod_ruPLrCuxPJX8GPSV3NLUOFUpIiM0JTVT",
        "Content-Type": "application/json",
      },
    });
  };

  useEffect(async () => {
    const listBanks = await getFinancialInstitution();
    setBanks(listBanks);
  }, []);

  return {
    banks,
    generatePSITx,
    nequiPayment,
    stipePayment,
    bankLink,
    setUserId,
    userId,
    stripeLink,
    setStripeLink,
  };
};
const generateReferenceId = (length) => {
  var result = "";
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

const generateToken = async () => {
  const getToken = await axios.get(
    "https://production.wompi.co/v1/merchants/pub_prod_BWT44KgL8Z6jk5nj1wkEJI7lCMwtJyCT"
  );

  if (getToken) {
    const {
      presigned_acceptance: { acceptance_token },
    } = getToken.data.data;

    return acceptance_token;
  }
};

const getFinancialInstitution = async () => {
  const {
    data: { data: financiaInstitutions },
  } = await axios.get(
    "https://production.wompi.co/v1/pse/financial_institutions",
    {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer pub_prod_BWT44KgL8Z6jk5nj1wkEJI7lCMwtJyCT",
      },
    }
  );

  return financiaInstitutions;
};

export { useCheckout };
