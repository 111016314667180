import { createSlice } from "@reduxjs/toolkit";

export const dateSlice = createSlice({
  name: "date",
  initialState: [],
  reducers: {
    addDate: (state, action) => {
      const date = {
        text: action.payload,
      };
      state.push(date);
    },
  },
});

export const { addDate } = dateSlice.actions;

export default dateSlice.reducer;
