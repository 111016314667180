import { TextInput } from "react-native-paper";
import React, { useEffect, useState } from "react";
import Icon from "react-native-vector-icons/Ionicons";
import DropDownPicker from "react-native-dropdown-picker";
import { TouchableOpacity } from "react-native-gesture-handler";
import { Image, Modal, StyleSheet, Text, View } from "react-native";

import { useCheckout } from "./useCheckout";
import DropdownCard from "../../Components/DropdownCard";
import { globalClasses, globalStyles } from "../../styles";
import {
  slackNotificationCheckout,
  visitCheckout,
  visitPageCheckout,
} from "../../analytics/src/open-report";
import CheckoutConfirmation from "../../Components/Modal/CheckoutConfirmation";

/*Pantalla contenedor Chekout
El usurio puede elegir la opción que se ajuste mejor para
pagar el plan seleccionado anteriormente 
Tiene como parametros:
- route: Opción que eligió el usuario anteriormente en la pantalla Planes con 
el precio y la info del usuario
- navigation: elemento que permite la navegación entre pantallas o, en este 
caso, devolverse
- price: Precio que eligió el usuario
- planType: Plan que eligió el usuario (Plus o pro)
- contactPhone: Número telefónico del usuario*/
const Checkout = ({
  route,
  navigation,
  price2,
  planType2,
  contactCountry2,
  contactPhone2,
}) => {
  const {
    banks,
    generatePSITx,
    bankLink,
    setUserId,
    userId,
    stripeLink,
    stipePayment,
    setStripeLink,
    nequiPayment,
  } = useCheckout();

  const [open, setOpen] = useState(false);
  const [value, setValue] = useState(null);
  const [items, setItems] = useState([]);

  const setAnalytic = async () => {
    await visitPageCheckout(route?.params?.contactPhone, {
      previous_screen: "Planes",
      type: `${route?.params?.planType}/${route?.params?.valueDescription}`,
    });
  };

  useEffect(() => {
    if (route?.params?.contactPhone) setAnalytic();
  }, [route?.params?.contactPhone]);

  useEffect(() => {
    const listBanks = banks?.map((x) => ({
      label: x?.financial_institution_name,
      value: x?.financial_institution_code,
    }));

    listBanks.shift();
    setItems(listBanks);
  }, [banks]);

  const parsePhone =
    route?.params?.contactPhone?.replace("+", "") ||
    contactPhone2?.replace("+", "");

  const [dropNequi, setDropNequi] = useState(false);
  const isNequi = async () => {
    setDropNequi(!dropNequi);

    if (!dropNequi) {
      await visitCheckout(route?.params?.contactPhone, {
        type: "Nequi",
      });
      await slackNotificationCheckout(
        route?.params?.price,
        route?.params?.contactPhone
      );
    }
  };
  const [dropPse, setDropPse] = useState(false);
  const isPse = async () => {
    setDropPse(!dropPse);
    if (!dropPse) {
      await visitCheckout(route?.params?.contactPhone, {
        type: "PSE",
      });

      await slackNotificationCheckout(
        route?.params?.price,
        route?.params?.contactPhone
      );
    }
  };
  const [dropCard, setDropCard] = useState(false);
  const isCard = async () => {
    setDropCard(!dropCard);
    if (!dropCard) {
      await visitCheckout(route?.params?.contactPhone, {
        type: "Tarjeta de credito",
      });

      await slackNotificationCheckout(
        route?.params?.price,
        route?.params?.contactPhone
      );
    }
  };

  const [showDropDown, setShowDropDown] = useState(false);
  const [gender, setGender] = useState("");
  const [showMultiSelectDropDown, setShowMultiSelectDropDown] = useState(false);
  const [showCheckoutModal, setShowCheckoutModal] = useState(false);
  const [showCheckoutCard, setShowCheckoutCard] = useState(false);

  const [modalVisible, setModalVisible] = useState(false);

  const dateOptions = {
    year: "numeric",
    month: "long",
    day: "numeric",
  };
  const timePeriod =
    route?.params?.valueDescription === "Mensual"
      ? 30
      : route?.params?.valueDescription === "Trimestral"
      ? 90
      : 180;
  const startDate = new Date()?.toLocaleDateString("es-ES", dateOptions);
  const endDate = new Date();
  endDate.setDate(endDate.getDate() + timePeriod);

  return (
    <View style={globalClasses.screenContainer}>
      <TouchableOpacity
        style={{ alignSelf: "flex-begin", marginTop: -5, position: "absolute" }}
        onPress={() => navigation.push("Plans")}
      >
        <Icon name="arrow-back-outline" size={30} color="#000000" />
      </TouchableOpacity>
      <Text style={styles.checkoutTitle}>Llegó el momento de pagar</Text>
      <View
        style={[
          globalClasses.card,
          globalClasses.shadow,
          globalClasses.spacedRow,
        ]}
      >
        <Image
          source={{
            uri: `https://qentaz.s3.amazonaws.com/public/Reporte/QZ_${
              route?.params?.planType === "PLUS" ? "Plus" : "Pro"
            }.png`,
          }}
          resizeMode="contain"
          style={{ width: 80, height: 40 }}
        />

        <View>
          <Text>
            <Text style={globalClasses.smallTitle}>Fecha inicio:</Text>{" "}
            {startDate}{" "}
          </Text>
          <Text>
            <Text style={globalClasses.smallTitle}>Fecha fin:</Text>{" "}
            {endDate?.toLocaleDateString("es-ES", dateOptions)}
          </Text>
          <Text>
            <Text style={globalClasses.smallTitle}>Precio: </Text>{" "}
            {route?.params?.price}
          </Text>
        </View>
      </View>
      <Text style={globalClasses.sectionTitle}>
        Selecciona tu medio de pago
      </Text>
      {route?.params?.contactCountry === "CO" ? (
        <>
          <DropdownCard
            visible={dropNequi}
            setVisible={async () => isNequi()}
            bank={{
              uri: "https://qentaz.s3.amazonaws.com/public/Reporte/Nequi.png",
            }}
            title="Paga usando Nequi"
            children={
              <View>
                <Text>
                  ¡Has elegido pagar desde Nequi! 💸
                  <Text style={globalClasses.smallTitle}>
                    ¿Puedes confirmarnos si tienes la aplicación de Nequi en el
                    mismo celular con el que llevarás tus finanzas?
                  </Text>
                </Text>
                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "space-evenly",
                    marginTop: globalStyles.shape.betweenSpace,
                  }}
                >
                  <TouchableOpacity onPress={async () => isNequi}>
                    <View
                      style={[
                        styles.button,
                        {
                          borderWidth: 1,
                          borderColor: globalStyles.colors.red,
                        },
                      ]}
                    >
                      <Text
                        style={[
                          globalClasses.smallTitle,
                          { color: globalStyles.colors.red },
                        ]}
                      >
                        No
                      </Text>
                    </View>
                  </TouchableOpacity>
                  <TouchableOpacity
                    onPress={() => {
                      isNequi();
                      setModalVisible(true);
                      nequiPayment(
                        route?.params?.planType,
                        route?.params?.price,
                        route?.params?.contactPhone,
                        route?.params?.codePrice
                      );
                    }}
                  >
                    <View
                      style={[
                        styles.button,
                        { backgroundColor: globalStyles.colors.primary },
                      ]}
                    >
                      <Text
                        style={[
                          globalClasses.smallTitle,
                          { color: globalStyles.colors.white },
                        ]}
                      >
                        ¡Sí!
                      </Text>
                    </View>
                  </TouchableOpacity>
                </View>
              </View>
            }
          />
          <DropdownCard
            visible={dropPse}
            setVisible={async () => isPse()}
            bank={{
              uri: "https://qentaz.s3.amazonaws.com/public/Reporte/PSE.png",
            }}
            title="Paga desde tu cuenta bancaria"
            children={
              <View>
                <Text>
                  ¡Has elegido pagar desde tu cuenta bancaria! 💸
                  <Text style={globalClasses.smallTitle}>
                    ¿Cuál es el banco con el que realizarás el pago de tu plan?
                  </Text>
                </Text>
                <View>
                  <Text
                    style={[globalClasses.breakTitle, { textAlign: "left" }]}
                  >
                    <Text
                      style={[globalClasses.smallTitle, { textAlign: "left" }]}
                    >
                      Banco a pagar
                    </Text>

                    <DropDownPicker
                      open={open}
                      value={value}
                      items={items}
                      setOpen={setOpen}
                      setValue={setValue}
                      setItems={setItems}
                      maxHeight={100}
                      placeholder="Seleccione un banco"
                      style={{
                        marginTop: globalStyles.shape.betweenSpace,
                        width: 250,
                      }}
                      containerStyle={{
                        position: "relative",
                        backgroundColor: globalStyles.colors.white,
                      }}
                      textStyle={{ fontSize: 12 }}
                    />
                    <View style={styles.inputContainer}>
                      <Text
                        style={[
                          globalClasses.smallTitle,
                          { textAlign: "left" },
                        ]}
                      >
                        Numero de identidad{" "}
                      </Text>

                      <TextInput
                        dense
                        placeholder={"Ingrese numero de identidad"}
                        onChangeText={setUserId}
                        placeholderTextColor="black"
                        value={userId}
                        style={{
                          textAlign: "left",
                          borderRadius: 5,
                          marginTop: 20,
                          marginBottom: 20,
                          backgroundColor: "white",
                          borderColor: "black",
                          borderWidth: 0.5,
                          fontWeight: "bold",
                          fontSize: 12,
                          width: 250,
                        }}
                        textAlign="left"
                        left={<TextInput.Affix text="#" />}
                        keyboardType="numeric"
                      />
                    </View>
                  </Text>
                  <TouchableOpacity
                    disabled={value && userId ? false : true}
                    onPress={() => {
                      generatePSITx(
                        route?.params?.contactFullname,
                        parsePhone,
                        route?.params?.price || price2,
                        route?.params?.planType || planType2,
                        value,
                        route?.params?.codePrice
                      );
                      setShowCheckoutModal(true);
                    }}
                  >
                    <View
                      style={[
                        styles.button,
                        {
                          backgroundColor:
                            value && userId
                              ? globalStyles.colors.primary
                              : globalStyles.colors.darkGrey,
                          alignSelf: "center",
                          marginTop: 10,
                          width: 250,
                        },
                      ]}
                    >
                      <Text
                        style={[
                          globalClasses.smallTitle,
                          { color: globalStyles.colors.white },
                        ]}
                      >
                        ¡Continuar!
                      </Text>
                    </View>
                  </TouchableOpacity>
                </View>
              </View>
            }
          />
          <DropdownCard
            visible={dropCard}
            setVisible={isCard}
            bank={{
              uri: "https://qentaz.s3.amazonaws.com/public/Reporte/TC.png",
            }}
            title="Paga con tarjeta de crédito"
            children={
              <View>
                <Text>
                  ¡Has elegido pagar con tarjeta de crédito! 💸
                  <Text style={globalClasses.smallTitle}>
                    Completa la siguiente información:
                  </Text>
                </Text>
                <TouchableOpacity
                  onPress={() => {
                    stipePayment(
                      route?.params?.planType,
                      route?.params?.price,
                      route?.params?.contactPhone,
                      route?.params?.codePrice
                    );
                  }}
                >
                  <View
                    style={[
                      styles.button,
                      {
                        width: 150,
                        backgroundColor: globalStyles.colors.primary,
                        alignSelf: "center",
                      },
                    ]}
                  >
                    <Text
                      style={[
                        globalClasses.smallTitle,
                        { color: globalStyles.colors.white },
                      ]}
                    >
                      ¡Ir a pagar!
                    </Text>
                  </View>
                </TouchableOpacity>
              </View>
            }
          />
        </>
      ) : (
        <DropdownCard
          visible={dropCard}
          setVisible={isCard}
          bank={{
            uri: "https://qentaz.s3.amazonaws.com/public/Reporte/TC.png",
          }}
          title="Paga con tarjeta de crédito"
          children={
            <View>
              <Text>
                ¡Has elegido pagar con tarjeta de crédito! 💸
                <Text style={globalClasses.smallTitle}>
                  Completa la siguiente información:
                </Text>
              </Text>
              <TouchableOpacity
                onPress={() => {
                  stipePayment(
                    route?.params?.planType,
                    route?.params?.price,
                    route?.params?.contactPhone,
                    route?.params?.codePrice
                  );
                }}
              >
                <View
                  style={[
                    styles.button,
                    {
                      width: 150,
                      backgroundColor: globalStyles.colors.primary,
                      alignSelf: "center",
                    },
                  ]}
                >
                  <Text
                    style={[
                      globalClasses.smallTitle,
                      { color: globalStyles.colors.white },
                    ]}
                  >
                    ¡Ir a pagar!
                  </Text>
                </View>
              </TouchableOpacity>
            </View>
          }
        />
      )}
      <Text style={styles.caption}>
        Actualmente solo tenemos disponible para tu país la opción de pago con
        tarjeta. Seguimos trabajando para ofrecerte nuevas opciones
      </Text>

      <CheckoutConfirmation
        show={showCheckoutModal}
        dismiss={() => setShowCheckoutModal(false)}
        bankLink={bankLink}
      />
      {
        <CheckoutConfirmation
          show={stripeLink ? true : false}
          dismiss={() => setStripeLink("")}
          bankLink={stripeLink}
        />
      }
      <Modal
        animationType="slide"
        transparent={true}
        visible={modalVisible}
        onRequestClose={() => {
          setModalVisible(!modalVisible);
        }}
      >
        <View
          style={{
            flex: 1,
            justifyContent: "center",
            backgroundColor: "rgba(0,0,0,0.6)",
            paddingHorizontal: globalStyles.shape.horizontal,
          }}
        >
          <TouchableOpacity
            onPress={() => {
              setModalVisible(!modalVisible);
            }}
          >
            <Icon name="close" size={20} color={globalStyles.colors.white} />
          </TouchableOpacity>
          <Image
            source={{
              uri: "https://qentaz.s3.amazonaws.com/public/Reporte/PagoNequi.jpg",
            }}
            resizeMode="contain"
            style={{ width: "100%", height: 500, marginTop: 15 }}
          />
        </View>
      </Modal>
    </View>
  );
};

export default Checkout;

const styles = StyleSheet.create({
  cardContainer: {
    width: "100%",
    borderRadius: 10,
    marginTop: 20,
    backgroundColor: "#f6f8f7",
  },
  cardTitle: {
    width: "100%",
    borderRadius: 10,
    paddingHorizontal: 15,
    paddingVertical: 10,
    backgroundColor: "#f6f8f7",
  },
  inputContainer: {
    marginTop: 15,
    textAlign: "center",
  },
  button: {
    borderRadius: 7,
    textAlign: "center",
    width: 120,
    padding: 5,
    marginTop: globalStyles.shape.betweenSpace,
  },
  caption: {
    color: globalStyles.colors.darkGrey,
    textAlign: "center",
    marginTop: globalStyles.shape.sectionSpace,
    fontWeight: "700",
  },
  checkoutTitle: {
    textAlign: "center",
    fontSize: 20,
    fontWeight: "600",
    color: "#000",
  },
});
