import { getAllInfoByISO } from "iso-country-currency";
import {
  PERIOD_TIME_QUERY,
  HASH_ID_QUERY,
  KEY_VALUE_CURRENCY,
  DEFAULT_CURRENCY_SYMBOL,
} from "../../Screens/config";

import getCountryCodeByPhone  from "phone";

const checkProperty = (value) => value || "";
const getHashId = (path) => path.replace(HASH_ID_QUERY, "");

const getDate = (path) => path.replace(PERIOD_TIME_QUERY, "");

const getCurrencySymbol = (code) => {
  const { symbol } = getAllInfoByISO(code, KEY_VALUE_CURRENCY) || {
    symbol: DEFAULT_CURRENCY_SYMBOL,
  };

  return symbol.slice(-1);
};

const getDateParse = (date) => {
  const dateFormat = { month: "short", day: "numeric" };
  dateFormat.timeZone = "UTC";
  const parseDate = new Date(date).toLocaleDateString(undefined, dateFormat);
  return parseDate;
};

const changeDateFilter = (date) => {
  window.location.href = window.location.pathname + PERIOD_TIME_QUERY + date;
};
//ToDo: Need to change to user currencyCode

const currencyFormat = (value, countryCode) => {
  const currency = new Intl.NumberFormat("es-CO", {
    style: "currency",
    currency: countryCode || "COP",
    currencySign: "standard",
    currencyDisplay: "narrowSymbol",
  });

  return currency.format(value);
};

const numberParser = (value) => {
  const parseNumber = String(value);
  const sign = parseNumber.indexOf("-") !== -1 ? "-" : "";

  return Number(sign + parseNumber.replace(/\D/g, ""));
};

const getCountryCode = (phone) => {
  const { countryIso3 } = getCountryCodeByPhone(phone);

  return countryIso3;
};

export {
  checkProperty,
  getHashId,
  getDate,
  getCurrencySymbol,
  changeDateFilter,
  getDateParse,
  currencyFormat,
  numberParser,
  getCountryCode,
};
