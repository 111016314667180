import { Image, StyleSheet, View, Text } from "react-native";
import React, { useEffect, useState } from "react";
import { Appbar } from "react-native-paper";

//Image
import logo from "../../assets/Qentaz.png";
import { globalClasses, globalStyles } from "../styles";
import { UserInfoAPI } from "../api/src/user-info";
import { checkProperty, getHashId } from "../helpers/src";

const AppHeader = ({ title, navigation }) => {
  const [name, setName] = useState("");
  const paramsId = window.location.pathname || localStorage.getItem("hashId");
  useEffect(() => {
    const hashId = getHashId(paramsId);
    UserInfoAPI.getInfo({ id: hashId }).then((x) =>
      setName(checkProperty(x?.contact?.attributes?.name))
    );
  }, [name]);
  /* Componente de Header de la aplicación donde se ve el
   nombre de la pantalla en la que se encuentra el usuario */
  return (
    <Appbar.Header style={{ backgroundColor: "#30ce88" }}>
      <Appbar.Content
        title={
          title === "Movimientos" ? (
            <View style={[globalClasses.spacedRow, { width: "100%" }]}>
              <View>
                <Text
                  style={[
                    globalClasses.smallTitle,
                    { color: globalStyles.colors.white },
                  ]}
                >
                  ¡Hola! 👋
                </Text>
                <Text
                  style={[
                    globalClasses.cardTitle,
                    { color: globalStyles.colors.white, marginTop: -8 },
                  ]}
                >
                  {name}
                </Text>
                {/* <Text
                style={[
                  globalClasses.smallTitle,
                  { color: globalStyles.colors.white },
                ]}
              >
                Tu plan:{" "}
                {userMembership === "basic" ? "básico" : userMembership}
              </Text> */}
              </View>
              <View
                style={{ marginRight: 8, marginTop: 4, alignSelf: "center" }}
              >
                <Image
                  source={logo}
                  style={{ height: 35, width: 100 }}
                  resizeMode="contain"
                />
              </View>
            </View>
          ) : (
            title
          )
        }
        titleStyle={{
          fontWeight: "600",
          fontSize: 18,
          color: globalStyles.colors.white,
        }}
      />
    </Appbar.Header>
  );
};

export default AppHeader;

const styles = StyleSheet.create({});
