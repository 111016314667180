import React, { useState, useEffect, useMemo } from "react";

import { DashboardAPI } from "../../api/src/dashboard";
import { UserInfoAPI } from "../../api/src/user-info";
import {
  checkProperty,
  getHashId,
  getDate,
  getCurrencySymbol,
  changeDateFilter,
} from "../../helpers/src";
import { visitPageMovements } from "../../analytics/src/open-report";
import {
  UpdateTransactionAPI,
  DeleteTransactionAPI,
} from "../../api/src/transcations";
import axios from "axios";
import { buttonSelect } from "../../analytics/src/open-report";
import { sendEventChatwood } from "../../events";
import { CHANGE_TRANSACTION, DELETE_TRANSACTION } from "../../events/model";
import { addUserData } from "../../../store/slices/userData";
import { useDispatch, useSelector } from "react-redux";
import { MultiAccountAPI } from "../../api/src/get-multiple-accounts";

export const useTransaction = ({ type }) => {
  const currencyFormat = Intl.NumberFormat("es-CO");

  const [index, setIndex] = useState([]);
  const paramsId = window.location.pathname || localStorage.getItem("hashId");
  const paramsDate = window.location.search || "?from=hoy";
  const [isFetchingData, setIsFetchingData] = useState(false);
  const [isFetchingUserData, setIsFetchingUserData] = useState(false);
  const [isTable, setIsTable] = useState(true);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [searchQuery, setSearchQuery] = React.useState("");

  const [currentDate, setCurrentDate] = useState();

  const [financeData, setFinanceData] = useState({});
  const [userData, setUserData] = useState({});
  const [date, setDate] = useState(getDate(paramsDate));

  const [updateTxType, setUpdateTxType] = useState(false);
  const [msg, setMsg] = useState("");
  const [loader, setLoader] = useState(false);
  const [label, setLabel] = useState(date);
  const [newTxData, setNewTxData] = useState({ type });

  const [namesFilter, setNamesFilter] = useState([]);

  const dispatch = useDispatch();

  const onChangeSearch = (query) => setSearchQuery(query.toLowerCase());

  const updateNewTxInfo = (name, value) => {
    setNewTxData({ ...newTxData, [name]: value });
  };

  const [urlRoutes, setUrlRoute] = useState("");
  const tableVisualisation = () => {
    setIsTable(!isTable);
  };
  const snackBarVisualisation = () => {
    setShowSnackbar(!showSnackbar);
  };

  const setUserDataS = (data) => {
    dispatch(addUserData(data));
  };

  const clearTxInfo = () => {
    setNewTxData({});
  };

  const updateTransaction = (txId, type, description) => {
    UpdateTransactionAPI.updateTransaction({
      txId,
      categoryType: newTxData?.categoryType,
      type: newTxData?.type,
      amount: newTxData?.amount,
      description: newTxData?.description,
      date: newTxData?.date,
    })
      .then((x) => {
        if (x?.data?.data) {
          setUpdateTxType(true);
        }
      })
      .finally(() => {
        setUpdateTxType(false);
        const body = CHANGE_TRANSACTION.body
          .replace("TYPE", type)
          .replace("DESCRIPTION", description);
        sendEventChatwood(userData.phone, CHANGE_TRANSACTION.title, body);
      });
  };

  const deleteTransaction = (txId, type, description) => {
    DeleteTransactionAPI.deleteTransaction({ txId })
      .then((x) => {
        if (x?.data?.data) {
          setUpdateTxType(true);
        }
      })
      .finally(() => {
        setUpdateTxType(false);
        const body = DELETE_TRANSACTION.body
          .replace("TYPE", type)
          .replace("DESCRIPTION", description);
        sendEventChatwood(userData.phone, DELETE_TRANSACTION.title, body);
      });
  };

  useEffect(() => {
    setIsFetchingData(true);
    setUrlRoute(paramsId + paramsDate);
    const hashId = getHashId(paramsId);

    axios
      .post(
        "https://qentaz-notification-server.herokuapp.com/report-message-notification",
        {
          id: hashId,
          from: label,
        }
      )
      .then((x) => setMsg(x?.data?.data?.balanceMsg))
      .finally(() => setIsFetchingData(false));

    DashboardAPI.getUserFinancesData({
      id: hashId,
      from: date,
      type: type,
      members: index,
    })
      .then((x) =>
        setFinanceData({
          transactions: checkProperty(x?.transactions),
          total_balance: checkProperty(x?.total_balance),
          outcome_transactions: checkProperty(x?.outcome_transactions),
          income_transactions: checkProperty(x?.income_transactions),
          total_income: checkProperty(x?.total_income),
          total_outcome: checkProperty(x?.total_outcome),
          weeks_days: checkProperty(x?.weeks_days),
        })
      )
      .finally(() => setIsFetchingData(false));

    UserInfoAPI.getInfo({ id: hashId })
      .then((x) =>
        setUserData({
          countryCurrency: getCurrencySymbol(
            checkProperty(x?.contact?.attributes?.countryCode)
          ),
          phone: checkProperty(x?.contact?.attributes?.phone),
          name: checkProperty(x?.contact?.attributes?.name),
          purposeUse: checkProperty(x?.contact?.attributes?.purposeUse),
          userMembership: checkProperty(x?.contact?.attributes?.userMembership),
          userType: checkProperty(x?.contact?.attributes?.userType),
          userRole: checkProperty(x?.contact?.attributes?.userRole),
          beta: checkProperty(x?.contact?.attributes?.beta),
        })
      )
      .finally(() => setIsFetchingUserData(false));

    MultiAccountAPI.getMultiAccountsUser({ id: hashId }).then((x) => {
      const {
        data: { users },
      } = x;

      users.push({name: userData?.name, phone: userData?.phone?.replace("+",'')})
      setNamesFilter(users);
    });

    setTimeout(() => {
      setLoader(true);
    }, 2000);
  }, [date, updateTxType, index]);

  useMemo(() => {
    if (userData.phone) {
      setUserDataS(userData);
    }
  }, [userData.phone]);

  return {
    financeData,
    userData,
    setCurrentDate,
    currentDate,
    isFetchingUserData,
    index,
    setIndex,
    isFetchingData,
    deleteTransaction,
    urlRoutes,
    date,
    showSnackbar,
    setNamesFilter,
    namesFilter,
    setShowSnackbar,
    paramsDate,
    changeDateFilter,
    setDate,
    onChangeSearch,
    searchQuery,
    isTable,
    tableVisualisation,
    updateTransaction,
    snackBarVisualisation,
    setLabel,
    updateNewTxInfo,
    clearTxInfo,
    newTxData,
    label,
    currencyFormat,
    paramsDate,
    msg,
    loader,
  };
};
