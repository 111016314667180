import { createSlice } from "@reduxjs/toolkit";

export const reportUrlSlice = createSlice({
  name: "reportUrl",
  initialState: [],
  reducers: {
    addReportUrl: (state, action) => {
      const reportUrl = {
        text: action.payload,
      };

      state.push(reportUrl);
    },
    clearReportUrl: (state, _) => {
      state.splice(0, state.length);
    },
  },
});

export const { addReportUrl, clearReportUrl } = reportUrlSlice.actions;

export default reportUrlSlice.reducer;
