import React, { useEffect, useState } from "react";
import ReactStoreIndicator from "react-score-indicator";
import { Text, View, Image, TouchableOpacity, StyleSheet } from "react-native";
import MaterialCommunityIcons from "react-native-vector-icons/MaterialCommunityIcons";

import {
  visitPageSummary,
  visitPageDebts,
} from "../../analytics/src/open-report";
import Screen from "../../Templates/Screen";
import { useClearFinances } from "./useClearFinances";
import AnalysisTabs from "../../Components/AnalysisTabs";
import { globalClasses, globalStyles } from "../../styles";
import ProblemReport from "../../Components/Modal/ProblemReport";
import NotPremiumContainer from "../../Components/NotPremiumContainer";

/*
Contentedor Pantalla Análisis
Pantalla donde se muestran componentes como
- MyIncomesCard: Tarjeta que muestra los ingresos del usuario de forma
 gráfica y resumida la información sobre sus ingresos desde el inicio de uso en Qentaz
 - SalesCard: Tarjeta que muestra el historial de ganancias en el periodo en el que se 
 encuentre
 - GoalsCard: Tarjeta donde se muestra un pronóstico al usuario de acuerdo a sus 
 movimientos 
 - Parte de usuarios similares, donde aparece un puntaje en el que se encuentra el 
 usuario en comparación con los demás del mismo nicho  */
const ClearFinances = ({ navigation, route }) => {
  const {
    financeData: { total_amount_transactions },
    userData: {
      countryCurrency,
      userType,
      phone,
      purposeUse,
      createdAt,
      userMembership,
    },
    financeDataLastWeek,
    financeDataWeek,
    financeDataMonth,
    financeDataLastMonth,
    incomesCardData,
    mySalesCardData,
    setType,
    type,
    setGoalWeek,
    setGoalMonth,
    goalMonth,
    goalWeek,
    goalWeekValues,
    goalMonthValues,
    clearGoalWeekValues,
    createGoalWeekValues,
    createGoalMonthValues,
    loader,
    msg,
  } = useClearFinances();

  const scoreValue = Number(msg?.scoreValue) * 10;
  const userGoals = msg?.goalsValue;
  const setAnalytic = async () => {
    await visitPageSummary(phone, { type: "navigation" });
  };
  useEffect(() => {
    if (phone && !route?.params?.nav) {
      setAnalytic();
    }
  }, [phone]);

  const registerDifferDate = getDifferDate(createdAt);
  const plan = userMembership;
  //problem
  const [problem, setProblem] = useState(false);
  return (
    <>
      {plan !== "basic" &&
      registerDifferDate >= 10 &&
      total_amount_transactions >= 10 ? (
        <Screen loader={loader} route={route} phone={phone}>
          <AnalysisTabs
            registerDifferDate={registerDifferDate}
            total_amount_transactions={total_amount_transactions}
            type={type}
            route={route}
            clearGoalWeekValues={clearGoalWeekValues}
            setType={setType}
            userGoals={userGoals}
            goalWeek={goalWeek}
            navigation={navigation}
            userMembership={userMembership}
            setGoalWeek={setGoalWeek}
            goalWeekValues={goalWeekValues}
            goalMonth={goalMonth}
            setGoalMonth={setGoalMonth}
            goalMonthValues={goalMonthValues}
            goalsIncome={userGoals?.goal_income_week}
            goalsOutcome={userGoals?.goal_outcome_week}
            mySalesCardData={mySalesCardData}
            financeDataMonth={financeDataMonth}
            financeDataLastMonth={financeDataLastMonth}
            incomesCardData={incomesCardData}
            financeDataWeek={financeDataWeek}
            financeDataLastWeek={financeDataLastWeek}
            createGoalWeekValues={createGoalWeekValues}
            createGoalMonthValues={createGoalMonthValues}
            userInfo={{ countryCurrency, userType, phone, purposeUse }}
          />
          <TouchableOpacity
            onPress={async () => {
              navigation.navigate("Deudas", {
                nav: true,
              });
              await visitPageDebts(phone, {
                previous_screen: "Analisis",
                type: "banner",
              });
            }}
          >
            <View
              style={{
                marginTop: globalStyles.shape.sectionSpace,
                borderRadius: 10,
              }}
            >
              <Image
                source={{
                  uri: "https://qentaz.s3.amazonaws.com/public/Reporte/BANNER_Deudas.jpg",
                }}
                resizeMode="contain"
                style={{ width: "100%", height: 170 }}
              />
            </View>{" "}
          </TouchableOpacity>
          <Text style={globalClasses.sectionTitle}>
            {userType === "NEGOCIO"
              ? "Negocios como el mío"
              : "Personas similares a ti"}
          </Text>
          <Text>
            {userType === "NEGOCIO"
              ? "¡Descubre cómo va tu negocio en comparación a otros similares a ti!"
              : "¡Analiza tus finanzas comparadas con otras personas con un comportamiento similar!"}
          </Text>
          <View style={globalClasses.card}>
            <ReactStoreIndicator
              value={scoreValue}
              maxValue={100}
              lineGap={3}
              lineWidth={15}
              textStyle={{ marginBottom: 60 }}
              fadedOpacity={20}
            />
          </View>

          <View style={styles.messageCard}>
            <View style={{ marginRight: 7 }}>
              <MaterialCommunityIcons
                name="creation"
                color={globalStyles.colors.primary}
                size={25}
              />
            </View>
            <View style={{ width: "91%" }}>
              {purposeUse === " META_GANANCIA" ? (
                <Text>
                  Este puntaje indica cómo estás en relación con otras personas
                  que llevan sus finanzas en Qentaz. Si tu puntaje es mayor a
                  50, estás en buena posición, ¡genial! Pero si está por debajo
                  de 50, quizás necesites revisar tus finanzas.
                </Text>
              ) : (
                <Text>
                  Este puntaje indica cómo estás en relación con otros negocios
                  similares al tuyo. Si tu puntaje es mayor a 50, estás en buena
                  posición, ¡genial! Pero si está por debajo de 50, quizás
                  necesites revisar tus finanzas.
                </Text>
              )}
            </View>
          </View>
        </Screen>
      ) : (
        <NotPremiumContainer
          navigation={navigation}
          amountTransactions={total_amount_transactions}
          registerTime={registerDifferDate}
          userMembership={userMembership}
          phone={phone}
        />
      )}
    </>
  );
};

const getDifferDate = (updatedAt) => {
  const lastMovement = new Date(updatedAt).getTime();
  const todayDate = new Date().getTime();
  return Math.floor((todayDate - lastMovement) / (24 * 3600 * 1000));
};
const styles = StyleSheet.create({
  activityContainer: {
    marginTop: 100,
    height: 188,
    width: 188,
    justifyContent: "center",
    marginLeft: "auto",
    marginRight: "auto",
  },
  messageCard: {
    padding: 7,
    backgroundColor: globalStyles.colors.lightPositive,
    flexDirection: "row ",
    marginTop: globalStyles.shape.sectionSpace,
    borderRadius: 10,
  },
});

export default ClearFinances;
