import React, { useState } from "react";
import { Divider } from "react-native-paper";
import { StyleSheet, Text, View } from "react-native";
import Ionicons from "react-native-vector-icons/Ionicons";
import { TouchableOpacity } from "react-native-gesture-handler";

import EditTx from "./Modal/EditTx";
import DeleteTx from "./Modal/DeleteTx";
import { globalClasses, globalStyles } from "../styles";
import { buttonSelect } from "../analytics/src/open-report";

const TransactionsList = ({
  id,
  tx,
  description,
  date,
  amount,
  categoryType,
  countryCurrency,
  updateTransaction,
  deleteTransaction,
  activateSnackbar,
  phone,
  userType,
  userMembership,
  newTxData,
  updateNewTxInfo,
  clearTxInfo,
  activateTx,
  beta,
  name,
  userName,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [visible, setVisible] = useState(false);
  const checkSelectButton = async (button) =>
    await buttonSelect(phone, {
      type: "Menu Movimientos",
      button,
    });

  const currencyFormat = Intl.NumberFormat("es-CO");
  const openMenu = () => {
    setVisible(true);
  };

  const closeMenu = () => setVisible(false);

  //Modal

  const openModal = () => setShowModal(true);
  const hideModal = () => setShowModal(false);
  const closeEdit = () => setEditModal(false);

  const handleDeleteButton = () => {
    openModal();
    closeMenu();
  };
  const dateFormat = new Date(date);
  dateFormat.setDate(dateFormat.getDate() + 1);

  const dateOptions = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  };

  return (
    <>
      <View style={styles.listContainer}>
        <View style={{ marginTop: 5, width: "50%" }}>
          <Text style={globalClasses.smallTitle}>{description}</Text>
          <Text style={globalClasses.caption}>
            {dateFormat.toLocaleDateString("es-ES", dateOptions)}
          </Text>
          {userMembership === "pro" && beta && (
            <Text
              numberOfLines={1}
              style={[
                globalClasses.caption,
                { color: globalStyles.colors.blue, width: 200 },
              ]}
            >
              Por: {name || userName}
            </Text>
          )}
        </View>
        <View
          style={{ flexDirection: "row", alignItems: "center", width: "50%" }}
        >
          <View style={{ textAlign: "right", width: "70%" }}>
            <Text
              style={[
                globalClasses.cardTitle,
                {
                  textAlign: "right",
                  color:
                    tx === "income"
                      ? globalStyles.colors.primary
                      : globalStyles.colors.red,
                },
              ]}
            >
              {tx === "income"
                ? `${countryCurrency} ${currencyFormat.format(amount)}`
                : `-${countryCurrency} ${currencyFormat.format(amount)}`}
            </Text>
            {userType.toLowerCase() === "negocio" && (
              <View
                style={[
                  styles.txBadge,

                  tx === "income"
                    ? {
                        backgroundColor: globalStyles.colors.lightPositive,
                        borderColor: globalStyles.colors.primary,
                      }
                    : {
                        backgroundColor: globalStyles.colors.lightNegative,
                        borderColor: globalStyles.colors.red,
                      },
                ]}
              >
                <Text
                  style={[
                    globalClasses.caption,
                    { color: "#000", margin: 0, padding: 0 },
                  ]}
                >
                  {categoryType.toLowerCase()}
                </Text>
              </View>
            )}
          </View>
          <View style={{ padding: 4, marginLeft: 8 }}>
            <TouchableOpacity onPress={() => setEditModal(true)}>
              <Ionicons
                name="options"
                color={globalStyles.colors.darkGrey}
                size={30}
              />
            </TouchableOpacity>
          </View>
        </View>
      </View>
      <Divider />
      <DeleteTx
        show={showModal}
        dismiss={hideModal}
        deleteAction={async () => {
          const tx1 = tx === "income" ? "Ingreso" : "Gasto";
          deleteTransaction(id, tx1, description);
          await checkSelectButton("Eliminar");
        }}
        showSnackbar={activateSnackbar}
      />
      <EditTx
        show={editModal}
        dismiss={closeEdit}
        date={date}
        value={currencyFormat.format(amount)}
        money={countryCurrency}
        description={description}
        tx={tx}
        categoryType={categoryType}
        userType={userType.toLowerCase()}
        saveAction={updateTransaction}
        newTxData={newTxData}
        updateNewTxInfo={updateNewTxInfo}
        id={id}
        deleteAction={openModal}
        userMembership={userMembership}
        clearTxInfo={clearTxInfo}
        activateTx={activateTx}
      />
    </>
  );
};

export default TransactionsList;

const styles = StyleSheet.create({
  listContainer: {
    marginVertical: 8,
    flexDirection: "row",
    justifyContent: "space-between",
    paddingVertical: globalStyles.shape.internSpaceY,
    alignItems: "center",
  },
  txBadge: {
    borderRadius: 12,
    borderWidth: 1,
    alignSelf: "flex-end",
    marginTop: 4,
    paddingVertical: 1,
    paddingHorizontal: 3,
  },
});
