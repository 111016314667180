import React from "react";
import { StyleSheet, Text, View } from "react-native";
import { TouchableOpacity } from "react-native-gesture-handler";

import ModalTemplate from "../ModalTemplate";
import { globalClasses, globalStyles } from "../../styles";

const DeleteTx = ({ show, dismiss, deleteAction, showSnackbar }) => {
  return (
    <ModalTemplate visible={show}>
      <View style={{ textAlign: "center" }}>
        <Text style={[globalClasses.cardTitle, { fontWeight: "700" }]}>
          ¿Confirmas la eliminación de esta transacción?
        </Text>
        <Text style={[globalClasses.cardTitlePeriod, { marginTop: 10 }]}>
          Ten en cuenta que después de eliminada no se puede recuperar tu
          transacción 😁
        </Text>
      </View>
      <View
        style={[
          globalClasses.spacedRow,
          {
            marginTop: globalStyles.shape.sectionSpace,
          },
        ]}
      >
        <View style={{ width: "47%" }}>
          <TouchableOpacity onPress={dismiss}>
            <View style={styles.redButton}>
              <Text
                style={[
                  globalClasses.smallTitle,
                  { color: globalStyles.colors.red },
                ]}
              >
                ¡No! Cancelar
              </Text>
            </View>
          </TouchableOpacity>
        </View>
        <View style={{ width: "47%" }}>
          <TouchableOpacity
            onPress={() => {
              deleteAction();
              dismiss();
              showSnackbar();
            }}
          >
            <View style={styles.greenButton}>
              <Text
                style={[
                  globalClasses.smallTitle,
                  { color: globalStyles.colors.white },
                ]}
              >
                ¡Si! Eliminar
              </Text>
            </View>
          </TouchableOpacity>
        </View>
      </View>
    </ModalTemplate>
  );
};

export default DeleteTx;

const styles = StyleSheet.create({
  container: {
    marginHorizontal: 20,
    padding: globalStyles.shape.horizontal,
    backgroundColor: globalStyles.colors.white,
    borderRadius: 10,
  },
  redButton: {
    flex: 1,
    width: "100%",
    borderWidth: 2,
    borderRadius: 10,
    textAlign: "center",
    paddingHorizontal: 20,
    justifyContent: "center",
    borderColor: globalStyles.colors.red,
    paddingVertical: globalStyles.shape.internSpaceY,
  },
  greenButton: {
    backgroundColor: globalStyles.colors.primary,
    borderWidth: 2,
    borderColor: globalStyles.colors.primary,
    width: "100%",
    flex: 1,
    justifyContent: "center",
    textAlign: "center",
    borderRadius: 10,
    paddingVertical: globalStyles.shape.internSpaceY,
    paddingHorizontal: 20,
  },
});
