import { BASE_URL, UPDATE_TRANSACTION, DELETE_TRANSACTION } from "../../config";
import { post } from "../base";

const updateTransaction = async ({
  txId,
  categoryType,
  type,
  description,
  amount,
  date,
}) => {
  const data = {
    txId,
    categoryType,
    type,
    description,
    amount,
    date,
  };

  const requestUrl = `${BASE_URL}${UPDATE_TRANSACTION}`;

  return post(requestUrl, data);
};

const deleteTransaction = async ({ txId }) => {
  const data = {
    txId,
  };

  const requestUrl = `${BASE_URL}${DELETE_TRANSACTION}`;

  return post(requestUrl, data);
};

export const UpdateTransactionAPI = {
  updateTransaction,
};

export const DeleteTransactionAPI = {
  deleteTransaction,
};
