import React, { useState } from "react";
import { View } from "react-native";
import { ScrollView } from "react-native-gesture-handler";

import { globalClasses } from "../styles";
import ProblemButton from "../Components/ProblemButton";
import LoadingComponent from "../Components/LoadingComponent";
import ProblemReport from "../Components/Modal/ProblemReport";

const Screen = ({ children, loader, route, phone }) => {
  //problem
  const [problem, setProblem] = useState(false);
  return (
    <>
      <ScrollView>
        <View style={globalClasses.screenContainer}>
          {!loader ? (
            <LoadingComponent />
          ) : (
            <>
              {children}
              {phone && <ProblemButton action={() => setProblem(true)} />}
            </>
          )}
        </View>
      </ScrollView>
      <ProblemReport
        show={problem}
        screen={route.name}
        dismiss={() => setProblem(false)}
        phone={phone}
      />
    </>
  );
};

export default Screen;
