import React, { useState } from "react";
import { useSelector } from "react-redux";
import { StyleSheet } from "react-native";
import { SegmentedButtons } from "react-native-paper";
import { buttonSelect } from "../analytics/src/open-report";

import ChartsCard from "./ChartsCard";
import { globalStyles } from "../styles";

const AnalysisTabs = ({
  type,
  route,
  clearGoalWeekValues,
  setType,
  total_amount_transactions,
  registerDifferDate,
  userInfo,
  goalWeek,
  navigation,
  setGoalWeek,
  goalWeekValues,
  mySalesCardData,
  createGoalMonthValues,
  incomesCardData,
  userGoals,
  financeDataWeek,
  createGoalWeekValues,
  financeDataLastWeek,
  financeDataMonth,
  financeDataLastMonth,
  goalMonth,
  setGoalMonth,
  goalMonthValues,
  userMembership,
}) => {
  const [value, setValue] = useState("semanal");
  const { userData } = useSelector((state) => state.userData);

  return total_amount_transactions >= 20 &&
    registerDifferDate >= 30 &&
    userMembership === "pro" ? (
    <React.Fragment style={{ marginTop: 10 }}>
      <SegmentedButtons
        value={value}
        onValueChange={setValue}
        style={{ width: "100%" }}
        buttons={[
          {
            value: "semanal",
            label: "Semanal",
            style: [
              styles.button,
              {
                borderBottomColor:
                  value === "semanal"
                    ? globalStyles.colors.primary
                    : globalStyles.colors.darkGrey,
              },
            ],
            onPress: async () =>
              await buttonSelect(userData?.phone, {
                type: "Barra Análisis",
                boton: "semanal",
              }),
          },

          {
            value: "mensual",
            label: "Mensual",
            style: [
              styles.button,
              {
                borderBottomColor:
                  value === "mensual"
                    ? globalStyles.colors.primary
                    : globalStyles.colors.darkGrey,
              },
            ],
            onPress: async () =>
              await buttonSelect(userData?.phone, {
                type: "Barra Análisis",
                boton: "mensual",
              }),
          },
        ]}
      />
      {value === "semanal" ? (
        <ChartsCard
          type={type}
          route={route}
          content="semanal"
          setType={setType}
          userInfo={userInfo}
          userGoalsIncome={userGoals?.goal_income_week}
          userGoalsOutcome={userGoals?.goal_outcome_week}
          goalWeek={goalWeek}
          navigation={navigation}
          setGoalWeek={setGoalWeek}
          goalWeekValues={goalWeekValues}
          clearGoalWeekValues={clearGoalWeekValues}
          mySalesCardData={mySalesCardData}
          financeDataWeek={financeDataWeek}
          most_amount_month_incomes={incomesCardData?.most_amount_month_incomes}
          most_amount_month_outcomes={incomesCardData.most_amount_month_outcome}
          financeDataLastWeek={financeDataLastWeek}
          createGoalWeekValues={createGoalWeekValues}
        />
      ) : (
        <ChartsCard
          type={type}
          route={route}
          content="mensual"
          setType={setType}
          userInfo={userInfo}
          goalWeek={goalMonth}
          navigation={navigation}
          clearGoalWeekValues={clearGoalWeekValues}
          setGoalWeek={setGoalMonth}
          goalWeekValues={goalMonthValues}
          mySalesCardData={mySalesCardData}
          incomesCardData={incomesCardData}
          most_amount_month_incomes={incomesCardData.most_amount_year_incomes}
          most_amount_month_outcomes={incomesCardData.most_amount_year_outcomes}
          userGoalsIncome={userGoals?.goal_income_month}
          userGoalsOutcome={userGoals?.goal_outcome_month}
          financeDataWeek={financeDataMonth}
          financeDataLastWeek={financeDataLastMonth}
          createGoalWeekValues={createGoalMonthValues}
        />
      )}
    </React.Fragment>
  ) : (
    <React.Fragment style={{ marginTop: 10 }}>
      <ChartsCard
        type={type}
        route={route}
        content="semanal"
        setType={setType}
        userInfo={userInfo}
        userGoalsIncome={userGoals?.goal_income_week}
        userGoalsOutcome={userGoals?.goal_outcome_week}
        goalWeek={goalWeek}
        navigation={navigation}
        setGoalWeek={setGoalWeek}
        goalWeekValues={goalWeekValues}
        clearGoalWeekValues={clearGoalWeekValues}
        mySalesCardData={mySalesCardData}
        financeDataWeek={financeDataWeek}
        most_amount_month_incomes={incomesCardData?.most_amount_month_incomes}
        most_amount_month_outcomes={incomesCardData.most_amount_month_outcome}
        financeDataLastWeek={financeDataLastWeek}
        createGoalWeekValues={createGoalWeekValues}
      />
    </React.Fragment>
  );
};

export default AnalysisTabs;

const styles = StyleSheet.create({
  button: {
    backgroundColor: "transparent",
    borderWidth: 2,
    borderRadius: 0,
    borderColor: globalStyles.colors.white,
  },
});
