import React, { useState } from "react";
import { IconButton } from "react-native-paper";
import Ionicon from "react-native-vector-icons/Ionicons";
import { Button, Modal, StyleSheet, Text, View } from "react-native";

import SetGoal from "./Modal/SetGoal";
import { globalClasses, globalStyles } from "../styles";
import { buttonSelect } from "../analytics/src/open-report";
import ModalTemplate from "./ModalTemplate";

const GoalsCard = ({
  currentWeekIncome,
  currentWeekOutcome,
  setGoalWeek,
  goalWeekValues,
  setType,
  type,
  goalWeek,
  createGoalWeekValues,
  countryCurrency,
  userGoalsIncome,
  userGoalsOutcome,
  clearGoalWeekValues,
  visible,
  phone,
  close,
  open,
  currencyFormat,
  content,
}) => {
  const progressIncome = Math.abs(
    Number(String(currentWeekIncome).replace(/\D/g, "")) !== 0 &&
      (goalWeekValues?.income || userGoalsIncome)
      ? Number(String(currentWeekIncome).replace(/\D/g, "")) /
          (goalWeekValues?.income || userGoalsIncome)
      : 0
  );

  const progressOutcome = Math.abs(
    Number(String(currentWeekOutcome).replace(/\D/g, "")) !== 0 &&
      (goalWeekValues?.outcome || userGoalsOutcome)
      ? Number(String(currentWeekOutcome).replace(/\D/g, "")) /
          (goalWeekValues?.outcome || userGoalsOutcome)
      : 0
  );

  const setAnalytic = async (button) => {
    await buttonSelect(phone, {
      type: "Editar Metas",
      button: button === "income" ? "ingreso" : "gasto",
    });
  };

  const [updateModal, setUpdateModal] = useState(false);
  const [period, setPeriod] = useState("");

  return (
    <>
      <View style={globalClasses.card}>
        <View
          style={[
            styles.progressContianer,
            { borderColor: globalStyles.colors.primary },
          ]}
        >
          <View
            style={[
              styles.progresBar,
              {
                backgroundColor: "rgba(48,206,136,0.1)",
                width: `${
                  progressIncome > 1
                    ? "+100"
                    : (progressIncome * 100)?.toFixed(0)
                }%`,
              },
            ]}
          ></View>
          <View style={styles.progressInfo}>
            <View style={globalClasses.spacedRow}>
              <View style={{ flexDirection: "row" }}>
                <View style={{ flex: 1, justifyContent: "center" }}>
                  <Ionicon
                    name="trending-up"
                    size={35}
                    color={globalStyles.colors.primary}
                  />
                </View>
                <View style={{ marginLeft: 10 }}>
                  <Text
                    style={[
                      globalClasses.smallTitle,
                      { color: globalStyles.colors.primary },
                    ]}
                  >
                    Ingresos
                  </Text>
                  <Text style={{ color: globalStyles.colors.primary }}>
                    {content === "semanal" ? "Esta semana" : "Este mes"}
                  </Text>
                </View>
              </View>
              <View style={{ textAlign: "right" }}>
                <Text
                  style={[
                    globalClasses.smallTitle,
                    { color: globalStyles.colors.primary },
                  ]}
                >
                  {countryCurrency} {currentWeekIncome}
                </Text>
                <Text style={{ color: globalStyles.colors.primary }}>
                  {progressIncome > 1
                    ? "+100"
                    : (progressIncome * 100)?.toFixed(0)}{" "}
                  %
                </Text>
              </View>
            </View>
          </View>
        </View>
        <View style={[globalClasses.spacedRow, { marginTop: 10 }]}>
          <Text style={globalClasses.caption}>
            Tu meta de ingresos esperada:
          </Text>
          <View style={{ flexDirection: "row" }}>
            <Text
              style={[
                globalClasses.smallTitle,
                { color: globalStyles.colors.darkGrey, marginRight: 10 },
              ]}
            >
              {countryCurrency}
              {currencyFormat.format(
                goalWeekValues?.income || Math.trunc(userGoalsIncome)
              )}
            </Text>
            <IconButton
              icon="pencil"
              onPress={() => {
                open();
                setType("income");
              }}
              iconColor={globalStyles.colors.darkGrey}
              style={{ margin: -10, padding: -10 }}
              size={22}
            />
            <View style={{ marginLeft: 10 }}>
              {goalWeekValues?.income && (
                <IconButton
                  icon="autorenew"
                  onPress={() => {
                    setUpdateModal(true);
                    setPeriod("income");
                  }}
                  iconColor={globalStyles.colors.darkGrey}
                  style={{ margin: -10, padding: -10 }}
                  size={22}
                />
              )}
            </View>
          </View>
        </View>
        <View
          style={[
            styles.progressContianer,
            { marginTop: 15, borderColor: globalStyles.colors.red },
          ]}
        >
          <View
            style={[
              styles.progresBar,
              {
                backgroundColor: globalStyles.colors.lightNegative,
                width: `${
                  progressOutcome > 1
                    ? "+100"
                    : (progressOutcome * 100)?.toFixed(0)
                }%`,
              },
            ]}
          ></View>
          <View style={styles.progressInfo}>
            <View style={globalClasses.spacedRow}>
              <View style={{ flexDirection: "row" }}>
                <View style={{ flex: 1, justifyContent: "center" }}>
                  <Ionicon
                    name="trending-down"
                    size={35}
                    color={globalStyles.colors.red}
                  />
                </View>
                <View style={{ marginLeft: 10 }}>
                  <Text
                    style={[
                      globalClasses.smallTitle,
                      { color: globalStyles.colors.red },
                    ]}
                  >
                    Gastos
                  </Text>
                  <Text style={{ color: globalStyles.colors.red }}>
                    {" "}
                    {content === "semanal" ? "Esta semana" : "Este mes"}
                  </Text>
                </View>
              </View>
              <View style={{ textAlign: "right" }}>
                <Text
                  style={[
                    globalClasses.smallTitle,
                    { color: globalStyles.colors.red },
                  ]}
                >
                  {" "}
                  {countryCurrency} {currentWeekOutcome}
                </Text>
                <Text style={{ color: globalStyles.colors.red }}>
                  {progressOutcome > 1
                    ? "+100"
                    : (progressOutcome * 100)?.toFixed(0)}{" "}
                  %
                </Text>
              </View>
            </View>
          </View>
        </View>
        <View style={[globalClasses.spacedRow, { marginTop: 10 }]}>
          <Text style={globalClasses.caption}>Tu meta de gastos esperada:</Text>
          <View style={{ flexDirection: "row" }}>
            <Text
              style={[
                globalClasses.smallTitle,
                { color: globalStyles.colors.darkGrey, marginRight: 10 },
              ]}
            >
              {countryCurrency}
              {currencyFormat.format(
                goalWeekValues?.outcome || Math.trunc(userGoalsOutcome)
              )}
            </Text>
            <IconButton
              icon="pencil"
              onPress={() => {
                open();
                setType("outcome");
              }}
              iconColor={globalStyles.colors.darkGrey}
              style={{ margin: -10, padding: -10 }}
              size={22}
            />
            <View style={{ marginLeft: 10 }}>
              {goalWeekValues?.outcome && (
                <IconButton
                  icon="autorenew"
                  onPress={() => {
                    setUpdateModal(true);
                    setPeriod("outcome");
                  }}
                  iconColor={globalStyles.colors.darkGrey}
                  style={{ margin: -10, padding: -10 }}
                  size={22}
                />
              )}
            </View>
          </View>
        </View>
      </View>
      <SetGoal
        setGoalWeek={setGoalWeek}
        goalWeek={goalWeek}
        content={content}
        setAnalytic={() => setAnalytic(type)}
        createGoalWeekValues={createGoalWeekValues}
        type={type}
        show={visible}
        dismiss={close}
      />
      <ModalTemplate visible={updateModal}>
        <IconButton
          icon="close"
          onPress={() => {
            setUpdateModal(false);
          }}
          iconColor={globalStyles.colors.darkGrey}
          size={25}
        />
        <View style={{ textAlign: "center" }}>
          <Text style={[globalClasses.cardTitle, { fontWeight: "700" }]}>
            Metas {content}
          </Text>
          <Text style={{ marginTop: 15 }}>
            Tu meta cambiará al valor estimado que hemos calculado según tus
            finanzas. ¡No te preocupes! Puedes cambiarlo cuando quieras
          </Text>
          <View style={{ marginTop: 15, width: "75%", alignSelf: "center" }}>
            <Button
              title="Continuar"
              color={globalStyles.colors.primary}
              onPress={() => {
                const type = content === "semanal" ? "Week" : "Month";
                clearGoalWeekValues(`${period}${type}`);

                setUpdateModal(false);
              }}
            />
          </View>
        </View>
      </ModalTemplate>
    </>
  );
};

export default GoalsCard;

const styles = StyleSheet.create({
  progressContianer: {
    borderRadius: 10,
    borderWidth: 2,
  },
  progresBar: {
    position: "relative",
    height: 50,
    borderRadius: 10,
  },
  progressInfo: {
    width: "100%",
    position: "absolute",
    paddingHorizontal: 8,
    paddingTop: 5,
  },
});
