import { createSlice } from "@reduxjs/toolkit";

export const tabNameSlice = createSlice({
  name: "tabName",
  initialState: {},
  reducers: {
    addTabName: (state, action) => {
      state.tabName = action.payload;
    },
  },
});

export const { addTabName } = tabNameSlice.actions;

export default tabNameSlice.reducer;
