import { View, Text } from "react-native";
import React from "react";
import { createStackNavigator } from "@react-navigation/stack";
import { NavigationContainer } from "@react-navigation/native";
import PaidPlans from "../Screens/PaidPlans/PaidPlans";
import Checkout from "../Screens/Checkout/Checkout";
import Options from "../Screens/Options/Options";
import Referrals from "../Screens/Referrals/Referrals";
import Profile from "../Screens/Profile/Profile";
/*
Componente de Navegador para Planes
que permite la navegación entre pantallas
Trabaja los componentes
- Plans (Pantalla de Planes)
- Checkout (Pantalla de Checkout)

*/
const Stack = createStackNavigator();

const PlansMenu = () => {
  return (
    <Stack.Navigator
      screenOptions={{
        headerShown: false,
        // headerLeft: () => null,
      }}
    >
      <Stack.Screen name="Plans" component={PaidPlans} />
      <Stack.Screen name="Checkout" component={Checkout} />
    </Stack.Navigator>
  );
};

export default PlansMenu;
