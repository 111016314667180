export const STRAPI_URL = "https://strapi.qentaz.com/api";
export const TRANSACTIONS_QUERY = "/transactions";

export const BASE_URL = "https://report-qentaz.herokuapp.com";
export const REPORT_QUERY = "/report-user-data";
export const USER_IN_QUERY = "/user";
export const REWIND_QUERY = "/rewind-report-user-data";
export const UPDATE_TRANSACTION = "/update-transaction";
export const UPDATE_CONTACT_GOAL = "/update-contact-goal";
export const UPDATE_DEBT = "/update-debt";
export const GET_CONTACT_GOAL = "/get-contact-goal";
export const DELETE_TRANSACTION = "/delete-transaction";
export const DELETE_DEBT = "/delete-debt";
export const STRIPE_PAYMENT = "/set-price-product";
export const UPDATE_MEMBERSHIP = "/update-usermembership";
export const SURVEYS = "/set-surveys";
export const UPDATE_CONTACT_DATA = "/update-contact-data";
export const GET_CONVERT_DATE = "/get-convert-date";
export const SEND_CONFIRMATION = "/send-confirmation-request-multiple-accounts";
export const GET_USERS_MULTIPLE_ACCOUNTS = "/get-multiple-account";
export const UPDATE_MULTIPLE_ACCOUNTS_USER = "/update-multiple-account-user";
export const USER_WEB_HOOK =
  "https://hook.us1.make.com/0ioe392rfex4nrmdcgn4qqdkkiiwobf3";
export const REPORT_WEB_HOOK =
  "https://hook.us1.make.com/ldkzyc94e1bepo6vsltdyavynz18qjny";
export const PAID_PLAN_HOOK =
  "https://hook.us1.make.com/r3v3uyv6w1sgidtvglafwc6zmi7brusx";
