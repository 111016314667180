import { postData } from "../api-call";
import { BASE_URL, REWIND_QUERY } from "../../config";

const getRewindFinancesData = ({ id }: RewindFinancesDataProps) => {
  const data = { id };

  const requestUrl = `${BASE_URL}${REWIND_QUERY}`;
  return postData(requestUrl, data);
};

export const RewindAPI = {
  getRewindFinancesData,
};

interface RewindFinancesDataProps {
  id: string;
}
