import React, { useEffect, useState } from "react";
import Ionicon from "react-native-vector-icons/Ionicons";
import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";

//Screens
import Debts from "../Screens/Debts/Debts";
import MainScreen from "../Screens/Main/Main";
import AppHeader from "./AppHeader";
import TransactionsTabs from "./TransactionsTabs";
import ClearFinances from "../Screens/ClearFinances/ClearFinances";

import MenuNavigator from "./MenuNavigator";
import { NavigationContainer } from "@react-navigation/native";
import PlansMenu from "./PlansMenu";
import { UserInfoAPI } from "../api/src/user-info";
import Transactions from "../Screens/Transactions/Transactions";
import { checkProperty, getHashId } from "../helpers/src";

const Tab = createBottomTabNavigator();

const BottomNav = () => {
  const paramsId = window.location.pathname || localStorage.getItem("hashId");

  const [userType, setUserType] = useState("");
  useEffect(() => {
    const hashId = getHashId(paramsId);
    UserInfoAPI.getInfo({ id: hashId }).then((x) =>
      setUserType(checkProperty(x?.contact?.attributes?.userType))
    );
  }, [userType]);
  return (
    <NavigationContainer>
      <Tab.Navigator
        headerMode="screen"
        screenOptions={({ route }) => ({
          tabBarIcon: ({ focused, color, size }) => {
            let iconName;
            let rn = route.name;
            if (rn === "Inicio") {
              iconName = focused ? "home" : "home-outline";
            } else if (rn === "Análisis") {
              iconName = focused ? "ribbon" : "ribbon-outline";
            } else if (rn === "Movimientos") {
              iconName = focused ? "card" : "card-outline";
            } else if (rn === "Deudas") {
              iconName = focused ? "document-text" : "document-text-outline";
            } else if (rn === "Más") {
              iconName = focused ? "apps" : "apps-outline";
            }
            return <Ionicon name={iconName} size={size} color={color} />;
          },
          header: ({ route, navigation }) => (
            <AppHeader navigation={navigation} title={route.name} />
          ),
          tabBarActiveTintColor: "#30ce88",
          tabBarInactiveTintColor: "gray",
          tabBarLabelStyle: { paddingBottom: 10, fontSize: 12 },
          tabBarStyle: {
            borderRadius: 10,
            padding: 10,
            height: 70,
            width: "100%",
            minWidth: 320,
            maxWidth: 500,
            alignSelf: "center",
          },
        })}
      >
        {/* <Tab.Screen name="Inicio" component={MainScreen} /> */}
        <Tab.Screen
          name="Movimientos"
          component={userType === "PERSONAL" ? Transactions : TransactionsTabs}
        />
        <Tab.Screen name="Análisis" component={ClearFinances} />
        <Tab.Screen name="Deudas" component={Debts} />
        <Tab.Screen
          name="Más"
          component={MenuNavigator}
          options={{ headerShown: false }}
        />
      </Tab.Navigator>
    </NavigationContainer>
  );
};

export default BottomNav;
