import { StyleSheet, Text, View } from "react-native";
import React from "react";
import { globalClasses, globalStyles } from "../styles";
import GoalsCard from "./GoalsCard";
import MySalesCard from "./MySalesCard";
import MyIncomesCard from "./MyIncomesCard";
import MaterialCommunityIcons from "react-native-vector-icons/MaterialCommunityIcons";

const ChartsCard = ({
  content,
  userInfo,
  mySalesCardData,
  financeDataWeek,
  financeDataLastWeek,
  clearGoalWeekValues,
  goalWeekValues,
  userGoalsOutcome,
  userGoalsIncome,
  createGoalWeekValues,
  setType,
  type,
  setGoalWeek,
  goalWeek,
  most_amount_month_incomes,
  most_amount_month_outcomes,
}) => {
  const {
    most_amount_day,
    most_amount_day_outcome,
    most_profit_day,
    most_profit_day_outcome,
    count_day_income,
    count_day_outcome,
    loss_profit_day,
    loss_profit_day_outcome,
  } = mySalesCardData;

  const { phone, purposeUse, countryCurrency } = userInfo;

  const currencyFormat = Intl.NumberFormat("es-CO");

  const [visible, setVisible] = React.useState(false);
  const showModal = async () => {
    setVisible(true);
  };
  const hideModal = () => setVisible(false);

  const progressIncome = Math.abs(
    Number(String(financeDataWeek?.total_income).replace(/\D/g, "")) !== 0 &&
      goalWeekValues?.income
      ? Number(String(financeDataWeek?.total_income).replace(/\D/g, "")) /
          goalWeekValues?.income
      : 0
  );

  const progressOutcome = Math.abs(
    Number(String(financeDataWeek?.total_outcome).replace(/\D/g, "")) !== 0 &&
      goalWeekValues?.outcome
      ? Number(String(financeDataWeek?.total_outcome).replace(/\D/g, "")) /
          goalWeekValues?.outcome
      : 0
  );
  return (
    <>
      <MyIncomesCard
        monthIncomeValues={most_amount_month_incomes}
        monthValuesOut={most_amount_month_outcomes}
        currentWeek={financeDataWeek}
        lastWeek={financeDataLastWeek}
        purposeUse={purposeUse}
        content={content}
      />

      <MySalesCard
        mostDaysIncomes={
          purposeUse === "META_GANANCIA"
            ? most_amount_day
            : most_amount_day_outcome
        }
        mostProfitDay={
          purposeUse === "META_GANANCIA"
            ? most_profit_day
            : most_profit_day_outcome
        }
        countDayIncome={
          purposeUse === "META_GANANCIA" ? count_day_income : count_day_outcome
        }
        mostLossDay={
          purposeUse === "META_GANANCIA"
            ? loss_profit_day
            : loss_profit_day_outcome
        }
        countryCurrency={countryCurrency}
        purposeUse={purposeUse}
        content={content}
      />

      <Text style={globalClasses.sectionTitle}>¡Alcanza tu meta!</Text>
      <Text>Lo que hace falta para cumplir tu meta 💪 ¡Tú puedes!</Text>
      <GoalsCard
        currentWeekIncome={financeDataWeek?.total_income}
        currentWeekOutcome={financeDataWeek?.total_outcome}
        clearGoalWeekValues={clearGoalWeekValues}
        goalWeekValues={goalWeekValues}
        setType={setType}
        userGoalsIncome={userGoalsIncome}
        userGoalsOutcome={userGoalsOutcome}
        type={type}
        setGoalWeek={setGoalWeek}
        goalWeek={goalWeek}
        currencyFormat={currencyFormat}
        countryCurrency={countryCurrency}
        createGoalWeekValues={createGoalWeekValues}
        phone={phone}
        visible={visible}
        close={hideModal}
        open={showModal}
        content={content}
      />
      <View style={styles.messageCard}>
        <View style={{ marginRight: 7 }}>
          <MaterialCommunityIcons
            name="creation"
            color={globalStyles.colors.primary}
            size={25}
          />
        </View>
        <View style={{ width: "91%" }}>
          {purposeUse !== "META_GANANCIA" ? (
            <Text>
              {content === "semanal" ? "Esta semana" : "Este mes "}
              has gastado {countryCurrency}
              {financeDataWeek?.total_outcome}, lo cual corresponde al{" "}
              {progressOutcome > 1
                ? "+100"
                : (progressOutcome * 100)?.toFixed(0)}
              % de tu límite semanal esperado. Mantén tus gastos bajo control
              para evitar exceder tus límites semanales 📈
            </Text>
          ) : (
            <Text>
              {content === "semanal" ? "Esta semana" : "Este mes "}
              has vendido {countryCurrency}
              {financeDataWeek?.total_income}, lo cual corresponde al{" "}
              {progressIncome > 1 ? "+100" : (progressIncome * 100)?.toFixed(0)}
              % de tu meta {content} esperada. Cada día es una oportunidad para
              que te acerques más a tu meta de ingresos, ¡sigue adelante! 🚀
            </Text>
          )}
        </View>
      </View>
    </>
  );
};

export default ChartsCard;

const styles = StyleSheet.create({
  messageCard: {
    padding: 7,
    backgroundColor: globalStyles.colors.lightPositive,
    flexDirection: "row ",
    marginTop: globalStyles.shape.sectionSpace,
    borderRadius: 10,
  },
});
