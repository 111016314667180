import { FlatList, StyleSheet, Text, View } from "react-native";
import React, { useState } from "react";
import Transactions from "../Screens/Transactions/Transactions";
import TransactionsPersonal from "../Screens/Transactions/TransactionsPersonal";
import TransactionsBusiness from "../Screens/Transactions/TransactionsBusiness";

import { globalStyles } from "../styles";
import { SegmentedButtons } from "react-native-paper";
import { ScrollView } from "react-native-gesture-handler";
import { buttonSelect } from "../analytics/src/open-report";
import { useSelector } from "react-redux";

const TransactionsTabs = ({ navigation, route }) => {
  const [value, setValue] = useState("general");
  const { userData } = useSelector((state) => state.userData);

  return (
    <React.Fragment style={{ marginTop: 10 }}>
      <SegmentedButtons
        value={value}
        onValueChange={setValue}
        style={{ width: "100%" }}
        buttons={[
          {
            value: "general",
            label: "General",
            style: [
              styles.button,
              {
                borderBottomColor:
                  value === "general"
                    ? globalStyles.colors.primary
                    : globalStyles.colors.darkGrey,
              },
            ],
            onPress: async () =>
              await buttonSelect(userData?.phone, {
                type: "Barra Movimientos",
                boton: "General",
              }),
          },
          {
            value: "negocios",
            label: "Negocios",
            style: [
              styles.button,
              {
                borderBottomColor:
                  value === "negocios"
                    ? globalStyles.colors.primary
                    : globalStyles.colors.darkGrey,
              },
            ],
            onPress: async () =>
              await buttonSelect(userData?.phone, {
                type: "Barra Movimientos",
                boton: "Negocios",
              }),
          },
          {
            value: "personal",
            label: "Personal",
            style: [
              styles.button,
              {
                borderBottomColor:
                  value === "personal"
                    ? globalStyles.colors.primary
                    : globalStyles.colors.darkGrey,
              },
            ],
            onPress: async () =>
              await buttonSelect(userData?.phone, {
                type: "Barra Movimientos",
                boton: "Personal",
              }),
          },
        ]}
      />
      {value === "personal" ? (
        <TransactionsPersonal navigation={navigation} route={route} />
      ) : value === "negocios" ? (
        <TransactionsBusiness navigation={navigation} route={route} />
      ) : (
        <Transactions navigation={navigation} route={route} />
      )}
    </React.Fragment>
  );
};

export default TransactionsTabs;

const styles = StyleSheet.create({
  button: {
    backgroundColor: "transparent",
    borderWidth: 2,
    borderRadius: 0,
    borderColor: globalStyles.colors.white,
  },
});
