import { createSlice } from "@reduxjs/toolkit";

export const modalShowTutorialSlice = createSlice({
  name: "modalShowTutorial",
  initialState: {},
  reducers: {
    addModalShow: (state, action) => {
      state.show = action.payload;
    },

    clearModalShow: (state, _) => {
      state.pop();
    },
  },
});

export const { addModalShow, clearModalShow } = modalShowTutorialSlice.actions;

export default modalShowTutorialSlice.reducer;
