import { Text, View } from "react-native";
import React from "react";
import { globalClasses, globalStyles } from "../styles";
import { Divider } from "react-native-paper";

const FAQsCard = ({ question, answer }) => {
  return (
    <View style={{ marginTop: globalStyles.shape.sectionSpace }}>
      <Text
        style={[
          globalClasses.smallTitle,
          { color: globalStyles.colors.primary },
        ]}
      >
        {question}
      </Text>
      <Text>{answer}</Text>
      <Divider style={{ marginTop: 3 }} />
    </View>
  );
};

export default FAQsCard;
