import { StyleSheet, Text, View } from "react-native";
import React from "react";
import { globalClasses, globalStyles } from "../styles";

const TopTransactions = ({
  tx,
  number,
  description,
  date,
  amount,
  countryCurrency,
}) => {
  const dateFormat = new Date(date);
  dateFormat.setDate(dateFormat.getDate() + 1);

  const dateOptions = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  };

  const currencyFormat = Intl.NumberFormat("es-CO");

  return (
    <View
      style={[
        styles.cardContainer,
        globalClasses.shadow,
        {
          backgroundColor:
            tx === "ingreso"
              ? globalStyles.colors.lightPositive
              : globalStyles.colors.lightNegative,
        },
      ]}
    >
      <View
        style={[
          styles.numberSquare,
          {
            backgroundColor:
              tx === "ingreso"
                ? globalStyles.colors.primary
                : globalStyles.colors.red,
          },
        ]}
      >
        <Text
          style={[
            globalClasses.breakTitle,
            { color: globalStyles.colors.white, textAlign: "center" },
          ]}
        >
          {number}
        </Text>
      </View>
      <View style={styles.cardContent__row}>
        <View style={{ width: "65%" }}>
          <Text style={globalClasses.smallTitle}>{description}</Text>
          <Text style={globalClasses.caption}>
            {dateFormat.toLocaleDateString("es-ES", dateOptions)}
          </Text>
        </View>
        <Text style={globalClasses.cardTitle}>
          {countryCurrency}
          {currencyFormat.format(amount)}
        </Text>
      </View>
    </View>
  );
};

export default TopTransactions;

const styles = StyleSheet.create({
  cardContainer: {
    width: "100%",
    borderRadius: 10,
    marginTop: globalStyles.shape.betweenSpace,
    flexDirection: "row",
    justifyContent: "space-between",
  },
  numberSquare: {
    width: 45,
    height: 45,
    flex: 1,
    justifyContent: "center",
    borderRadius: 10,
  },
  cardContent__row: {
    flexDirection: "row",
    justifyContent: "space-between",
    paddingHorizontal: globalStyles.shape.internSpaceX,
    paddingVertical: globalStyles.shape.internSpaceY,
    width: "85%",
  },
});
