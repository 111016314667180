import { Button, Image, Modal, StyleSheet, Text, View } from "react-native";
import { TouchableOpacity } from "react-native-gesture-handler";
import React, { useState } from "react";
import Icon from "react-native-vector-icons/Ionicons";
import * as Sharing from "expo-sharing";
import { globalClasses, globalStyles } from "../../styles";
import Clipboard from "@react-native-clipboard/clipboard";
import { Linking } from "react-native";

const Referrals = ({ navigation }) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [state, setState] = useState("");

  const handlePress = () => {
    Sharing.isAvailableAsync().then((available) => {
      if (available) {
        setState("Sharing is available");
      } else {
        setState("Sharing is NOT available");
      }
    });
    Clipboard.setString(
      "¡Hola 😄! Quería contarte que estoy ordenando mis finanzas por un chat de WhatsApp que se llama *Qentaz*, te recomiendo esta herramienta porque me ha parecido *segura*, *simple* y *útil* para organizarme 🙌 "
    );
    setModalVisible(true);
  };

  setTimeout(() => {
    setModalVisible(false);
  }, 6000);

  return (
    <View style={globalClasses.screenContainer}>
      <TouchableOpacity
        style={{ alignSelf: "flex-begin", marginTop: -5, position: "absolute" }}
        onPress={() => navigation.push("Menu")}
      >
        <Icon name="arrow-back-outline" size={30} color="#000000" />
      </TouchableOpacity>
      <View
        style={{
          flex: 1,
          textAlign: "center",
          justifyContent: "center",
          marginHorizontal: globalStyles.shape.horizontal,
        }}
      >
        <View style={{ padding: 10, alignSelf: "center", width: "80%" }}>
          <Image
            source={require("../../../assets/amico.png")}
            resizeMode="contain"
            style={{ width: "100%", height: 250 }}
          />
        </View>
        <Text style={globalClasses.sectionTitle}>
          Refiere a tu amig@ que aún no organiza sus finanzas
        </Text>
        <Text style={{ marginTop: globalStyles.shape.sectionSpace }}>
          Comparte lo mejor de Qentaz a todos los que lo necesitan. Solo
          presiona el botón y comparte la invitación por dónde quieras:
          WhatsApp, Facebook, Instagram o dónde quieras
        </Text>
        <View
          style={{
            marginTop: globalStyles.shape.sectionSpaceBottom,
            width: "70%",
            alignSelf: "center",
          }}
        >
          <Button
            color={globalStyles.colors.primary}
            title="Compartir"
            onPress={handlePress}
          />
        </View>
        <Modal
          animationType="slide"
          transparent="true"
          visible={modalVisible}
          onRequestClose={() => {
            setModalVisible(false);
          }}
        >
          <View
            style={{
              flex: 1,
              justifyContent: "center",
              backgroundColor: "rgba(0,0,0,0.3)",
              paddingHorizontal: globalStyles.shape.horizontal,
            }}
          >
            <Text
              style={[
                globalClasses.cardTitle,
                {
                  textAlign: "center",
                  borderRadius: 10,
                  backgroundColor: globalStyles.colors.white,
                  fontSize: 14,
                },
              ]}
            >
              ¡Texto copiado al portapapeles!
              <br />
              <Text
                style={[
                  globalClasses.cardTitle,
                  {
                    textAlign: "center",
                    borderRadius: 10,
                    backgroundColor: globalStyles.colors.white,
                    fontSize: 16,
                  },
                ]}
              >
                Si quieres probarla, puedes *tocar en este enlace* que te
                llevará directamente a su chat:
                <Text>
                  {" "}
                  <TouchableOpacity
                    onPress={() => Linking.openURL("m.qentaz.com / HolaQZ")}
                  >
                    <Text
                      style={[
                        globalClasses.smallTitle,
                        {
                          color: globalStyles.colors.primary,
                          textDecorationLine: "underline",
                        },
                      ]}
                    >
                      m.qentaz.com/HolaQZ
                    </Text>
                  </TouchableOpacity>
                </Text>
              </Text>
            </Text>
          </View>
        </Modal>
      </View>
    </View>
  );
};

export default Referrals;

const styles = StyleSheet.create({});
