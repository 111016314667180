import React, { useState, useEffect, useMemo } from "react";
import _ from "lodash";
import { ApprovalTableAPI } from "../../../api/src/send-multi-account-confirm";
import { useSelector } from "react-redux";
import { MultiAccountAPI } from "../../../api/src/get-multiple-accounts";

export const useMultiUserForm = ({ userInfo, setRefreshUserData }) => {
  const [phoneNumber, setPhoneNumber] = useState(
    userInfo ? userInfo?.phone : ""
  );

  const { userData } = useSelector((state) => state.userData);

  const [newUser, setNewUser] = useState({
    name: userInfo?.name || "",
    userRol: userInfo?.user_role || "",
  });

  const sendConfirmationMultiAccount = async () => {
    const parseContact = phoneNumber.replace("+", "");
    const parseContactOwner = userData.phone?.replace("+", "");
    ApprovalTableAPI.postConfirmationMultiAccount({
      ownerPhone: parseContactOwner,
      name: newUser.name,
      role: newUser.userRol,
      memberPhone: parseContact,
    });
  };

  const updateMultiAccountUser = async () => {
    const memberArray = ["owner", "admin", "member"];

    MultiAccountAPI.updateMultiAccountUser({
      memberPhone: phoneNumber?.replace("+", ""),
      name: newUser.name,
      rol: memberArray[newUser.userRol],
    }).finally(() => setRefreshUserData(true));
  };

  return {
    newUser,
    setNewUser,
    phoneNumber,
    setPhoneNumber,
    sendConfirmationMultiAccount,
    updateMultiAccountUser,
  };
};
